import { get, set } from "lodash";
import { mergeYaml } from "utils/yaml";
import YAML from "yaml";

export const PARAM_SEPARATOR = "~~param~~";

export function createTwoWaySyncer({ formData, formMeta, values }) {
  function populateValues() {
    if (
      formMeta[0]?.name === "identityProvider" &&
      formData?.pack === "kubernetes"
    ) {
      try {
        const doc = YAML.parseDocument(values);
        doc.setIn(
          formMeta[0].targetKey?.split("."),
          formData[`${PARAM_SEPARATOR}${formMeta[0]?.name}`]
        );
        return doc.toString({
          keepComments: true,
          lineWidth: 0,
          skipInvalid: true,
        });
      } catch (e) {}
      return values;
    } else {
      const params = (formMeta || []).reduce((accumulator, param) => {
        if (formData[`${PARAM_SEPARATOR}${param.name}`]) {
          return set(
            accumulator,
            param.targetKey,
            formData[`${PARAM_SEPARATOR}${param.name}`]
          );
        }

        return accumulator;
      }, {});

      if (Object.keys(params).length) {
        return mergeYaml(values, YAML.stringify(params)).toString();
      }
    }

    return values;
  }

  function populateForm() {
    return (formMeta || []).reduce((accumulator, param) => {
      try {
        const valuesDoc = YAML.parse(values);
        const value = get(valuesDoc, param.targetKey) || param.value;
        return set(accumulator, `${PARAM_SEPARATOR}${param.name}`, value);
      } catch (err) {
        return accumulator;
      }
    }, {});
  }

  return {
    populateForm,
    populateValues,
  };
}
