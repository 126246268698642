import { pageWidthConstrain } from "components/styled/mixins";
import React, { useMemo } from "react";
import styled from "styled-components";

const PageContraints = styled.div`
  margin: -16px -32px -20px -32px;
  height: calc(100% + 29px);
  ${pageWidthConstrain};
  background-color: #fff;
`;

const Iframe = styled.iframe`
  border: 0;
  width: 100%;
  height: 100%;
`;

export function getVmService(cluster) {
  return cluster?.status?.addOnServices?.find((service) => {
    // TODO: console-service is deprecated, remove it once we launch the feature
    return [
      "console-service",
      "vm-dashboard",
      "spectro-vm-dashboard",
      "virtual-machine-orchestrator",
    ].includes(service.name);
  });
}

export default function Vms({ cluster }) {
  const kubevirtUiService = useMemo(() => getVmService(cluster), [cluster]);

  if (!kubevirtUiService) {
    return null;
  }

  return (
    <PageContraints>
      <Iframe title="Virtual Machines" src={`${kubevirtUiService.endpoint}/`} />
    </PageContraints>
  );
}
