import { isGenerator } from "utils/generators";
import { v4 as uuid } from "uuid";

const WILDCARD = Symbol("*");

export class ValidationError {
  constructor(config) {
    const defaults = { priority: 0, status: "error" };
    return typeof config === "string"
      ? { ...defaults, message: config }
      : { ...defaults, ...config };
  }
}

class GeneratorResult {
  constructor({ result, guid, field }) {
    this.result = result;
    this.fromGenerator = true;
    this.guid = guid;
    this.field = field;
  }

  run = () => {
    return this.result;
  };
}

async function promisifyRunner({ run, ...rest }) {
  let result;
  try {
    result = await run();
  } catch (err) {
    if (err instanceof Error) {
      console.error(err);
    }
    result = err;
  }
  const error = result ? new ValidationError(result) : {};
  delete error.message;
  return { ...rest, result, ...error };
}

export class Rule {
  constructor(rule) {
    if (rule instanceof Rule) {
      this.getValidations = rule.getValidations;
      this.guid = rule.guid;
      return;
    }

    const _ID = uuid();
    this.guid = _ID;
    let _rule = rule;
    if (typeof rule === "function") {
      _rule = function* (...args) {
        yield {
          run: () => rule(...args),
          field: args.pop(),
          guid: _ID,
        };
      };
    }

    if (rule instanceof Validator) {
      const subValidator = rule;
      _rule = function* (value, key, data, path) {
        yield* subValidator.run(value, undefined, path).rules;
      };
      _rule.isValidator = true;
    }

    if (isGenerator(rule)) {
      _rule = function* (value, key, data, path) {
        const errors = rule(value, key, data, path);
        for (const error of errors) {
          if (error instanceof Validator) {
            yield* error.run(value, undefined, path).rules;
          } else {
            yield new GeneratorResult({
              result: error,
              field: path,
              guid: _ID,
              fromGenerator: true,
            });
          }
        }
      };
    }

    this.getValidations = _rule;
  }
}

export default class Validator {
  constructor() {
    this.rules = {};
  }

  addRule(fields, rule) {
    if (rule === undefined) {
      this.addRule(WILDCARD, fields);
      return;
    }

    let keys = fields;
    if (!Array.isArray(fields)) {
      keys = [fields];
    }
    keys.forEach((field) => {
      this.rules[field] = this.rules[field] || [];
      this.rules[field].push(new Rule(rule));
    });
  }

  removeRules() {
    this.rules = {};
  }

  run = (data, fields = data ? Object.keys(data) : [], path) => {
    let keys = fields;
    if (!Array.isArray(fields)) {
      keys = [fields];
    }

    keys = [...new Set(keys.map((key) => key.split(".").shift()))];

    const currentPath = path ? `${path}.` : "";

    let rules = [];
    if (Array.isArray(data)) {
      rules = data.reduce((accumulator, item, index) => {
        const itemRules = this.run(
          item,
          undefined,
          `${currentPath}${index}`
        ).rules;
        return accumulator.concat(itemRules);
      }, []);
    } else {
      for (const key of keys) {
        const keyRules = this.rules[key] || [];
        for (const rule of keyRules) {
          const runners = rule.getValidations(
            data[key],
            key,
            data,
            `${currentPath}${key}`
          );
          rules = [...rules, ...runners];
        }
      }
    }

    if (this.rules[WILDCARD]) {
      for (const rule of this.rules[WILDCARD]) {
        const runners = rule.getValidations(data, "", data, path);
        rules = [...rules, ...runners];
      }
    }

    function filterRules(rule) {
      return (
        rule.fromGenerator ||
        fields.some((field) => rule?.field?.startsWith(field))
      );
    }

    return {
      rules,
      [Symbol.asyncIterator]: async function* () {
        const promises = rules.filter(filterRules).map(promisifyRunner);

        const map = new Map(
          promises.map((p, i) => [i, p.then((res) => [i, res])])
        );

        while (map.size) {
          const [key, { fromGenerator, ...result }] = await Promise.race(
            map.values()
          );
          if (fromGenerator) {
            if (fields.some((field) => result.field?.startsWith(field))) {
              yield result;
            }
          } else {
            yield result;
          }
          map.delete(key);
        }
      },
    };
  };
}
