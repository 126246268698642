import { createSelector } from "reselect";
import { isEmpty } from "lodash";
import { v4 as uuid } from "uuid";
import { availableClusterFiltersFetcher } from "state/cluster/services";
import { getAllProjects } from "state/auth/selectors";
import { getClusterFiltersAvailableOptions } from "state/cluster/selectors/filters";

import { presentFilterPropertiesSchema } from "utils/presenters";
import {
  PRESENTED_DEFAULT_CLUSTERS_FILTERS,
  DEFAULT_CLUSTERS_FILTERS,
} from "utils/constants/filters";

const mapSelectOption = (option) => ({
  label: option.metadata.name,
  value: option.metadata.name,
});

const mapFilterValue = (filter) => ({
  label: filter?.name,
  value: filter?.uid,
});

const mapFipsValue = (filter) => ({
  label: filter?.displayValue,
  value: filter?.value,
});

export function createListFiltersFormFactory({
  formModule,
  formActions,
  listActions,
  listModule,
  availableFiltersFetcher = availableClusterFiltersFetcher,
  defaultFilters = DEFAULT_CLUSTERS_FILTERS,
}) {
  const getCurrentConditions = createSelector(
    (state) => state.forms?.[formModule]?.data,
    (formData) => {
      return formData?.conditions || [];
    }
  );

  const getSelectedConjunction = createSelector(
    (state) => state.forms?.[formModule]?.data,
    (formData) => {
      return formData?.conjunction || "";
    }
  );

  const getAvailableClusterFilters = createSelector(
    availableFiltersFetcher.selector,
    ({ result }) => {
      return result?.schema?.properties || [];
    }
  );

  const getPresentedClusterFilters = createSelector(
    getAvailableClusterFilters,
    getAllProjects,
    getClusterFiltersAvailableOptions,
    (properties, projects, availableOptions) => {
      properties.forEach((item) => {
        if (item.name === "environment") {
          const index = item.enum.indexOf("coxedge");
          if (index !== -1) {
            item.enum.splice(index, 1);
          }
        }
      });
      const presentedProperties = presentFilterPropertiesSchema(properties);
      const projectOptions = projects.map(mapSelectOption);
      const cloudAccountsOptions = (
        availableOptions?.cloudAccounts?.values || []
      )?.map(mapFilterValue);
      const regionOptions = (availableOptions?.regionName?.values || [])?.map(
        mapFilterValue
      );
      const countryOptions = (availableOptions?.countryName?.values || [])?.map(
        mapFilterValue
      );
      const architectureOptions = (
        properties?.find(({ name }) => name === "architecture")?.enumValues ||
        []
      )?.map(mapFipsValue);
      const fipsOptions = (
        properties?.find(({ name }) => name === "fips")?.enumValues || []
      )?.map(mapFipsValue);

      return {
        ...presentedProperties,
        cloudAccounts: {
          ...presentedProperties.cloudAccounts,
          options: cloudAccountsOptions,
        },
        projects: {
          ...presentedProperties.projects,
          options: projectOptions,
        },
        countryName: {
          ...presentedProperties.countryName,
          options: countryOptions,
        },
        regionName: {
          ...presentedProperties.regionName,
          options: regionOptions,
        },
        architecture: {
          ...presentedProperties.architecture,
          options: architectureOptions,
        },
        fips: {
          ...presentedProperties.fips,
          options: fipsOptions,
        },
      };
    }
  );

  const isSubmitFilterDisabled = createSelector(
    (state) => state.forms?.[formModule]?.data,
    (formData) => {
      const conditions = formData?.conditions || [];
      return conditions.some(
        (condition) =>
          (typeof condition.values !== "boolean" && !condition.values) ||
          (Array.isArray(condition.values) && isEmpty(condition.values)) ||
          !condition.property ||
          !condition.operator
      );
    }
  );

  const getAvailableDefaultFilters = createSelector(
    (state) => state.list?.[listModule(state)]?.query,
    (query) => {
      const filterGroups = query?.filterGroups || [];
      const selectedProperties = filterGroups.reduce((accumulator, group) => {
        const properties = (group?.conditions || []).map(
          (condition) => condition.property
        );
        accumulator.push(...properties);
        return accumulator;
      }, []);

      return defaultFilters.filter(
        (filter) => !selectedProperties.includes(filter.name)
      );
    }
  );

  function onAddCondition() {
    return (dispatch, getState) => {
      const conditions = [...getState().forms?.[formModule]?.data?.conditions];

      conditions.push({
        property: "",
        operator: "",
        values: "",
      });

      dispatch(
        formActions.onChange({
          module: formModule,
          name: "conditions",
          value: conditions,
        })
      );
    };
  }

  function onRemoveCondition(conditionIndex) {
    return (dispatch, getState) => {
      let conditions = [...getState().forms?.[formModule]?.data?.conditions];

      if (conditions.length > 1) {
        conditions.splice(conditionIndex, 1);
      } else {
        conditions.splice(conditionIndex, 1, {
          property: "",
          operator: "",
          values: "",
        });
      }

      dispatch(
        formActions.onChange({
          module: formModule,
          name: "conditions",
          value: conditions,
        })
      );
    };
  }

  function onSubmitFilters() {
    return (dispatch) => {
      dispatch(formActions.submit({ module: formModule }));
    };
  }

  function onPropertyOptionChange(index, property) {
    return (dispatch, getState) => {
      const conditions = [...getState().forms?.[formModule]?.data?.conditions];
      const properties = getPresentedClusterFilters(getState());

      conditions[index] = {
        property,
        values: properties[property].defaultValue,
        operator: conditions[index].operator,
        ...(property === "coordinates" && {
          latitude: properties[property].defaultValue,
          longitude: properties[property].defaultValue,
        }),
      };

      dispatch(
        formActions.onChange({
          module: formModule,
          name: "conditions",
          value: conditions,
        })
      );
    };
  }

  function onApplyDefaultFilter(name) {
    return (dispatch, getState) => {
      const state = getState();
      const filtersList = [
        ...state.list?.[listModule(state)]?.query?.filterGroups,
      ];
      filtersList.push({
        guid: uuid(),
        conjunction: "and",
        conditions: [
          {
            property: name,
            operator: "eq",
            values: "true",
            type: PRESENTED_DEFAULT_CLUSTERS_FILTERS[name].type,
            displayName: PRESENTED_DEFAULT_CLUSTERS_FILTERS[name].displayName,
          },
        ],
      });

      dispatch(
        listActions.changeQuery({
          name: "filterGroups",
          value: filtersList,
          module: listModule(state),
        })
      );
    };
  }

  return {
    actions: {
      onAddCondition,
      onRemoveCondition,
      onSubmitFilters,
      onPropertyOptionChange,
      onApplyDefaultFilter,
    },
    selectors: {
      getCurrentConditions,
      getAvailableClusterFilters,
      getPresentedClusterFilters,
      isSubmitFilterDisabled,
      getSelectedConjunction,
      getAvailableDefaultFilters,
    },
  };
}
