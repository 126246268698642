import React from "react";
import { useTranslation } from "react-i18next";

import Title from "components/common/Title";
import PageHeader from "components/common/layouts/App/PageHeader";
import OverviewListing from "pages/dev/overview/Overview";

const Overview = () => {
  const { t } = useTranslation();
  const title = t("Overview");

  return (
    <div>
      <Title>{title}</Title>
      <PageHeader
        title={title}
        breadcrumbs={[{ label: title }]}
        withBack={false}
      />
      <OverviewListing />
    </div>
  );
};

export default Overview;
