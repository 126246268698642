import React from "react";
import styled, { css } from "styled-components";

import { faCaretUp, faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { faSearch } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Fields from "components/ui/Fields";
import Button from "components/ui/Button";
import { FieldWrap } from "components/styled/Field";
import { MAX_CONTENT_WIDTH } from "components/styled/mixins";
import {
  white,
  mediumLightGray,
  blue,
  gray,
  winterMist,
  lightWhite,
  darkGray,
  concreteWhite,
} from "utils/constants/colors";

const StyledForm = styled.div`
  min-height: 48px;
  min-width: fit-content;
  overflow: hidden;
  max-width: ${MAX_CONTENT_WIDTH};
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  background-color: ${white};
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  margin-bottom: 16px;
  padding: 0 8px;
  border-bottom: 1px solid ${concreteWhite};
  border-top: 1px solid ${concreteWhite};
`;

const InputWrap = styled.div`
  margin-right: 8px;
  max-width: 240px;
  width: 100%;
  min-width: 150px;

  .ant-input-affix-wrapper {
    border: none;
  }
  .ant-input-prefix {
    margin-right: 16px;
  }
  ${FieldWrap} {
    max-width: 240px;
    margin: 0;
  }
`;

const SearchIcon = styled(FontAwesomeIcon)`
  width: 16px;
  height: 16px;
  color: ${gray};
`;

const SelectWrap = styled.div`
  margin: 0 16px;
  .ant-select-arrow,
  .ant-select-clear {
    top: 8px;
  }

  ${FieldWrap} {
    margin: 0;
    label {
      margin: 0;
      text-transform: uppercase;
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 10px;
      color: ${mediumLightGray};
    }
    .ant-select-selector {
      min-height: auto;
      min-width: 200px;
      height: 25px;
      padding-left: 0;
    }
  }
`;

const SortIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10px;
  height: 8px;
  color: ${mediumLightGray};
`;

const StyledButton = styled(Button)`
  border: none;
  width: 35px;
  min-width: 35px;
  box-shadow: none;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  ${(props) =>
    props.order === "asc" &&
    css`
      > ${SortIcon}:first-of-type {
        color: ${blue};
      }
    `}

  ${(props) =>
    props.order === "desc" &&
    css`
      > ${SortIcon}:last-of-type {
        color: ${blue};
      }
    `}
`;

const Divider = styled.div`
  height: ${(props) => (props.height ? `${props.height}px` : "100%")};
  border-right: 1px solid ${winterMist};
  width: 1px;
`;

export const SelectFieldWrap = styled.div`
  ${FieldWrap} {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin: 0;

    label {
      white-space: nowrap;
      margin: 0;
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      height: 32px;
      padding: 5px 12px;
      color: ${darkGray};
      background-color: ${lightWhite};
      box-shadow: inset 0px 1px 0px ${concreteWhite},
        inset 1px 0px 0px ${concreteWhite}, inset 0px -1px 0px ${concreteWhite};
      border-radius: 2px 0px 0px 2px;
    }

    .ant-select-selector {
      min-height: auto;
      min-width: ${(props) => props.minWidth || "232px"};
      height: 32px;
      border: 1px solid ${concreteWhite};
      border-radius: 2px;

      ${(props) =>
        props.primary &&
        css`
          background-color: ${white};
        `}

      ${(props) =>
        props.secondary &&
        css`
          background-color: ${lightWhite};
        `}
    }

    label ~ .ant-select .ant-select-selector {
      border-radius: 0px 2px 2px 0px;
    }
  }

  .ant-select-selection-overflow,
  .ant-select-selection-overflow-item {
    flex-wrap: nowrap;
    height: 0;
  }

  .ant-select.ant-select-show-search .ant-select-selection-search-input {
    height: 100%;
  }
`;

export const InputFieldWrap = styled.div`
  ${FieldWrap} {
    min-height: auto;
    width: ${(props) => props.width || "216px"};
    height: 32px;
    margin: 0;
  }

  .ant-input-affix-wrapper,
  input {
    background-color: ${(props) => (props.secondary ? lightWhite : white)};
  }

  .ant-input-affix-wrapper {
    border: 1px solid ${concreteWhite};
    border-radius: 2px;
  }
`;

const FormWrap = styled.div`
  max-width: ${MAX_CONTENT_WIDTH};
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  background-color: ${white};
  padding: ${(props) => (props.withPadding ? `8px 12px 0 12px` : "0")};
  min-height: 48px;
  position: sticky;
  flex-shrink: 0;
  top: ${(props) => props.top || "-16px"};
  z-index: 101;
  border-bottom: 1px solid ${concreteWhite};
  border-top: ${(props) =>
    props.withTopBorder ? `1px solid ${concreteWhite}` : "none"};

  ${SelectFieldWrap},
  ${InputFieldWrap} {
    margin-right: 8px;
    margin-bottom: 8px;
  }
`;

FormWrap.defaultProps = {
  withTopBorder: true,
  withPadding: true,
};

const StyledSearch = (props) => {
  return (
    <InputWrap>
      <Fields.Input
        data-qa="search-filter"
        prefix={<SearchIcon icon={faSearch} />}
        bordered={false}
        {...props}
      />
    </InputWrap>
  );
};

const StyledSort = (props) => {
  return (
    <SelectWrap>
      <Fields.Select data-qa="sort" bordered={false} {...props} />
    </SelectWrap>
  );
};

const StyledOrderButton = ({ order, ...rest }) => {
  return (
    <StyledButton data-qa="order" secondary order={order} {...rest}>
      <SortIcon>
        <FontAwesomeIcon icon={faCaretUp} />
      </SortIcon>
      <SortIcon>
        <FontAwesomeIcon icon={faCaretDown} />
      </SortIcon>
    </StyledButton>
  );
};

const Filters = {
  Divider,
  FormWrap,
  Form: StyledForm,
  Search: StyledSearch,
  Select: StyledSort,
  OrderButton: StyledOrderButton,
};

export default Filters;
