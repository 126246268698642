import React from "react";
import styled from "styled-components";
import {
  PackContainer,
  PackLabel,
  PackLogo,
  PackLogoIcon,
} from "../PackNameBlocks";

const Wrap = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  font-size: 14px;

  > * {
    width: 50%;
    margin-left: 50px;
  }

  > *:first-child {
    margin-left: 0;
  }
`;

const HelperText = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`;

export function PackTitle({
  logoUrl,
  layer,
  addonType,
  displayName,
  tag,
  helperText,
}) {
  const title = `${displayName} ${tag}`;
  return (
    <PackContainer>
      <PackLogo>
        <PackLogoIcon {...{ logoUrl, layer, addonType }} />
      </PackLogo>
      <PackLabel title={title}>
        {title}
        <HelperText>{helperText}</HelperText>
      </PackLabel>
    </PackContainer>
  );
}

export default function EditorSplitTitle({ from, to }) {
  return (
    <Wrap>
      <PackTitle {...from} />
      <PackTitle {...to} />
    </Wrap>
  );
}
