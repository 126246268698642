export const initialState = {
  isValid: false,
};

export function VALIDATE_REGISTRY_REQUEST(nextState) {
  nextState.isValidating = true;
}

export function VALIDATE_REGISTRY_SUCCESS(nextState) {
  nextState.isValid = true;
  nextState.isValidating = false;
}

export function VALIDATE_REGISTRY_FAILURE(nextState, { error }) {
  nextState.isValidating = false;
}

export function SET_INVALID_REGISTRY(nextState) {
  nextState.isValid = false;
}

export function OPEN_MODAL(nextState, { name }) {
  if (name !== "packRegistryModal" && name !== "helmRegistryModal") {
    return;
  }

  nextState.isValid = false;
  nextState.isValidating = false;
}

export function FORM_LOAD_SUCCESS(nextState, { module, result }) {
  if (module !== "packregistries" && module !== "helmregistries") {
    return;
  }

  nextState.isValid = result.isValid;
}
