export const ADMIN_PERM = "ADMIN";

export class PermissionService {
  constructor(perms) {
    this.perms = perms || [];
  }

  init(perms) {
    this.perms = perms || [];
  }

  has(list = []) {
    let permissions = list;
    if (!Array.isArray(list)) {
      permissions = [permissions];
    }

    if (list.length === 0) {
      return true;
    }

    return permissions.some((perm) => this.perms.includes(perm));
  }

  is(list = []) {
    let permissions = list;
    if (!Array.isArray(list)) {
      permissions = [permissions];
    }

    return permissions.every((perm) => this.perms.includes(perm));
  }

  get isAdmin() {
    return this.perms.includes(ADMIN_PERM);
  }
}

const permissions = new PermissionService();
export default permissions;
