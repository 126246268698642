import { css } from "styled-components";
import {
  blueberry,
  concreteWhite,
  darkGray,
  white,
} from "utils/constants/colors";

export const MAX_CONTENT_WIDTH = "1616px";

export const pageWidthConstrain = css`
  max-width: ${MAX_CONTENT_WIDTH};
`;

export const paginationStyle = css`
  .ant-pagination {
    height: 48px;
    background-color: ${white};
    padding: 0 12px;
    display: flex;
    align-items: center;
    border-top: 1px solid ${concreteWhite};
    width: 100%;
    position: ${({ isSticky = true }) => (isSticky ? "sticky" : "static")};
    bottom: ${(props) => props.bottom || "-20px"};
    z-index: 99;
    font-size: 14px;
    color: ${darkGray};
    line-height: 32px;
  }

  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link,
  ul > li:not(.ant-pagination-item-active) {
    border: 0;
  }

  ul > .ant-pagination-item-active {
    border: 1px solid ${blueberry};
    border-radius: 2px;
    a {
      color: ${blueberry};
    }
  }

  .ant-pagination-item a,
  li:not(.ant-pagination-disabled) .ant-pagination-item-link {
    color: ${darkGray};
    .ant-pagination-item-link-icon,
    :hover {
      color: ${blueberry};
    }
  }
`;
