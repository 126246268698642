import isEqual from "fast-deep-equal";
import debounce from "redux-debounce-thunk";
import entities, { createEntity } from "utils/entities";
import { v4 as uuid } from "uuid";
import { listingTablePreferences } from "services/localstorage/cache";

const DEFAULT_LIMIT_SIZE = 50;

export default class ListActions {
  constructor(actions) {
    if (actions instanceof ListActions) {
      return actions;
    }

    const {
      fetchData,
      schema,
      debounceDelay = 1000,
      initialQuery = () => ({}),
      defaultQuery,
      dataFetcher,
      hasPagination,
      identifier,
      persistFilters = false,
    } = actions;

    Object.assign(this, {
      fetchData,
      schema,
      initialQuery,
      dataFetcher,
      hasPagination,
      identifier,
      defaultQuery,
      persistFilters,
    });

    this.debouncedFetchItems = debounce(this.fetchItems, debounceDelay);
  }

  initialize(module, initialQuery = this.initialQuery()) {
    return (dispatch) => {
      const cache = listingTablePreferences.get(module) || {};
      const { columnPreferences = {}, query: cachedQuery = {} } = cache;
      const newQuery = {
        ...initialQuery,
        ...cachedQuery,
      };
      dispatch({
        type: "LIST_INITIALIZE",
        query: { limit: DEFAULT_LIMIT_SIZE, ...newQuery },
        defaultQuery: this.defaultQuery,
        module,
        columnPreferences,
      });
      return dispatch(this.createFetchAction(module, "LIST_INITIALIZE"));
    };
  }

  addItems({ module, items }) {
    return (dispatch) => {
      const entity = createEntity(items, this.schema);
      const normalization = entities.normalize(entity, this.schema);
      dispatch({
        type: "ADD_ENTITIES",
        ...normalization,
      });
      dispatch({
        type: "LIST_ADD_ITEMS",
        module,
        items: normalization.result,
        hasPagination: this.hasPagination,
      });
    };
  }

  removeItems({ module, items }) {
    return {
      type: "LIST_REMOVE_ITEM",
      module,
      items,
    };
  }

  nextPage(module) {
    return (dispatch, getState) => {
      const listState = getState().list[module];
      if (listState.isLoading || !listState.nextToken) {
        return;
      }
      dispatch({ type: "LIST_NEXT_PAGE", module });
      return dispatch(this.fetchItems(module));
    };
  }

  goToPage({ module, pageNumber }) {
    return (dispatch, getState) => {
      const { count, query } = getState().list[module] || {};
      const lastPageNumber = Math.ceil(count / query.limit);
      if (pageNumber > lastPageNumber) {
        return;
      }
      dispatch({
        type: "LIST_GO_TO_PAGE",
        module,
        currentPageNumber: pageNumber,
      });
      dispatch(this.debouncedFetchItems(module));
    };
  }

  fetchItems = (module) => {
    return (dispatch) => {
      return dispatch(this.createFetchAction(module, "LIST_FETCH_ITEMS"));
    };
  };

  refreshItems = (module) => {
    return (dispatch) => {
      return dispatch(this.createFetchAction(module, "LIST_REFRESH_ITEMS"));
    };
  };

  createFetchAction(module, type) {
    return (dispatch, getState) => {
      const { query, currentPageNumber, token } = getState().list[module] || {};
      let offset;
      if (this.hasPagination) {
        offset = (currentPageNumber - 1) * query.limit;
      }

      const promise = async () => {
        if (!this.fetchData && !this.dataFetcher) {
          return {
            items: [],
          };
        }

        let data = {
          items: [],
        };

        const fetchQuery = {
          ...query,
          continue: token !== "initial" ? token : undefined,
          offset,
        };

        if (this.dataFetcher) {
          const dataFetcher = this.identifier
            ? this.dataFetcher.key(this.identifier)
            : this.dataFetcher;

          await dispatch(dataFetcher.fetch(fetchQuery));
          data = dataFetcher.selector(getState()).result;
        }

        if (this.fetchData) {
          data = await this.fetchData(fetchQuery);
        }

        dispatch({
          type: "LIST_SET_ITEMS_META",
          nextToken: data?.listmeta?.continue,
          count: data?.listmeta?.count,
          module,
        });

        return data?.items || [];
      };

      const apiCall = promise();
      const requestId = uuid();
      dispatch({
        type,
        module,
        promise: apiCall,
        token,
        schema: this.schema,
        currentPageNumber,
        hasPagination: this.hasPagination,
        requestId,
      });

      return apiCall;
    };
  }

  changeQuery({ name, value, module }) {
    return (dispatch) => {
      dispatch({
        module: module,
        type: "LIST_CHANGE_QUERY",
        name,
        value,
        hasPagination: this.hasPagination,
      });
      dispatch(this.saveFilterPreferences(module));
      dispatch(this.debouncedFetchItems(module));
    };
  }

  batchChangeQuery({ module, query }) {
    return (dispatch, getState) => {
      if (isEqual(query, getState().list[module]?.query)) return;

      dispatch({
        module,
        type: "BATCH_CHANGE_QUERY",
        query,
        hasPagination: this.hasPagination,
      });
      dispatch(this.debouncedFetchItems(module));
    };
  }

  getColumnsSettings(module) {
    return (_, getState) => {
      return getState()?.list?.[module]?.columns || {};
    };
  }

  onPageSizeChange({ module, newLimit }) {
    return (dispatch) => {
      dispatch(
        this.changeQuery({
          module,
          name: "limit",
          value: newLimit,
        })
      );
      dispatch(this.saveFilterPreferences(module));
    };
  }

  onColumnResize({ module, key, newWidth }) {
    return (dispatch) => {
      dispatch({
        module,
        type: "RESIZE_COLUMN",
        key,
        value: newWidth,
      });
      dispatch(savePreferences({ module }));
    };
  }

  saveFilterPreferences(module) {
    return (_, getState) => {
      if (!this.persistFilters) {
        return;
      }
      const query = getState()?.list[module]?.query || {};
      const cache = listingTablePreferences.get(module) || {};

      const newCache = {
        ...cache,
        query,
      };

      listingTablePreferences.set(module, {
        ...newCache,
      });
    };
  }
}

const updateColumnStateArray = (value, stateArray, key) => {
  const newStateArray = [...stateArray];
  if (value) {
    const index = newStateArray.findIndex((elem) => elem === key);
    newStateArray.splice(index, 1);
  } else {
    newStateArray.push(key);
  }

  return newStateArray;
};

const savePreferences = ({ module, reset = false }) => {
  return (_, getState) => {
    const updates = getState()?.list[module]?.columns || {};
    const cache = listingTablePreferences.get(module) || {};
    const { columnPreferences = {} } = cache;
    const newColumnPreferences = !reset
      ? {
          ...columnPreferences,
          ...updates,
        }
      : {};
    listingTablePreferences.set(module, {
      ...cache,
      columnPreferences: newColumnPreferences,
    });
  };
};

export const toggleShowColumn = ({ module, key, value }) => {
  return (dispatch, getState) => {
    const columnSettings = getState()?.list[module]?.columns || {};
    const hidden = [...columnSettings?.hidden] || [];
    const newHidden = updateColumnStateArray(value, hidden, key);
    dispatch({
      module,
      type: "TOGGLE_SHOW_COLUMN",
      value: newHidden,
    });
    dispatch(savePreferences({ module }));
  };
};

export const togglePin = ({ module, key, value, scrollableColumnsKeys }) => {
  return (dispatch, getState) => {
    const columnSettings = getState()?.list[module]?.columns || {};
    const pinned = [...columnSettings?.pinned] || [];
    const scrollable = [...scrollableColumnsKeys] || [];
    const newPinned = updateColumnStateArray(!value, pinned, key);

    if (value) {
      const index = scrollable.findIndex((elem) => elem === key);
      scrollable.splice(index, 1);
    } else {
      scrollable.unshift(key);
    }

    dispatch({
      module,
      type: "TOGGLE_PIN",
      pinned: newPinned,
      scrollable,
    });
    dispatch(savePreferences({ module }));
  };
};

export const dragAndDropColumn = ({ module, key, value }) => {
  return (dispatch) => {
    dispatch({
      module,
      type: "DND_COLUMNS",
      key,
      value,
    });
    dispatch(savePreferences({ module }));
  };
};

export const resetColumnsPreferences = (module) => {
  return (dispatch) => {
    dispatch({
      module,
      type: "RESET_COLUMNS",
    });
    dispatch(savePreferences({ module, reset: true }));
  };
};
