import React from "react";
import styled from "styled-components";
import * as COLORS from "utils/constants/colors";
import AppWidget from "./widgets/AppWidget";
import AppProfileWidget from "./widgets/AppProfileWidget";
import { pageWidthConstrain } from "components/styled/mixins";
import ClusterWidget from "./widgets/ClusterWidget";
import MetricsWidget from "./widgets/MetricsWidget";
import Permissions from "components/ui/Permissions";
import WelcomeWidget from "./widgets/WelcomeWidget";
import {
  GettingStartedInfoWidget,
  MultiTierModelingInfoWidget,
  VirtualClustersInfoWidget,
} from "./widgets/InfoWidgets";
import { getTotalCountsOfAppDeployments } from "state/appModeOverview/selectors/appDeploymentsWidget";
import { connect } from "react-redux";
import Loader from "components/ui/Loader";

const WidgetRow = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: ${(props) => (props.flexWrap ? "wrap" : "nowrap")};
`;

const Card = styled.div`
  background: ${(props) => props.backgroundColor || COLORS.white};
  border-radius: 3px;
  overflow: hidden;
  padding: 12px;
  margin: 16px 16px;
  flex-grow: ${(props) => props.flexGrow || 1};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  flex-basis: ${(props) => props.basis};
`;

const ListingWrap = styled.div`
  height: 100%;
  margin: 0;
  ${pageWidthConstrain}
  display: flex;
  flex-direction: column;
`;

const InfoWidgets = () => {
  return (
    <>
      <Card backgroundColor={COLORS.titanWhite} basis="30%">
        <GettingStartedInfoWidget />
      </Card>
      <Permissions has={["appDeployment.list", "appDeployment.get"]}>
        <Card basis="30%">
          <MultiTierModelingInfoWidget />
        </Card>
      </Permissions>
      <Permissions has={["virtualCluster.list", "virtualCluster.get"]}>
        <Card basis="30%">
          <VirtualClustersInfoWidget />
        </Card>
      </Permissions>
    </>
  );
};

const Overview = ({ appDeploymentCount, isLoading }) => {
  return isLoading ? (
    <Loader />
  ) : (
    <ListingWrap>
      {!appDeploymentCount && (
        <>
          <WidgetRow>
            <Card>
              <WelcomeWidget />
            </Card>
          </WidgetRow>
          <WidgetRow>
            <InfoWidgets />
          </WidgetRow>
        </>
      )}
      <WidgetRow flexWrap>
        <Permissions has={["appDeployment.list", "appDeployment.get"]}>
          <Card width={"823px"} flexWrap="wrap">
            <AppWidget />
          </Card>
        </Permissions>
        <Permissions has={["virtualCluster.list", "virtualCluster.get"]}>
          <Card width={"562px"} flexWrap="wrap">
            <ClusterWidget />
          </Card>
        </Permissions>
      </WidgetRow>
      <WidgetRow>
        <Card>
          <MetricsWidget />
        </Card>
      </WidgetRow>
      <WidgetRow>
        <Permissions has={["appProfile.list", "appProfile.get"]}>
          <Card>
            <AppProfileWidget />
          </Card>
        </Permissions>
      </WidgetRow>
      {appDeploymentCount > 0 && (
        <WidgetRow>
          <InfoWidgets />
        </WidgetRow>
      )}
    </ListingWrap>
  );
};

export default connect((state) => ({
  appDeploymentCount: getTotalCountsOfAppDeployments(state),
  isLoading: state.appModeOverview?.isDashboardLoading,
}))(Overview);
