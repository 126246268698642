import React from "react";
import { useTranslation } from "react-i18next";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import styled from "styled-components";
import Icon from "components/ui/Icon";
import {
  faChevronDown,
  faInfoCircle,
} from "@fortawesome/pro-regular-svg-icons";
import { Input, Tooltip } from "antd";
import { Checkbox } from "components/styled/Checkbox";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import EmptyResults from "components/ui/EmptyResults";

export interface SelectOption {
  label: React.ReactNode;
  value: string;
  info?: React.ReactNode;
}

const StyledTrigger = styled(DropdownMenu.Trigger)`
  padding: 0 12px;
  border-radius: 4px;
  background-color: rgba(31, 38, 60, 0.06);
  min-height: 32px;
  display: flex;
  align-items: center;

  > * + * {
    margin-left: 8px;
  }
`;

const StyledContent = styled(DropdownMenu.Content)`
  border-radius: 4px;
  border: 1px solid #dee1ea;
  background: #fff;
  box-shadow: 0px 0px 1px 0px rgba(9, 30, 66, 0.31),
    0px 8px 12px 0px rgba(9, 30, 66, 0.15);
  min-width: var(--radix-dropdown-menu-trigger-width);
  max-width: 320px;
  z-index: 999;
  position: relative;

  .ItemWrap {
    min-height: 32px;
    display: flex;
    align-items: center;
    padding: 0 16px;
    cursor: pointer;

    &[data-state="checked"] {
      background: #dcdaf9;
    }

    &:hover,
    &:focus {
      background: rgba(31, 38, 60, 0.06);
      outline: none;
    }

    > * + * {
      margin-left: 8px;
    }

    &.Spaced {
      justify-content: space-between;
    }

    .Label {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .InfoWrap {
      font-size: 12px;
      display: flex;
      justify-content: flex-end;
      flex-grow: 1;

      > * + * {
        margin-left: 4px;
      }
    }
  }
`;

const Selection = styled.span`
  padding: 0px 4px;
  min-width: 16px;
  height: 16px;
  background-color: #7d77ca;
  color: #fff;
  border-radius: 4px;
  font-size: 12px;
  line-height: 16px;
`;

const SearchInputWrap = styled.div`
  padding: 4px;
  position: relative;

  input {
    padding-right: 32px;
  }

  .anticon {
    position: absolute;
    right: 8px;
    height: 100%;
    display: flex;
    align-items: center;
    top: 0;
    padding: 0px 8px;
  }
`;

const OptionsListWrap = styled.div`
  max-height: 320px;
  overflow-y: auto;
`;

function HightlightSearchTerm({
  text,
  searchTerm,
}: {
  text: string;
  searchTerm: string;
}) {
  if (!searchTerm) {
    return <>{text}</>;
  }

  const parts = text.split(new RegExp(`(${searchTerm})`, "gi"));

  return (
    <>
      {parts.map((part, index) => {
        if (part.toLowerCase() === searchTerm.toLowerCase()) {
          return <strong key={index}>{part}</strong>;
        }

        return part;
      })}
    </>
  );
}

export default function Select({
  mode = "single",
  value,
  options = [],
  label,
  onChange,
  searchEnabled = false,
  onSearch,
  searchPlaceholder,
}: {
  options: SelectOption[];
  label: React.ReactNode;
} & (
  | {
      searchEnabled: true;
      onSearch: (searchTerm: string) => void;
      searchPlaceholder: React.ReactNode;
    }
  | {
      searchEnabled: false;
    }
) &
  (
    | {
        mode: "single";
        value?: SelectOption["value"];
        onChange: (value: SelectOption["value"]) => void;
      }
    | {
        mode: "multiple";
        value?: SelectOption["value"][];
        onChange: (value: SelectOption["value"][]) => void;
        searchEnabled?: boolean;
      }
  )) {
  const [opened, setOpened] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState("");
  const { t } = useTranslation();

  function renderSearchField() {
    if (!searchEnabled) {
      return null;
    }

    return (
      <SearchInputWrap>
        <Input
          value={searchTerm}
          placeholder={searchPlaceholder}
          onKeyDown={(ev) => {
            ev.stopPropagation();
          }}
          onChange={(ev) => {
            const value = ev.target.value;
            setSearchTerm(value);
            onSearch(value);
          }}
        />
        {searchTerm ? (
          <Icon awesome={faTimes} onClick={() => setSearchTerm("")} />
        ) : null}
      </SearchInputWrap>
    );
  }

  function renderOptionInfo(option: SelectOption) {
    if (!option.info) {
      return null;
    }

    return (
      <Tooltip title={option.info} placement="right">
        <Icon awesome={faInfoCircle} />
      </Tooltip>
    );
  }

  function renderOptions() {
    if (!options.length) {
      return (
        <EmptyResults
          {...(searchTerm
            ? { description: t("No matching tags for this search query") }
            : {})}
        />
      );
    }

    if (mode === "single") {
      return (
        <DropdownMenu.RadioGroup value={value} onValueChange={onChange}>
          {options.map((option) => (
            <DropdownMenu.RadioItem
              className="ItemWrap Spaced"
              value={option.value}
              key={option.value}
            >
              <span className="Label">{option.label}</span>
              <span className="InfoWrap">
                <DropdownMenu.ItemIndicator>
                  <Icon awesome={faCheck} />
                </DropdownMenu.ItemIndicator>
                {renderOptionInfo(option)}
              </span>
            </DropdownMenu.RadioItem>
          ))}
        </DropdownMenu.RadioGroup>
      );
    }

    if (mode === "multiple") {
      return options.map((option) => (
        <DropdownMenu.CheckboxItem
          key={option.value}
          className="ItemWrap"
          checked={value?.includes(option.value)}
          onCheckedChange={(checked) => {
            if (checked) {
              onChange([...(value || []), option.value]);
            } else {
              onChange((value || []).filter((v) => v !== option.value));
            }
          }}
        >
          <Checkbox checked={value?.includes(option.value)} />
          <span className="Label">
            <HightlightSearchTerm text={option.label} searchTerm={searchTerm} />
          </span>
          <span className="InfoWrap">{renderOptionInfo(option)}</span>
        </DropdownMenu.CheckboxItem>
      ));
    }
  }

  function renderSelection() {
    if (mode === "single" && value) {
      return 1;
    }

    if (mode === "multiple" && value) {
      return value.length;
    }

    return null;
  }

  const selection = renderSelection();

  return (
    <DropdownMenu.Root
      open={opened}
      onOpenChange={(open) => {
        if (open || mode === "single") {
          setOpened(open);
        }
      }}
    >
      <StyledTrigger>
        <span>{label}</span>
        {selection ? <Selection>{selection}</Selection> : null}
        <span>
          <Icon awesome={faChevronDown} />
        </span>
      </StyledTrigger>
      <DropdownMenu.Portal>
        <StyledContent
          sideOffset={5}
          onInteractOutside={() => {
            setOpened(false);
            setSearchTerm("");
            onSearch("");
          }}
          onClick={(ev) => {
            if (mode === "multiple") {
              ev.preventDefault();
              ev.stopPropagation();
            }
          }}
        >
          {renderSearchField()}
          <OptionsListWrap>{renderOptions()}</OptionsListWrap>
        </StyledContent>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
}
