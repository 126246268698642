import React, { createContext, useContext, useState, useEffect } from "react";
import styled from "styled-components";
import { Select as AntSelect, Tooltip, Tag } from "antd";

import { blue, orange } from "utils/constants/colors";
import { MaxTagPlaceholder } from "components/ui/Fields";
import { InfoCircleOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
//

const Select = styled(AntSelect)`
  .ant-select-arrow.ant-select-arrow-loading {
    color: ${blue};
  }

  .ant-select-selection-item > span {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .ant-select-selection-item .ant-select-selection-item-remove {
    min-width: 10px;
  }

  .ant-select-selection-overflow-item {
    > span {
      max-width: 100%;
    }
    .ant-tag {
      display: flex;
      align-items: center;
    }
  }
`;

const LabelOption = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .anticon.anticon-info-circle {
    margin-left: 11px;
  }
`;

const DescriptionTooltipWrap = styled.span`
  cursor: default;
`;

const TagLabel = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
`;

export const OptionText = styled.span`
  flex-grow: 1;
`;

// TODO: needs some improvement in case the value doesn't reflect the title and
// the title is a React component
function DEFAULT_FILTER_OPTION(searchValue, option) {
  const title = option?.cleanLabel;

  if (!title) {
    return false;
  }

  if (typeof title === "object") {
    return option.value.toLowerCase()?.includes(searchValue.toLowerCase());
  }

  return title.toLowerCase().includes(searchValue.toLowerCase());
}

const OpenTrackerContext = createContext({});

export const OpenTrackerProvider = ({ children, onChange }) => {
  const [visible, setVisible] = useState([]);

  useEffect(() => {
    onChange?.(visible);
  }, [onChange, visible]);

  const toggle = (name) => {
    setVisible((visible) => {
      if (visible.includes(name)) {
        return visible.filter((elem) => elem !== name);
      }

      return [...visible, name];
    });
  };

  return (
    <OpenTrackerContext.Provider value={{ toggle, visible }}>
      {children}
    </OpenTrackerContext.Provider>
  );
};

export default function SelectWithOptions(
  {
    options = [],
    children,
    // NOTE: This will get deprecated, use searchBy instead
    filterOption = DEFAULT_FILTER_OPTION,
    showSearch = true,
    showTooltip = false,
    allowClear = false,
    showSelectedDescription = false,
    value,
    mode,
    searchBy,
    ...rest
  },
  ref
) {
  const { toggle } = useContext(OpenTrackerContext);
  function renderOption(option, index) {
    if (option.isGroup) {
      return (
        <Select.OptGroup label={option.label} key={index}>
          {option.options.map(renderOption)}
        </Select.OptGroup>
      );
    }

    const descriptionTooltip = option.description && (
      <Tooltip title={option.description} placement="right">
        <DescriptionTooltipWrap>
          {option.descriptionType === "warning" ? (
            <FontAwesomeIcon
              icon={faExclamationCircle}
              style={{ color: orange }}
            />
          ) : (
            <InfoCircleOutlined />
          )}
        </DescriptionTooltipWrap>
      </Tooltip>
    );

    return (
      <Select.Option
        data-qa={`${rest["data-qa"]}-option`}
        data-qa-index={index}
        data-qa-value={option.value}
        key={option.value}
        value={option.value}
        disabled={option.disabled}
        cleanLabel={option.label}
        label={
          <>
            <OptionText>{option.label}</OptionText>
            {showSelectedDescription && descriptionTooltip}
          </>
        }
        {...{ [searchBy]: option[searchBy] }}
      >
        <LabelOption>
          {showTooltip ? (
            <Tooltip title={option.label} placement="right">
              <OptionText>{option.label}</OptionText>
            </Tooltip>
          ) : (
            <OptionText>{option.label}</OptionText>
          )}
          {descriptionTooltip}
        </LabelOption>
      </Select.Option>
    );
  }

  return (
    <Select
      ref={ref}
      {...(mode === "tags" && { maxTagCount: "responsive" })}
      maxTagPlaceholder={(values) => (
        <MaxTagPlaceholder omittedValues={values} />
      )}
      optionFilterProp={searchBy}
      {...rest}
      optionLabelProp="label"
      showSearch={showSearch}
      filterOption={showSearch && searchBy ? true : filterOption}
      allowClear={allowClear && !!value}
      value={value}
      mode={mode}
      onDropdownVisibleChange={() => {
        rest?.onDropdownVisibleChange?.();
        toggle?.(rest.name);
      }}
      tagRender={({ value, label, ...rest }) => {
        let tag = label || "";
        if (typeof label !== "string") {
          const selectedOption = options.find((opt) => opt.value === value);
          if (selectedOption) {
            tag = selectedOption.label;
          }
        }
        return (
          <Tag key={value} title={tag} {...rest}>
            <TagLabel>{tag}</TagLabel>
          </Tag>
        );
      }}
    >
      {children ? children : (options || []).map(renderOption)}
    </Select>
  );
}
