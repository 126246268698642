import React, { useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import * as colors from "utils/constants/colors";
import { TextButton } from "components/ui/Button";
import Descriptions, { ItemContainer } from "components/common/Descriptions";
import Drawer from "components/ui/Drawer";

import createProjectRoleManagement from "modules/roleManagement/ProjectRoles";
import createTenantRoleManagement from "modules/roleManagement/TenantRoles";
import createWorkspaceRoleManagement from "modules/roleManagement/WorkspaceRoles";
import createResourceRoleManagement from "modules/roleManagement/ResourceRoles";

import {
  projectRolesListActions,
  updateData,
} from "state/roleManagement/actions/projectRoles";

import {
  updateData as tenantRolesUpdateData,
  getSelectedEntityRoles,
  tenantRolesListActions,
} from "state/roleManagement/actions/tenantRoles";

import {
  updateData as workspaceRolesUpdateData,
  workspaceRolesListActions,
} from "state/roleManagement/actions/workspaceRoles";

import {
  updateData as resourceRolesUpdateData,
  resourceRolesListActions,
} from "state/roleManagement/actions/resourceRoles";

import { userDetailsModal } from "state/users/actions";
import { getFullName } from "utils/presenters";
import Clipboard from "components/common/Clipboard";
import Ellipsis from "components/common/Ellipsis";
import Icon from "components/ui/Icon";
import { faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import Tabs from "components/common/Tabs";
import Switch from "components/common/Switch";

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;

const DetailsContainer = styled.div`
  width: 320px;
  height: 100%;
  padding: 24px;
  border-right: 1px solid ${colors.lightMidGray};

  ${ItemContainer} {
    margin-bottom: 0;
    padding: 13px 0 15px;

    :nth-child(n + 2) {
      border-top: 1px solid ${colors.concreteWhite};
    }

    :last-child {
      border-bottom: 1px solid ${colors.concreteWhite};
      margin-bottom: 16px;
    }
  }
`;

const TabsContainer = styled.div`
  flex-grow: 1;
  background: white;
  padding: 24px;
`;

const GroupedButtons = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ProjectRolesManagement = createProjectRoleManagement({
  listActions: projectRolesListActions,
  updateData,
});

const TenantRolesManagement = createTenantRoleManagement({
  updateData: tenantRolesUpdateData,
  getSelectedEntityRoles,
  entityType: "user",
  listActions: tenantRolesListActions,
});

const WorkspaceRolesManagement = createWorkspaceRoleManagement({
  updateData: workspaceRolesUpdateData,
  listActions: workspaceRolesListActions,
});

const ResourceRolesManagement = createResourceRoleManagement({
  updateData: resourceRolesUpdateData,
  listActions: resourceRolesListActions,
});

const UserInformation = ({ user, onUserRemove, resetUserPassword }) => {
  const { t } = useTranslation();
  const { emailId, teams = [] } = user.spec;
  const { isActive, activationLink, isPasswordResetting } = user.status;
  const [activeKey, setActiveTab] = useState("project-roles");
  const onTabChange = (activeKey) => setActiveTab(activeKey);

  const userOptions = [
    {
      label: "Name",
      value: getFullName(user) || "-",
    },
    {
      label: t("Email"),
      value: emailId,
    },
    {
      label: t("Team"),
      value: teams.map((team) => team.metadata.name).join(", ") || "-",
    },
    activationLink &&
      isPasswordResetting && {
        label: isActive ? t("Reset password link") : t("Activation link"),
        value: (
          <Clipboard text={activationLink}>
            <Ellipsis>{activationLink}</Ellipsis>
          </Clipboard>
        ),
      },
  ].filter(Boolean);

  const tabs = [
    {
      label: t("Project Roles"),
      key: "project-roles",
    },
    {
      label: t("Tenant Roles"),
      key: "tenant-roles",
    },
    {
      label: t("Workspace Roles"),
      key: "workspace-roles",
    },
    {
      label: t("Resource Roles"),
      key: "resource-roles",
    },
  ];

  return (
    <Drawer
      width="1200"
      service={userDetailsModal}
      title={t("User Details")}
      footer={null}
      hasSidebar
    >
      <Container>
        <DetailsContainer>
          <Descriptions options={userOptions} variation="drawer-side-details" />
          <GroupedButtons>
            <TextButton
              secondary
              data-qa="reset_password"
              onClick={resetUserPassword}
            >
              <Icon awesome={faEdit} />
              {t("Reset password")}
            </TextButton>

            <TextButton danger data-qa="remove_user" onClick={onUserRemove}>
              <Icon awesome={faTrashAlt} />
              {t("Delete user")}
            </TextButton>
          </GroupedButtons>
        </DetailsContainer>
        <TabsContainer>
          <Tabs tabs={tabs} activeKey={activeKey} changeTab={onTabChange}>
            <Switch value={activeKey}>
              <Switch.Case value="project-roles">
                <ProjectRolesManagement />
              </Switch.Case>
              <Switch.Case value="tenant-roles">
                <TenantRolesManagement />
              </Switch.Case>
              <Switch.Case value="workspace-roles">
                <WorkspaceRolesManagement />
              </Switch.Case>
              <Switch.Case value="resource-roles">
                <ResourceRolesManagement />
              </Switch.Case>
            </Switch>
          </Tabs>
        </TabsContainer>
      </Container>
    </Drawer>
  );
};

export default UserInformation;
