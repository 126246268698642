import i18n from "i18next";
import { createSelector } from "reselect";

import _ from "lodash";
import { getEntity } from "utils/entities";
import {
  ClusterProfileSchema,
  CredentialSchema,
  SSHSchema,
  RegionSchema,
  VpcidSchema,
  SSHKeysSchema,
} from "utils/schemas";
import {
  presentClusterProfileLayers,
  formatTags,
  groupPresetsOptions,
  generateEditorYamlSchema,
  presentDatacenterFolders,
} from "utils/presenters";
import { getVsphereFolderPayload, getCronScheduleValue } from "utils/parsers";

import { getCurrentStep } from "modules/wizard/selectors";
import {
  propertiesFetcher,
  datacentersFetcher,
  virtualNetworksFetcher,
  clusterCreateProfileModule,
  cloudAccountsFetcher,
  appliancesKeyedFetcher,
  getSelectedCloudAccount,
} from "state/cluster/services/create";
import {
  azureInstanceTypesFetcher,
  azureAZFetcher,
  gcpAZFetcher,
  gcpInstanceTypesFetcher,
} from "state/cluster/services/nodes";

import { dnsMappingsFetcher } from "state/dns/services";
import { getScheduledBackupPayload } from "state/backups/selectors";
import { getRoleBindingsPayload } from "state/cluster/selectors/rolebindings";
import { getClusterNamespacesPayload } from "state/cluster/actions/workloads";
import { getRawCluster } from "./details";
import {
  COXEDGE_ENVIRONMENT,
  getEnvironments,
  MANAGED_TO_PURE_ENVIRONMENT,
} from "utils/constants";
import store from "services/store";
import { openstackCloudForm } from "../actions/create/flows/openstack";
import { geocodingForm } from "state/cluster/actions/create/form";
import { cleanEmptyKeys } from "utils/objects";
import { presentAwsSubnet } from "utils/presenters/clouds/aws";
import { edgeMachinesFetcher } from "../services/create/flows/edge";
import { isOnPremApplication } from "state/auth/selectors";

export const getSelectedClusterProfile = getEntity(
  (state) => state.forms?.cluster?.data?.clusterprofile,
  ClusterProfileSchema
);

export const getSelectedClusterProfilePacks = createSelector(
  getSelectedClusterProfile,
  (profile) => {
    return profile?.spec?.published?.packs || [];
  }
);

export const getAzureCNILayer = ({ name, layer }) =>
  name === "cni-azure" && layer === "cni";

export const hasAzureCNIOnCreate = createSelector(
  getSelectedClusterProfile,
  (profile) => profile?.spec?.published?.packs?.find(getAzureCNILayer)
);

export const getInitialClusterProfile = getEntity(
  (state) => state.forms?.cluster?.initialData?.clusterprofile,
  ClusterProfileSchema
);

export const getFormError = createSelector(
  getCurrentStep("cluster"),
  (state) => state.forms.cluster,
  (currentStep, formState) => {
    if (!formState) {
      return null;
    }
    const { errors } = formState;

    if (!errors.length) {
      return null;
    }

    let message = i18n.t(
      "Please resolve the errors on the page in order to continue"
    );

    if (errors.find((err) => err.field === "clusterprofile")) {
      message = i18n.t("Please select a profile in order to continue");
    }

    if (errors.find((err) => err.field === "layers")) {
      message = i18n.t(
        "One or more packs have errors. Please resolve them in order to continue"
      );
    }

    if (errors.find((err) => err?.showInWizardFooter)) {
      message = i18n.t("At least one node pool should not contain taints");
    }

    return message;
  }
);

export const getCredentials = getEntity(
  (state) => state.cluster.create.credentials,
  [CredentialSchema]
);

export const getCredentialsAsOptions = createSelector(
  getCredentials,
  (items) => {
    return items.map((item) => {
      return {
        label: item.metadata.name,
        value: item.metadata.uid,
        description: item.metadata?.annotations?.description,
      };
    });
  }
);

export const getSelectedCredential = createSelector(
  cloudAccountsFetcher.selector,
  (state) => state.forms.cluster?.data.credential,
  (credentials, value) => {
    return credentials?.result?.find(
      (credential) => credential.metadata.uid === value
    );
  }
);

export const getSSHs = getEntity(
  (state) => state.cluster.create.sshs,
  [SSHSchema]
);

export const getRegions = getEntity(
  (state) => state.cluster.create.regions,
  [RegionSchema]
);

export const getSelectedCloud = createSelector(
  getSelectedClusterProfile,
  (selectedClusterProfile) => {
    const cloudType = selectedClusterProfile?.spec?.published?.cloudType;
    return cloudType;
  }
);

export const getSelectedEnvironmentType = createSelector(
  getSelectedCloud,
  (cloudType) => {
    let environment = cloudType;
    if (MANAGED_TO_PURE_ENVIRONMENT[cloudType]) {
      environment = MANAGED_TO_PURE_ENVIRONMENT[cloudType];
    }

    return environment;
  }
);

export const getSelectedEnvironment = createSelector(
  getEnvironments,
  getSelectedCloud,
  (ENVIRONMENTS, cloudType) => {
    return ENVIRONMENTS.find((env) => env.apiKey === cloudType);
  }
);

export const isSupervizedEnvironment = createSelector(
  getSelectedCloud,
  (cloudType) => {
    return !!MANAGED_TO_PURE_ENVIRONMENT[cloudType];
  }
);

const getSelectedSSHEntities = getEntity(
  (state) => state.forms.cluster?.data?.sshKeys,
  [SSHKeysSchema]
);
const getSelectedSSHEntity = getEntity(
  (state) => state.forms.cluster?.data?.sshKey,
  SSHKeysSchema
);

export const getSelectedSSHKeys = createSelector(
  getSelectedSSHEntities,
  (entities) => entities?.map((entity) => entity?.spec?.publicKey) || []
);

export const getSelectedSSHKey = createSelector(
  getSelectedSSHEntity,
  (entity) => entity?.spec?.publicKey || ""
);

const getOsPatchingSchedule = createSelector(
  (state) => state.forms?.cluster?.data,
  (data) => {
    return getCronScheduleValue(
      data?.osPatchingScheduleOption,
      data?.osPatchingSchedule
    );
  }
);

export function getHostClusterConfig(data) {
  const { clusterEndpointType, isHostCluster, ingressHost } = data || {};

  const getIngressConfig = () => {
    if (!isHostCluster || clusterEndpointType !== "Ingress") {
      return null;
    }
    return {
      ingressConfig: {
        host: `*.${ingressHost}`,
      },
    };
  };

  const ingressConfig = getIngressConfig();
  const config = clusterEndpointType === "Ingress" && ingressConfig;

  return {
    isHostCluster: !!isHostCluster,
    clusterEndpoint: {
      type: clusterEndpointType,
      ...(config ? { config } : {}),
    },
  };
}

export function getNodeCommonPoolConfig(nodePool) {
  const sizeConfiguration =
    nodePool.isAutoscalerEnabled && !nodePool.isMaster
      ? {
          maxSize: nodePool.maxNodeSize,
          minSize: nodePool.minNodeSize,
          size: nodePool.minNodeSize,
        }
      : { size: nodePool.size };
  return {
    name: nodePool.poolName,
    labels: [nodePool.isMaster ? "control-plane" : "worker"],
    isControlPlane: nodePool.isMaster,
    useControlPlaneAsWorker: nodePool.useControlPlaneAsWorker,
    taints: nodePool.taints || [],
    additionalLabels: formatTags(nodePool.additionalLabels) || {},
    nodeRepaveInterval: nodePool.nodeRepaveInterval,
    updateStrategy: {
      type: nodePool.updateStrategy,
    },
    ...(nodePool.architecture
      ? {
          machinePoolProperties: {
            archType: nodePool.architecture || "",
          },
        }
      : {}),
    ...sizeConfiguration,
  };
}

export const hasAutoscalePack = createSelector(
  () => clusterCreateProfileModule.payload,
  (profiles) => {
    return profiles.some((profile) => {
      const packs = profile.packValues;
      return packs.some((pack) => pack.name === "aws-cluster-autoscaler");
    });
  }
);

function formatEdgeHosts(edgeHosts, isMasterPool = false) {
  const hostsArray = _.uniqBy(edgeHosts, "hostUid");
  return hostsArray.map((host, index) => {
    const {
      dns = [],
      gateway = "",
      ip = "",
      isDefault = false,
      macAddr = "",
      nicName = "",
      subnet = "",
      enableStaticIp,
      ...rest
    } = host;

    const isTwoNodeMode = hostsArray.length === 2 && isMasterPool;
    let twoNodeMode = {};

    if (isTwoNodeMode) {
      twoNodeMode = {
        twoNodeCandidatePriority: "primary",
      };

      if (index === 1) {
        twoNodeMode = {
          twoNodeCandidatePriority: "secondary",
        };
      }
    }

    return {
      ...twoNodeMode,
      ...rest,
      nic: {
        nicName,
        ...(enableStaticIp
          ? { dns, gateway, ip, isDefault, macAddr, subnet }
          : {}),
      },
    };
  });
}

export const getPayload = createSelector(
  (state) => state.forms?.cluster?.data,
  getSelectedCloud,
  getSelectedSSHKeys,
  getSelectedSSHKey,
  () => clusterCreateProfileModule.payload,
  getOsPatchingSchedule,
  getRoleBindingsPayload,
  geocodingForm.selectors.getPayload,
  getSelectedCloudAccount,
  hasAutoscalePack,
  isOnPremApplication,
  (
    data = {},
    cloudType,
    selectedSSHKeys,
    selectedSSHKey,
    profiles,
    osPatchingSchedule,
    roleBindings,
    location,
    selectedCloudAccount,
    hasAutoscalePack,
    isOnPremApplication
  ) => {
    const dnsSetting = data.nodePools?.[0]?.domains?.[0]?.dns?.spec?.dnsName;
    const backupPolicy = getScheduledBackupPayload(data);

    let policies = {
      scanPolicy: {},
      ...(Object.keys(backupPolicy).length ? { backupPolicy } : {}),
    };

    if (data.kubebenchEnabled) {
      policies.scanPolicy.kubeBench = {
        schedule: {
          scheduledRunTime: getCronScheduleValue(
            data.kubebench.type,
            data.kubebench.recurrency
          ),
        },
      };
    }

    if (data.kubehunterEnabled) {
      policies.scanPolicy.kubeHunter = {
        schedule: {
          scheduledRunTime: getCronScheduleValue(
            data.kubehunter.type,
            data.kubehunter.recurrency
          ),
        },
      };
    }

    if (data.sonobuoyEnabled) {
      policies.scanPolicy.sonobuoy = {
        schedule: {
          scheduledRunTime: getCronScheduleValue(
            data.sonobuoy.type,
            data.sonobuoy.recurrency
          ),
        },
      };
    }

    const azureNetworkInfo = data.hasDisablePropertiesRequest
      ? {
          controlPlaneSubnet: {
            name: data.controlPlaneSubnetName,
            cidrBlock: data.controlPlaneSubnetCidr,
            securityGroupName: data.controlPlaneSubnetSecurity,
          },
          workerSubnet: {
            name: data.workerSubnetName,
            cidrBlock: data.workerSubnetCidr,
            securityGroupName: data.workerSubnetSecurity,
          },
        }
      : {
          controlPlaneSubnet: JSON.parse(data.controlPlaneSubnet || "{}"),
          workerSubnet: JSON.parse(data.workerSubnet || "{}"),
        };

    const replaceMacros = (yaml, fields) => {
      let newYaml = `${yaml}`;

      function generateRegex(key) {
        return new RegExp(`\\$\\{${key}(?:=(?:"([^"]*)"|([^}]+)))?\\}`, "g");
      }
      const regexValuePair = Object.keys(fields || {}).reduce((accum, key) => {
        const field = fields[key] || {};
        if (!field.avoidResolve) {
          accum.push({
            regex: generateRegex(key),
            value: field?.value,
          });
        }
        return accum;
      }, []);

      regexValuePair.forEach(({ regex, value }) => {
        newYaml = newYaml.replaceAll(regex, value || `""`);
      });

      return newYaml;
    };

    const updateCloudConfigMacros = ({
      cloudConfigValues = "",
      cloudConfigMacros = {},
    }) => {
      return replaceMacros(cloudConfigValues, cloudConfigMacros);
    };

    const updateNodePoolConfigMacros = ({
      values = "",
      nodePoolConfigMacros = {},
    }) => {
      const { poolIndex, ...fields } = nodePoolConfigMacros;
      return replaceMacros(values, fields);
    };

    const cloudTypeConfig = {
      aws: {
        machinePoolConfig: data.nodePools?.map((nodePool) => {
          return {
            cloudConfig: {
              instanceType: nodePool.instanceType,
              capacityType:
                nodePool.instanceOption === "onDemand" ? "on-demand" : "spot",
              spotMarketOptions:
                nodePool.instanceOption === "onSpot"
                  ? {
                      maxPrice: `${(
                        (nodePool.instancePrice * nodePool.maxPricePercentage) /
                        100
                      ).toLocaleString(undefined, {
                        maximumFractionDigits: 5,
                      })}`,
                    }
                  : undefined,
              azs: nodePool.azs,
              additionalSecurityGroups: (
                nodePool?.additionalSecurityGroups || []
              ).map((id) => ({ id })),
              rootDeviceSize: nodePool.disk,
              subnets: nodePool.azs.reduce((acc, az) => {
                const azSubnets = nodePool[`subnet_${az}`];
                const id = Array.isArray(azSubnets)
                  ? azSubnets.join(",")
                  : azSubnets;

                if (azSubnets) {
                  acc.push({
                    az,
                    id,
                  });
                }
                return acc;
              }, []),
            },
            poolConfig: {
              ...getNodeCommonPoolConfig(nodePool),
            },
          };
        }),
        cloudConfig: {
          region: data.region,
          sshKeyName: data.ssh,
          vpcId: data.vpcid,
          controlPlaneLoadBalancer: data.apiServerPrivate ? "internal" : "",
        },
      },
      eks() {
        function getNodePoolPayload(nodePool) {
          return {
            cloudConfig: {
              instanceType: nodePool.instanceType,
              azs: nodePool.azs,
              rootDeviceSize: nodePool.disk,
              capacityType:
                nodePool.instanceOption === "onDemand" ? "on-demand" : "spot",
              ...(nodePool.launchTemplateEnabled
                ? {
                    awsLaunchTemplate: {
                      ami: { id: nodePool.amiId },
                      rootVolume: cleanEmptyKeys(nodePool.rootVolume),
                    },
                  }
                : {}),
              subnets: nodePool.azs.reduce((acc, az) => {
                const azSubnets = nodePool[`subnet_${az}`];
                const id = Array.isArray(azSubnets)
                  ? azSubnets.join(",")
                  : azSubnets;

                if (azSubnets) {
                  acc.push({
                    az,
                    id,
                  });
                }
                return acc;
              }, []),
            },
            poolConfig: {
              ...getNodeCommonPoolConfig({
                ...nodePool,
                isAutoscalerEnabled: hasAutoscalePack,
              }),
            },
          };
        }
        let machinePoolConfig = data.nodePools?.map(getNodePoolPayload);
        if (data.staticPlacement) {
          machinePoolConfig = [
            getNodePoolPayload({
              isMaster: true,
              poolName: "control-plane-pool",
              size: 0,
              azs: [],
              ...data.controlPlane,
            }),
            ...machinePoolConfig,
          ];
        }

        return {
          machinePoolConfig,
          cloudConfig: {
            region: data.region,
            sshKeyName: data.ssh,
            vpcId: data.vpcid,
            endpointAccess: {
              private: !!data?.endpointAccess?.includes("private"),
              public: !!data?.endpointAccess?.includes("public"),
              publicCIDRs: !!data?.endpointAccess?.includes("public")
                ? data.publicAccessCidrs
                : [],
              privateCIDRs: !!data?.endpointAccess?.includes("private")
                ? data.privateAccessCidrs
                : [],
            },
            ...(data?.enableEncryption
              ? {
                  encryptionConfig: {
                    isEnabled: true,
                    provider: data.provider,
                  },
                }
              : {}),
          },
          fargateProfiles: data.fargateProfiles.map((profile) => ({
            ...profile,
            selectors: profile.selectors.map((selector) => ({
              ...selector,
              labels: formatTags(selector.labels),
            })),
          })),
        };
      },
      vsphere: {
        cloudConfig: {
          ...(!!dnsSetting
            ? {
                controlPlaneEndpoint: {
                  type: "DDNS",
                  ddnsSearchDomain: dnsSetting,
                },
              }
            : {}),
          ...(data.clusterType === "edge"
            ? {
                controlPlaneEndpoint: {
                  host: data.vip,
                  type: "VIP",
                },
              }
            : {}),
          network: {
            networkName: data.network || "",
            staticIp: data.networkType === "staticIP",
          },
          placement: {
            datacenter: data.datacenter,
            folder: getVsphereFolderPayload(data),
            imageTemplateFolder: data.imageTemplateFolder || undefined,
            network: {
              networkName: data.network || "",
              staticIp: data.networkType === "staticIP",
            },
          },
          staticIp: data.networkType === "staticIP",
          sshKeys: selectedSSHKeys,
          ntpServers: data.ntpServers,
        },
        machinePoolConfig: data.nodePools?.map((nodePool) => {
          return {
            cloudConfig: {
              instanceType: {
                diskGiB: nodePool.disk,
                memoryMiB: nodePool.memory * 1024,
                numCPUs: nodePool.cpu,
              },
              placements: nodePool.domains.map(
                ({ network, parentPoolUid, dns, ...rest }) => ({
                  ...rest,
                  network: {
                    networkName: network,
                    staticIp: data.networkType === "staticIP",
                    parentPoolUid: parentPoolUid,
                  },
                })
              ),
            },
            poolConfig: {
              ...getNodeCommonPoolConfig(nodePool),
            },
          };
        }),
      },
      maas: {
        cloudConfig: {
          domain: data.domain,
        },
        machinePoolConfig: data.nodePools?.map((nodePool) => {
          return {
            cloudConfig: {
              instanceType: {
                minCPU: nodePool.minCPU,
                minMemInMB: nodePool.minMem * 1024,
              },
              azs: nodePool.azs,
              resourcePool: nodePool.resourcePool,
              tags: nodePool.tags,
            },
            poolConfig: {
              ...getNodeCommonPoolConfig(nodePool),
            },
          };
        }),
      },
      azure: {
        cloudConfig: {
          subscriptionId: data.subscriptionId,
          location: data.region,
          sshKey: selectedSSHKey,
          resourceGroup: data.resourceGroup,
          storageAccountName: data.storageAccountName,
          containerName: data.containerName,
          vnetName: data.vnetName,
          vnetResourceGroup: data.vnetResourceGroup,
          vnetCidrBlock: data.vnetCidrBlock,
          ...azureNetworkInfo,
          ...(data.hasTenantName && {
            aadProfile: {
              managed: data.managedAD,
              adminGroupObjectIDs: data.adminGroupObjectIDs,
            },
          }),
          infraLBConfig:
            isOnPremApplication ||
            (data.staticPlacement &&
              selectedCloudAccount?.metadata?.annotations?.overlordUid)
              ? {
                  apiServerLB: {
                    type: data.apiServerPrivate ? "Internal" : "Public",
                    ipAllocationMethod: data.ipAllocationMethod,
                    apiServerLBStaticIP: data.apiServerLBStaticIP,
                    privateDNSName: data.privateDNSName,
                  },
                }
              : undefined,
        },
        machinePoolConfig: data.nodePools?.map((nodePool) => {
          return {
            cloudConfig: {
              instanceType: nodePool.instanceType,
              azs: nodePool.azs,
              osDisk: {
                diskSizeGB: nodePool.disk,
                managedDisk: {
                  storageAccountType: nodePool.storageAccountType,
                },
                osType: nodePool.osType,
              },
            },
            poolConfig: {
              ...getNodeCommonPoolConfig(nodePool),
            },
          };
        }),
      },
      aks: {
        cloudConfig: {
          subscriptionId: data.subscriptionId,
          location: data.region,
          sshKey: selectedSSHKey,
          resourceGroup: data.resourceGroup,
          storageAccountName: data.storageAccountName,
          containerName: data.containerName,
          apiServerAccessProfile: {
            enablePrivateCluster: data.enablePrivateCluster,
          },
          vnetName: data.vnetName,
          vnetResourceGroup: data.vnetResourceGroup,
          vnetCidrBlock: data.vnetCidrBlock,
          ...azureNetworkInfo,
          ...(data.hasTenantName && {
            aadProfile: {
              managed: data.managedAD,
              adminGroupObjectIDs: data.adminGroupObjectIDs,
            },
          }),
        },
        machinePoolConfig: data.nodePools?.map((nodePool) => {
          return {
            managedPoolConfig: {
              isSystemNodePool: nodePool?.isSystemNodePool || false,
              osType: nodePool?.isSystemNodePool ? "Linux" : nodePool?.osType,
            },
            cloudConfig: {
              instanceType: nodePool.instanceType,
              azs: nodePool.azs,
              osDisk: {
                diskSizeGB: nodePool.disk,
                managedDisk: {
                  storageAccountType: nodePool.storageAccountType,
                },
              },
            },
            poolConfig: {
              ...getNodeCommonPoolConfig(nodePool),
              taints: !nodePool?.isSystemNodePool ? nodePool.taints || [] : [],
            },
          };
        }),
      },
      gcp: {
        cloudConfig: {
          project: data.project,
          region: data.region,
          network: data.network,
          sshKey: selectedSSHKey,
        },
        machinePoolConfig: data.nodePools?.map((nodePool) => {
          return {
            cloudConfig: {
              instanceType: nodePool.instanceType,
              azs: nodePool.azs,
              rootDeviceSize: nodePool.disk,
            },
            poolConfig: {
              ...getNodeCommonPoolConfig(nodePool),
            },
          };
        }),
      },
      gke: {
        cloudConfig: {
          project: data.project,
          region: data.region,
          network: data.network,
          sshKey: selectedSSHKey,
          managedClusterConfig: {
            location: data.region,
          },
        },
        machinePoolConfig: data.nodePools?.map((nodePool) => {
          return {
            cloudConfig: {
              instanceType: nodePool.instanceType,
              rootDeviceSize: nodePool.disk,
            },
            poolConfig: {
              ...getNodeCommonPoolConfig(nodePool),
            },
          };
        }),
      },
      openstack() {
        const selectors = openstackCloudForm.selectors;
        const state = store.getState();
        const selectedDomain = selectors.getOpenstackSelectedDomain(state);
        const selectedNetwork = selectors.getOpenstackSelectedNetwork(state);
        const subnets = selectors.getOpenstackNetworkSubnets(state);
        const flavorsData =
          openstackCloudForm.fetchers.flavorsFetcher.selector(state);
        const projects =
          openstackCloudForm.fetchers.projectsFetcher.selector(state)?.result;

        const selectedProject = (projects?.items || []).find(
          (project) => project.name === data.project
        );

        const subnet = subnets.find((subnet) => subnet.id === data.subnet);

        return {
          cloudConfig: {
            region: data.region,
            domain: _.pick(selectedDomain, ["id", "name"]),
            project: _.pick(selectedProject, ["id", "name"]),
            network: data.staticPlacement
              ? _.pick(selectedNetwork, ["id", "name"])
              : undefined,
            subnet,
            dnsNameservers: !data.staticPlacement
              ? data.dnsNameservers
              : undefined,
            nodeCidr: !data.staticPlacement ? data.nodeCidr : undefined,
            sshKeyName: data.sshKeyName,
          },
          machinePoolConfig: data.nodePools?.map((nodePool) => {
            const flavorConfig = (flavorsData.result || []).find(
              (flavor) => flavor.name === nodePool.flavor
            );
            const subnet = subnets.find(
              (subnet) => subnet.id === nodePool.subnet
            );
            return {
              cloudConfig: {
                flavorConfig: flavorConfig
                  ? {
                      numCPUs: flavorConfig.vcpus,
                      memoryMiB: flavorConfig.memory,
                      name: flavorConfig.name,
                      diskGiB: flavorConfig.disk,
                    }
                  : undefined,
                azs: nodePool.azs,
                diskGiB: nodePool.disk,
                subnet,
              },
              poolConfig: {
                ...getNodeCommonPoolConfig(nodePool),
              },
            };
          }),
        };
      },
      edge: {
        cloudConfig: {
          sshKeys: selectedSSHKeys,
        },
        machinePoolConfig: data.nodePools?.map((nodePool) => {
          return {
            cloudConfig: {
              // TODO uniqueness is only temporary. For now we only allow one device to be selected
              edgeHosts: _.uniqBy(nodePool.edgeHosts, "hostUid"),
            },
            poolConfig: {
              ...getNodeCommonPoolConfig(nodePool),
            },
          };
        }),
      },
      "edge-native": {
        cloudConfig: {
          controlPlaneEndpoint: {
            ddnsSearchDomain: dnsSetting,
            host: data.enableOverlay ? data.internalIp : data.ip,
            type: "IP",
          },
          ntpServers: data.ntpServers,
          sshKeys: selectedSSHKeys,
          staticIp: data.networkType === "staticIP",
          overlayNetworkConfiguration: {
            cidr: data.internalCIDR,
            enable: data.enableOverlay,
          },
        },
        machinePoolConfig: data.nodePools?.map((nodePool, index) => ({
          cloudConfig: {
            edgeHosts: formatEdgeHosts(nodePool.edgeHosts, index === 0),
          },
          poolConfig: {
            ...getNodeCommonPoolConfig(nodePool),
            size: nodePool.edgeHosts.length,
            maxSize: undefined,
            minSize: undefined,
          },
        })),
      },
      libvirt: {
        cloudConfig: {
          ntpServers: data.ntpServers,
          sshKeys: selectedSSHKeys,
          controlPlaneEndpoint: {
            host: data.vip,
            type: "VIP",
          },
        },
        machinePoolConfig: data.nodePools?.map((nodePool) => {
          return {
            cloudConfig: {
              instanceType: {
                numCPUs: nodePool.cpu,
                memoryInMB: nodePool.memory * 1024,
                cpuset: nodePool.cpuset,
                cpuPassthroughSpec: nodePool.cpuPassthroughSpec,
                gpuConfig: nodePool.gpuSupport
                  ? {
                      numGPUs: nodePool.numGPUs,
                      vendorName: nodePool.gpuVendor,
                      deviceModel: nodePool.gpuModel,
                    }
                  : undefined,
              },
              placements: nodePool.edgeHosts.map((edgeHost) => ({
                hostUid: edgeHost.hostUid,
                dataStoragePool: edgeHost.dataStoragePool,
                sourceStoragePool: edgeHost.sourceStoragePool,
                targetStoragePool: edgeHost.targetStoragePool,
                networks: (edgeHost?.networks || []).map((network) => ({
                  networkName: network,
                  networkType: edgeHost.networkType,
                })),
              })),
              rootDiskInGB: nodePool.disk,
              nonRootDisksInGB: (nodePool?.nonRootDisks || []).map(
                (diskSize) => ({
                  sizeInGB: parseInt(diskSize),
                  managed: nodePool.persistentNonRootDisks,
                })
              ),
            },
            poolConfig: {
              ...getNodeCommonPoolConfig(nodePool),
              size: nodePool.size,
              maxSize: !nodePool.isMaster ? nodePool.maxNodeSize : undefined,
              minSize: !nodePool.isMaster ? nodePool.minNodeSize : undefined,
            },
          };
        }),
      },
      tke() {
        function getNodePoolPayload(nodePool) {
          return {
            cloudConfig: {
              instanceType: nodePool.instanceType,
              azs: nodePool.azs,
              rootDeviceSize: nodePool.disk,
              subnetIds: (nodePool.azs || []).reduce((acc, az) => {
                const azSubnets = nodePool[`subnet_${az}`];
                const id = Array.isArray(azSubnets)
                  ? azSubnets.join(",")
                  : azSubnets;

                if (id) {
                  return { ...acc, [az]: id };
                }
                return acc;
              }, {}),
            },
            poolConfig: {
              ...getNodeCommonPoolConfig(nodePool),
              size: nodePool.size,
              maxSize: nodePool.maxNodeSize,
              minSize: nodePool.minNodeSize,
            },
          };
        }
        const machinePoolConfig = data.nodePools?.map(getNodePoolPayload);

        return {
          machinePoolConfig,
          cloudConfig: {
            region: data.region,
            sshKeyIDs: data.sshKeys,
            vpcID: data.vpcid,
            endpointAccess: {
              private: !!data?.endpointAccess?.includes("private"),
              public: !!data?.endpointAccess?.includes("public"),
              securityGroup: data?.publicSecurityGroup,
              subnetId: data?.privateSubnet,
            },
          },
        };
      },
      [COXEDGE_ENVIRONMENT.apiKey]() {
        function getNodePoolPayload(nodePool) {
          const inboundRules = nodePool.network?.inboundRules || [];
          const outboundRules = nodePool.network?.outboundRules || [];
          return {
            cloudConfig: {
              spec: nodePool.instanceType,
              persistentStorages: nodePool.persistentStorages,
              deployments: nodePool.deployments.map(
                ({
                  enableAutoScaling,
                  minInstancesPerPop,
                  maxInstancesPerPop,
                  instancesPerPop,
                  ...deployment
                }) => ({
                  ...deployment,
                  pops: [deployment.pops],
                  ...(enableAutoScaling
                    ? {
                        minInstancesPerPop,
                        maxInstancesPerPop,
                      }
                    : {
                        instancesPerPop,
                      }),
                })
              ),
              securityGroupRules: [...inboundRules, ...outboundRules].map(
                (rule) => ({
                  ...rule,
                })
              ),
            },
            poolConfig: {
              ...getNodeCommonPoolConfig(nodePool),
            },
          };
        }
        const machinePoolConfig = data.nodePools?.map(getNodePoolPayload);

        return {
          cloudType: COXEDGE_ENVIRONMENT.apiKey,
          machinePoolConfig,
          cloudConfig: {
            sshAuthorizedKeys: selectedSSHKeys,
            ...(data.coxEdgeLoadBalancerConfig?.pops
              ? {
                  coxEdgeLoadBalancerConfig: {
                    pops: [data.coxEdgeLoadBalancerConfig.pops],
                  },
                }
              : {}),
            ...(data.coxEdgeWorkerLoadBalancerConfig?.pops
              ? {
                  coxEdgeWorkerLoadBalancerConfig: {
                    pops: [data.coxEdgeWorkerLoadBalancerConfig.pops],
                  },
                }
              : {}),
            organizationId: data.organizationId,
            environment: data.environment,
          },
        };
      },
    };

    let cloudSpecificConfig = cloudTypeConfig[cloudType] || {};
    if (typeof cloudSpecificConfig === "function") {
      cloudSpecificConfig = cloudSpecificConfig();
    }

    if (data.clusterType === "custom") {
      cloudSpecificConfig = {
        cloudConfig: {
          values: updateCloudConfigMacros(data),
        },
        machinePoolConfig: data.nodePools.map((nodePool) => ({
          poolConfig: {
            ...getNodeCommonPoolConfig(nodePool),
          },
          cloudConfig: {
            values: updateNodePoolConfigMacros(nodePool),
          },
        })),
      };
    }

    const hostClusterConfig = getHostClusterConfig(data);
    const hasPatchAndReboot =
      !MANAGED_TO_PURE_ENVIRONMENT[cloudType] && cloudType !== "edge-native";

    return {
      metadata: {
        annotations: {
          description: data.description,
        },
        name: data.name,
        labels: formatTags(data.tags),
      },
      spec: {
        ...cloudSpecificConfig,
        cloudAccountUid: data.credential,
        edgeHostUid: data.appliance,
        profiles,
        policies,
        clusterConfig: {
          ...(data.isHostCluster ? { hostClusterConfig } : {}),
          machineManagementConfig: {
            osPatchConfig: {
              schedule: osPatchingSchedule,
              patchOnBoot: hasPatchAndReboot ? data.patchOnBoot : false,
              rebootIfRequired: hasPatchAndReboot
                ? data.rebootIfRequired
                : false,
            },
          },
          updateWorkerPoolsInParallel: [
            "edge-native",
            "edge",
            "libvirt",
          ].includes(cloudType)
            ? undefined
            : data.updateWorkerPoolsInParallel,
          resources: {
            namespaces: getClusterNamespacesPayload(data)?.namespaces,
            rbacs: roleBindings,
          },
          ...({ location } || {}),
        },
      },
    };
  }
);

export const getClusterRatePayload = createSelector(
  getPayload,
  (clusterPayload) => {
    const { cloudConfig, machinePoolConfig } = clusterPayload?.spec || {};
    return {
      cloudConfig,
      machinepoolconfig: machinePoolConfig,
    };
  }
);

export const getClusterRate = createSelector(
  (state) => state.cluster?.create?.estimatedRate,
  (data) => {
    return data?.rate || {};
  }
);

export const getProfiles = createSelector(
  () => clusterCreateProfileModule.state,
  (profilesState) => {
    return (profilesState?.profiles || []).map((profile) => ({
      guid: profile.guid,
      name: profile.metadata.name,
      layers: presentClusterProfileLayers(profile),
    }));
  }
);

export const getSelectedClusterProfileLayers = createSelector(
  getProfiles,
  (profiles) => {
    return profiles.reduce((accumulator, profile) => {
      return accumulator.concat(profile.layers);
    }, []);
  }
);

export const getSelectedLayer = createSelector(
  getSelectedClusterProfileLayers,
  (state) => state.cluster.create.selectedLayer,
  (layers, guid) => {
    return layers.find((layer) => layer.guid === guid);
  }
);

export const getParamsForLayer = createSelector(
  getSelectedLayer,
  (selectedLayer) => {
    return selectedLayer?.values;
  }
);

export const getVpcId = getEntity(
  (state) => state.cluster.create.vpcids,
  [VpcidSchema]
);

export const getVpcIdAsOptions = createSelector(getVpcId, (vpcs) => {
  return vpcs.map((vpc) => ({
    label: vpc.name || vpc.vpcId,
    value: vpc.vpcId,
  }));
});

export const getParsedNodePools = createSelector(
  (state) => state.forms.cluster.data.nodePools,
  (nodePools) =>
    nodePools.map((nodePool) => ({
      value: nodePool.poolName,
      label: nodePool.poolName,
    }))
);

export const getSubnets = createSelector(
  (state) => state.cluster.details.cloudConfigParams,
  (state) => state.location.params,
  (state) => state.forms.cluster?.data.vpcid,
  (state) => state.forms.fargateProfiles?.data.vpcid,
  (
    cloudConfigParams,
    locationParams,
    clusterSelectedVpcId,
    fargateSelctedVpcId
  ) => {
    if (!cloudConfigParams) {
      return [];
    }

    let selectedVpcId = !!locationParams.id
      ? fargateSelctedVpcId
      : clusterSelectedVpcId;

    const { vpcids } = cloudConfigParams;
    let currentAzVpc = vpcids.find((vpc) => vpc.vpcId === selectedVpcId);

    if (!currentAzVpc) {
      return;
    }

    return currentAzVpc.subnets;
  }
);

export const getSubnetsForSelectedAz = createSelector(
  getSubnets,
  getSelectedCloud,
  (azSubnets, cloudType) => {
    if (!azSubnets) {
      return;
    }

    const subnetPresenter = presentAwsSubnet({
      disableOnMissingAutoIp: cloudType === "eks",
    });

    return azSubnets.reduce((acc, value) => {
      acc[value.az] = acc[value.az] || [];
      acc[value.az].push(subnetPresenter(value));
      return acc;
    }, {});
  }
);

export const getSubnetsForNetworkAz = createSelector(
  getSubnets,
  (azSubnets) => {
    if (!azSubnets) {
      return;
    }

    const subnetPresenter = presentAwsSubnet({ disableOnMissingAutoIp: false });

    return azSubnets.reduce((acc, value) => {
      acc[value.az] = acc[value.az] || [];
      acc[value.az].push(subnetPresenter(value));
      return acc;
    }, {});
  }
);

export const getFargateSubnets = createSelector(getSubnets, (subnets) => {
  if (!subnets) {
    return [];
  }

  return subnets
    .filter((subnet) => !!subnet.isPrivate)
    .map((subnet) => ({
      label: subnet.name || subnet.subnetId,
      value: subnet.subnetId,
    }));
});

export const getCloudConfigSubnets = createSelector(
  (state) => state.forms.nodes.data,
  (formData) => {
    return formData.azs.reduce((acc, az) => {
      const subnetIsSet = formData[`subnet_${az}`];

      if (subnetIsSet) {
        acc.push({
          az,
          id: subnetIsSet,
        });
      }
      return acc;
    }, []);
  }
);

export const getVMwarePlacementOptions = createSelector(
  propertiesFetcher.selector,
  dnsMappingsFetcher.selector,
  (state) => state.forms.cluster?.data,
  getSelectedCredential,
  getRawCluster,
  (properties, dnsMapping, formData, cloudAccount, cluster) => {
    const asOption = (item) => ({
      label: item === "" ? "Default" : item,
      value: item,
    });

    // Depending on where this selector is used
    // it has to decide between the formData and cluster
    const dnsData = {
      datacenter:
        formData?.datacenter ||
        cluster?.spec?.cloudConfig?.spec?.clusterConfig?.placement?.datacenter,
      privateGatewayUid:
        cloudAccount?.metadata?.annotations?.overlordUid ||
        cluster?.spec?.cloudConfig?.spec?.cloudAccount?.metadata?.annotations
          ?.overlordUid,
    };

    function presentProperties(properties) {
      if (!properties) {
        return {};
      }

      properties.resourcePools = properties.resourcePools || [];

      if (!properties.resourcePools.includes("")) {
        properties.resourcePools.push("");
      }

      const output = ["datastores", "networks", "resourcePools"].reduce(
        (accumulator, key) => ({
          ...accumulator,
          [key]: (properties[key] || []).sort().map(asOption),
        }),
        {}
      );

      output.networks = output.networks.map((options) => ({
        ...options,
        dnsMapping: (dnsMapping.result || []).find((dnsMapping) => {
          return _.isEqual(
            _.pick(dnsMapping.spec, [
              "network",
              "privateGatewayUid",
              "datacenter",
            ]),
            {
              network: options.value,
              ...dnsData,
            }
          );
        }),
      }));

      return output;
    }

    return Object.keys(properties).reduce((accumulator, key) => {
      accumulator[key] = presentProperties(properties[key].result);
      return accumulator;
    }, {});
  }
);

export const getVMwarePlacementOptionsLoadingState = createSelector(
  propertiesFetcher.selector,
  (properties) => {
    return Object.keys(properties).reduce((accumulator, key) => {
      accumulator[key] = properties[key].isLoading;
      return accumulator;
    }, {});
  }
);

export const getDatacenters = createSelector(
  datacentersFetcher.selector,
  (datacenters) => datacenters.result
);

export const getDatacenterClustersOptions = createSelector(
  getDatacenters,
  (state) => state.forms.cluster?.data?.datacenter,
  (datacenters, selectedDatacenter) => {
    const center = datacenters?.find(
      ({ datacenter }) => datacenter === selectedDatacenter
    );
    return (center?.computeclusters || center?.computeClusters || []).map(
      (computecluster) => ({
        label: computecluster?.name || computecluster,
        value: computecluster?.name || computecluster,
      })
    );
  }
);

export const getDatacenterFolderOptions = createSelector(
  getDatacenters,
  (state) => state.forms.cluster?.data?.datacenter,
  (datacenters, selectedDatacenter) => {
    const center = datacenters?.find(
      ({ datacenter }) => datacenter === selectedDatacenter
    );

    return presentDatacenterFolders(center);
  }
);

export const getAzureSubnets = createSelector(
  virtualNetworksFetcher.selector,
  (state) => state.forms.cluster.data.vnetName,
  (fetcherState, vnet) => {
    if (!vnet) {
      return [];
    }

    if (!fetcherState.result) {
      return [];
    }

    const selectedVirtualNetwork = fetcherState.result.find(
      (network) => network.name === vnet
    );

    if (!selectedVirtualNetwork) {
      return [];
    }

    return selectedVirtualNetwork.subnets.map(({ name, ...rest }) => ({
      label: name,
      value: JSON.stringify({ name, ...rest }),
    }));
  }
);

export function getNonSupportedZones(instanceTypes, selectedInstance) {
  if (!selectedInstance) {
    return [];
  }
  const selectedOption = instanceTypes?.find(
    (type) => type.type === selectedInstance
  );
  return selectedOption?.nonSupportedZones || [];
}

export function mapAwsAzs(azs, instanceTypes, selectedInstance) {
  if (!azs) return [];

  const nonSupportedZones = getNonSupportedZones(
    instanceTypes,
    selectedInstance
  );

  return azs
    .map((az) => ({
      label: az.name,
      value: az.zoneId,
      disabled: nonSupportedZones?.some((zone) => zone === az.name),
    }))
    .sort((a, b) => (a.label > b.label ? 1 : -1));
}

export const createAWSAvailabilityZonesSelector = (nodeIndex) => {
  return createSelector(
    (state) => state.cluster.details?.cloudConfigParams?.azs,
    (state) => state.cluster.details?.cloudConfigParams?.instanceTypes,
    (state) => state.forms.cluster?.data?.nodePools[nodeIndex]?.instanceType,
    (state) => state.forms.cluster?.data?.nodePools[0]?.azs,
    (azs, instanceTypes, selectedInstance, masterPoolAzs) => {
      const awsAzs = mapAwsAzs(azs, instanceTypes, selectedInstance);
      if (nodeIndex === 0) {
        return awsAzs;
      }
      return awsAzs.map((az) => ({
        ...az,
        disabled: !masterPoolAzs.includes(az.label),
      }));
    }
  );
};

export function mapAzureAzs(azs, instanceTypes, selectedInstance) {
  if (!azs) return [];

  const nonSupportedZones = getNonSupportedZones(
    instanceTypes,
    selectedInstance
  );

  return azs.zoneList
    .map((az) => ({
      label: az.id,
      value: az.id,
      disabled: nonSupportedZones?.some((zone) => zone === az.id),
    }))
    .sort((a, b) => (a.label > b.label ? 1 : -1));
}

export const createAzureAvailabilityZonesSelector = (nodeIndex) => {
  return createSelector(
    azureAZFetcher.selector,
    azureInstanceTypesFetcher.selector,
    (state) => state.forms.cluster?.data?.nodePools[nodeIndex]?.instanceType,
    ({ result: azs }, { result: instanceTypes }, selectedInstance) => {
      return mapAzureAzs(azs, instanceTypes?.instanceTypes, selectedInstance);
    }
  );
};

export function mapGcpAzs(azs, instanceTypes, selectedInstance) {
  if (!azs) return [];

  const nonSupportedZones = getNonSupportedZones(
    instanceTypes,
    selectedInstance
  );

  return azs
    .map((az) => ({
      label: az.name,
      value: az.name,
      disabled: nonSupportedZones?.some((zone) => zone === az.name),
    }))
    .sort((a, b) => (a.label > b.label ? 1 : -1));
}

export const getMasterNodePoolAzs = createSelector(
  (state) => state.forms.cluster?.data?.nodePools,
  (nodePools) => {
    return nodePools?.find((nodePool) => nodePool.isMaster)?.azs;
  }
);

export const createGCPAvailabilityZonesSelector = (nodeIndex) => {
  return createSelector(
    getMasterNodePoolAzs,
    gcpAZFetcher.selector,
    gcpInstanceTypesFetcher.selector,
    (state) => state.forms.cluster?.data?.nodePools[nodeIndex]?.instanceType,
    (state) => state.forms.cluster?.data?.nodePools[nodeIndex]?.isMaster,
    (
      selectedMasterAzs,
      { result: allAzs },
      { result: instanceTypes },
      selectedInstance,
      isMaster
    ) => {
      const masterNamedAzs = selectedMasterAzs?.map((az) => ({ name: az }));
      const azs = isMaster ? allAzs : masterNamedAzs;
      return mapGcpAzs(azs, instanceTypes?.instanceTypes, selectedInstance);
    }
  );
};

export const getPresetsOptions = createSelector(
  getSelectedLayer,
  (selectedLayer) => {
    return groupPresetsOptions(selectedLayer?.spec?.presets);
  }
);

export const getEditorSchema = createSelector(
  getSelectedLayer,
  (selectedLayer) => {
    return generateEditorYamlSchema(selectedLayer?.spec?.schema);
  }
);

export const getDefaultPackValues = createSelector(
  getSelectedLayer,
  (selectedLayer) => {
    return selectedLayer?.spec?.values;
  }
);

export const getPackValidationErrors = createSelector(
  (state) => state.forms.cluster?.errors,
  (formErrors = []) => {
    const packValidationErrors =
      formErrors.find((error) => error.field === "layers")?.result || [];

    return packValidationErrors;
  }
);

export const getResolvedValues = createSelector(
  getSelectedClusterProfile,
  (profile) => profile?.status?.resolved || {}
);

export const getAppliancesSize = (poolIndex) =>
  createSelector(
    (state) => state.forms.cluster.data,
    (formData) => formData?.nodePools?.[poolIndex].edgeHosts.length
  );

export const getNicsByDeviceUid = createSelector(
  (state) => edgeMachinesFetcher?.selector(state)?.result || [],
  (appliances) => {
    return appliances.reduce((acc, curr) => {
      const uid = curr?.metadata?.uid;
      const nics = (curr?.spec?.device?.nics || []).filter(
        ({ nicName }) => !!nicName
      );

      acc[uid] = nics;
      return acc;
    }, {});
  }
);

export function findInstanceByType(data, type) {
  if (!data?.instanceTypes || !type) {
    return {};
  }
  return (
    (data?.instanceTypes || []).find(
      (instanceType) => instanceType.type === type
    ) || {}
  );
}

export const getAwsSelectedInstance = (index) =>
  createSelector(
    (state) => state.cluster.details.cloudConfigParams,
    (state) => state.forms.cluster?.data?.nodePools[index]?.instanceType,
    (params, type) => findInstanceByType(params, type)
  );

export const getAzureSelectedInstance = (index) =>
  createSelector(
    azureInstanceTypesFetcher.selector,
    (state) => state.forms.cluster?.data?.nodePools[index]?.instanceType,
    ({ result }, type) => findInstanceByType(result, type)
  );

export const getGcpSelectedInstance = (index) =>
  createSelector(
    gcpInstanceTypesFetcher.selector,
    (state) => state.forms.cluster?.data?.nodePools[index]?.instanceType,
    ({ result }, type) => findInstanceByType(result, type)
  );

export const getNamespaceSelectOptions = createSelector(
  (state) => state.forms.cluster?.data?.namespaces,
  (namespaces) => {
    return (namespaces || [])
      .filter((ns) => !ns.isChild)
      .map((ns) => ({
        label: ns.namespaceName,
        value: ns.namespaceName,
      }));
  }
);

export const getSelectedAppliancesGPUs = (index) =>
  createSelector(
    (state) => state.forms.cluster?.data?.nodePools[index].edgeHosts,
    appliancesKeyedFetcher.key(index.toString()).selector,
    (selectedHosts, { result } = {}) => {
      const hosts = (selectedHosts || [])
        .map(({ hostUid }) =>
          (result || []).find((appliance) => appliance.metadata.uid === hostUid)
        )
        .filter(Boolean);

      return hosts.flatMap((host) => host?.spec?.device?.gpus || []);
    }
  );

export const getSelectedAppliancesGPUsVendors = (index) =>
  createSelector(
    (state) => getSelectedAppliancesGPUs(index)(state),
    (gpus) =>
      [...new Set(gpus.map((gpu) => gpu.vendor))].map((vendor) => ({
        label: vendor,
        value: vendor,
      }))
  );

export const getSelectedGPUModels = (index) =>
  createSelector(
    (state) => getSelectedAppliancesGPUs(index)(state),
    (state) => state.forms.cluster?.data?.nodePools[index]?.gpuVendor,
    (gpus, selectedVendor) =>
      [
        ...new Set(
          gpus
            .filter((gpu) => gpu.vendor === selectedVendor)
            .map((gpu) => gpu.model)
        ),
      ].map((model) => ({
        label: model,
        value: model,
      }))
  );

export const getVirtualNetworkCidrBlocks = createSelector(
  virtualNetworksFetcher.selector,
  (state) => state.forms.cluster.data.vnetName,
  (fetcherState, vnet) => {
    if (!vnet) {
      return [];
    }

    if (!fetcherState.result) {
      return [];
    }

    const selectedVirtualNetwork = fetcherState.result.find(
      (network) => network.name === vnet
    );

    if (!selectedVirtualNetwork) {
      return [];
    }

    return selectedVirtualNetwork.addressSpaces.map((cidr) => ({
      label: cidr,
      value: cidr,
    }));
  }
);

export const COX_CALICO_INBOUND_RULES = [
  {
    protocol: "TCP",
    portRange: "22",
    securityGroupAction: "allow",
    securityGroupRuleType: "inbound",
    source: "0.0.0.0/0",
    name: "ssh",
    description: "ssh",
    type: "inbound",
  },
  {
    protocol: "TCP",
    portRange: "6443",
    securityGroupAction: "allow",
    securityGroupRuleType: "inbound",
    source: "0.0.0.0/0",
    name: "apiserver",
    description: "apiserver",
    type: "inbound",
  },
  {
    protocol: "TCP",
    portRange: "179",
    securityGroupAction: "allow",
    securityGroupRuleType: "inbound",
    source: "0.0.0.0/0",
    name: "calico-bgp",
    description: "calico-bgp",
    type: "inbound",
  },
  {
    protocol: "UDP",
    portRange: "4789",
    securityGroupAction: "allow",
    securityGroupRuleType: "inbound",
    source: "0.0.0.0/0",
    name: "calico-vxlan",
    description: "calico-vxlan",
    type: "inbound",
  },
];

export const getCoxEdgeNetworkSettings = createSelector(
  getSelectedCloud,
  getSelectedClusterProfile,
  (cloudType, profile) => {
    let networkSettings = {};
    const hasCalicoCNI = profile.spec.published.packs.some((pack) => {
      return pack.name === "cni-calico" && pack.layer === "cni";
    });
    if (hasCalicoCNI && cloudType === "coxedge") {
      networkSettings = {
        network: {
          inboundRules: COX_CALICO_INBOUND_RULES,
        },
      };
    }
    return networkSettings;
  }
);

export const hasMicroK8sPack = createSelector(
  () => clusterCreateProfileModule.state,
  (profileState) => {
    const profiles = profileState?.profiles || [];
    const profilesLayers = profiles?.map((profile) =>
      presentClusterProfileLayers(profile)
    );
    return profilesLayers.some((layers) => {
      return layers.find((layer) => layer?.name.includes("microk8s"));
    });
  }
);

export const getAllEdgeHosts = createSelector(
  (state) => state.forms.cluster?.data?.nodePools,
  (nodePools) => (nodePools || []).flatMap(({ edgeHosts }) => edgeHosts)
);
