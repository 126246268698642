import React, { ComponentProps } from "react";
import styled from "styled-components";
import Icon from "components/ui/Icon";
import { primaryBlue } from "utils/constants/colors";
import { DOC_BASE_URL, DOC_LINK_MAPPING } from "utils/constants/docLinks";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

type WrapperProps = {
  color?: string;
  fontSize?: number;
};
const Wrapper = styled.span<WrapperProps>`
  display: inline-flex;
  align-items: center;

  a {
    font-size: ${(props) => `props.fontSize ? ${props.fontSize}px : "inherit"`};

    .anticon {
      margin-left: 8px;

      svg {
        path {
          fill: ${(props) => props.color || primaryBlue};
        }
      }
    }
  }
`;

type DocLinkProps = ComponentProps<typeof Wrapper> & {
  location: keyof typeof DOC_LINK_MAPPING;
  text: React.ReactNode;
};

const DocLink = ({ location, text, ...rest }: DocLinkProps) => {
  const { t } = useTranslation();
  const docLocation = DOC_LINK_MAPPING[location] || "#";
  const href =
    (docLocation.startsWith("http")
      ? docLocation
      : `${DOC_BASE_URL}${docLocation}?utm_source=palette&utm_medium=product2docs`) ||
    "#";
  return (
    <Wrapper {...rest}>
      <a target="_blank" rel="noopener noreferrer" href={href}>
        {text || t("Learn More")}
        <Icon awesome={faArrowUpRightFromSquare} />
      </a>
    </Wrapper>
  );
};

export default DocLink;
