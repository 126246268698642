import store from "services/store";

import Validator from "services/validator";
import { Missing, isKubernetesName, MaxLength } from "services/validator/rules";
import createActions from "modules/form/actions";

import {
  cloudAccountFetcher,
  cloudAccountFormModal,
  CLOUD_ACCOUNTS_MODULE,
} from "state/cloudaccounts/services";
import { createAccount } from "state/cloudaccounts/actions/create";
import dataFetcher from "modules/dataFetcher";
import api from "services/api";
import notifications from "services/notifications";
import i18next from "i18next";

const validator = new Validator();
validator.addRule(
  ["name", "apiKey", "apiEndpoint", "overlordUid", "maasServer"],
  Missing()
);
validator.addRule(["name"], isKubernetesName());
validator.addRule(["name"], MaxLength(32));

async function submit(data) {
  const payload = {
    metadata: {
      name: data.name,
      annotations: {
        overlordUid: data.overlordUid,
        overlordType: data.useSystemGateway ? "system" : undefined,
      },
    },
    spec: {
      apiEndpoint: data.apiEndpoint.trim(),
      apiKey: data.apiKey.trim(),
      insecure: data?.insecure,
    },
  };

  return store.dispatch(createAccount("maas", payload));
}

async function init() {
  let data;
  if (cloudAccountFormModal.data?.uid) {
    data = await store.dispatch(cloudAccountFetcher.fetch());
    store.dispatch({
      type: "SET_ACCOUNT_AS_VALID",
    });
  }

  return Promise.resolve({
    type: "maas",
    useSystemGateway:
      data?.metadata?.annotations?.overlordType === "system" || false,
    name: data?.metadata.name || "",
    apiKey: data?.spec.apiKey || "",
    apiEndpoint: data?.spec.apiEndpoint || "",
    overlordUid: data?.metadata?.annotations?.overlordUid || "",
    description: data?.metadata?.annotations?.description || "",
  });
}

export const maasAccountFormActions = createActions({
  submit,
  validator,
  init,
});

export const selectedOverlordFetcher = dataFetcher({
  selectors: [
    "overlords",
    (state) => state.forms[CLOUD_ACCOUNTS_MODULE]?.data?.overlordUid,
  ],
  async fetchData([_, id]) {
    try {
      const overlord = await api.get(`v1/overlords/${id}`);
      const cloudAccount = api.get(
        `v1/cloudaccounts/maas/${overlord.spec.cloudAccountUid}`
      );

      return cloudAccount;
    } catch (e) {
      notifications.error({
        message: i18next.t(
          "Something went wrong while retrieving the overlord"
        ),
        description: e.message,
      });
    }
  },
});

export function onOverlordSelect(value) {
  return async function thunk(dispatch) {
    dispatch(
      maasAccountFormActions.onChange({
        name: "overlordUid",
        value,
        module: CLOUD_ACCOUNTS_MODULE,
      })
    );

    dispatch({ type: "SET_ACCOUNT_AS_INVALID" });

    const cloudAccount = await dispatch(selectedOverlordFetcher.fetch());

    dispatch(
      maasAccountFormActions.batchChange({
        module: CLOUD_ACCOUNTS_MODULE,
        updates: {
          insecure: cloudAccount?.spec?.insecure,
          apiEndpoint: cloudAccount?.spec?.apiEndpoint,
        },
      })
    );
  };
}
