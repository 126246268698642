import { createSelector } from "reselect";
import _ from "lodash";
import { dnsMappingsFetcher } from "state/dns/services";
import {
  datacentersFetcher,
  propertiesFetcher,
  OVERLORD_CLOUD_MODULE,
  OVERLORD_ACCOUNT_MODULE,
} from "state/overlord/services/list";
import { presentDatacenterFolders } from "utils/presenters";
import { getCronScheduleValue } from "utils/parsers";
import { IPAM_MODULE } from "../services/ipam";

export const getDatacenters = createSelector(
  datacentersFetcher.selector,
  (datacenters) => datacenters.result
);

export const getDatacenterFolderOptions = createSelector(
  getDatacenters,
  (state) => state.forms[OVERLORD_CLOUD_MODULE].data?.datacenter,
  (datacenters, selectedDatacenter) => {
    const center = datacenters?.find(
      ({ datacenter }) => datacenter === selectedDatacenter
    );

    return presentDatacenterFolders(center);
  }
);

export const getOverlordData = (state) => state.overlord.configure.overlordData;

export const getVMwarePlacementOptions = createSelector(
  propertiesFetcher.selector,
  dnsMappingsFetcher.selector,
  (state) => state.forms[OVERLORD_CLOUD_MODULE].data,
  getOverlordData,
  (properties, dnsMapping, formData, overlordData) => {
    const asOption = (item) => ({
      label: item === "" ? "Default" : item,
      value: item,
    });

    function presentProperties(properties) {
      if (!properties) {
        return {};
      }
      properties.resourcePools = properties.resourcePools || [];

      if (!properties.resourcePools.includes("")) {
        properties.resourcePools.push("");
      }

      const output = ["datastores", "networks", "resourcePools"].reduce(
        (accumulator, key) => ({
          ...accumulator,
          [key]: (properties[key] || []).sort().map(asOption),
        }),
        {}
      );

      output.networks = output.networks.map((options) => ({
        ...options,
        dnsMapping: (dnsMapping.result || []).find((dnsMapping) => {
          return _.isEqual(
            _.pick(dnsMapping.spec, [
              "network",
              "privateGatewayUid",
              "datacenter",
            ]),
            {
              network: options.value,
              datacenter: formData.datacenter,
              privateGatewayUid: overlordData.metadata.uid,
            }
          );
        }),
      }));

      return output;
    }

    return Object.keys(properties).reduce((accumulator, key) => {
      accumulator[key] = presentProperties(properties[key].result);
      return accumulator;
    }, {});
  }
);

export const getVMwarePlacementOptionsLoadingState = createSelector(
  propertiesFetcher.selector,
  (properties) => {
    return Object.keys(properties).reduce((accumulator, key) => {
      accumulator[key] = properties[key].isLoading;
      return accumulator;
    }, {});
  }
);

export const getDatacenterClustersOptions = createSelector(
  getDatacenters,
  (state) => state.forms[OVERLORD_CLOUD_MODULE].data?.datacenter,
  (datacenters, selectedDatacenter) => {
    const center = datacenters?.find(
      ({ datacenter }) => datacenter === selectedDatacenter
    );

    return (center?.computeclusters || []).map((computecluster) => ({
      label: computecluster,
      value: computecluster,
    }));
  }
);

export const getOverlordOsPatchingSchedule = createSelector(
  (state) => state.forms?.overlordCloud?.data,
  (data) => {
    return getCronScheduleValue(
      data?.osPatchingScheduleOption,
      data?.osPatchingSchedule
    );
  }
);

const CONFIG_MODULES_MAPPING = {
  account: OVERLORD_ACCOUNT_MODULE,
  config: OVERLORD_CLOUD_MODULE,
  ipam: IPAM_MODULE,
};

export const getNumberOfFieldsWithErrors = createSelector(
  (state) => state.overlord.configure.step,
  (state) => state.forms,
  (step, forms) => {
    const formModule = CONFIG_MODULES_MAPPING?.[step];
    const errors = forms[formModule]?.errors || [];

    const fieldsWithErrors = (errors || []).map((error) => error?.field);
    return new Set(fieldsWithErrors).size;
  }
);
