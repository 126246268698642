import { groupBy } from "lodash";
import i18n from "i18next";

export const CATEGORIES = {
    INFRASTRUCTURE: 'infrastructure',
    SECURITY: 'security',
    PLATFORM: 'platform',
    SUBSCRIPTION: 'subscription'
};

export const groupRoutes = (routes, categories) => {
    const groupedRoutes = groupBy(routes, 'category');

    return categories.map(category => ({
        ...category,
        routes: groupedRoutes[category.categoryKey] || []
    }));
};

export const tenantSettingsCategories = [
    {
        categoryKey: CATEGORIES.INFRASTRUCTURE,
        categoryName: i18n.t("Infrastructure")
    },
    {
        categoryKey: CATEGORIES.SECURITY,
        categoryName: i18n.t("Security"),
    },
    {
        categoryKey: CATEGORIES.PLATFORM,
        categoryName: i18n.t("Platform"),
    }, {
        categoryKey: CATEGORIES.SUBSCRIPTION,
        categoryName: i18n.t("Subscription"),
    }];

export const projectSettingcategories = [
    {
        categoryKey: CATEGORIES.INFRASTRUCTURE,
        categoryName: i18n.t("Infrastructure")
    },
    {
        categoryKey: CATEGORIES.SECURITY,
        categoryName: i18n.t("Security"),
    },
    {
        categoryKey: CATEGORIES.PLATFORM,
        categoryName: i18n.t("Platform"),
    }];
