import LocalStorage from "services/localstorage";

export const contextPreferenceCache = new LocalStorage("preferedContext");
export const menu = new LocalStorage("menu");
export const backToProjectCache = new LocalStorage("backToProject");
export const includeMastersCache = new LocalStorage("includeMasters");
export const coresChartTimePeriodCache = new LocalStorage(
  "coresChartTimePeriod"
);
export const clustersFiltersCache = new LocalStorage("clustersFilters");
export const nestedClustersFiltersCache = new LocalStorage(
  "nestedClustersFilters"
);
export const mapFiltersCache = new LocalStorage("mapFilters");
export const devModeProductTour = new LocalStorage("devModeProductTour");
export const overlordsFiltersCache = new LocalStorage("clustersFilters");
export const userSettingsCache = new LocalStorage("userSettings");
export const projectSettingsCache = new LocalStorage("projectSettingsActivePanels");
export const tenantSettingsCache = new LocalStorage("tenantSettingsActivePanels");
export const profileEditorLayersTooltipCache = new LocalStorage(
  "profileEditorLayersTooltip"
);
export const listingTablePreferences = new LocalStorage(
  "listingTablePreferences"
);
