import createFormActions from "modules/form/actions";
import ModalService from "services/modal";
import store from "services/store";
import { clusterProfiletTypeCounter } from "state/clusterprofile/services";

export const selectCloudModal = new ModalService("selectCloud");
export const CLOUD_SELECTOR_MODULE = "cluster";

export const cloudSelectFormActions = createFormActions({
  init: async () => {
    store.dispatch(clusterProfiletTypeCounter.key("aws").fetch());
    return Promise.resolve({ clusterType: "cloud" });
  },
});
