import { createSelector } from "reselect";
import { formatTags } from "utils/presenters";

import {
  FORM_MODULE,
  profileModule,
  projectHostClustersFetcher,
} from "../services/create";
import { ClusterSchema } from "utils/schemas";
import { getEntity } from "utils/entities";
import { getQuotaValue } from "utils/presenters";

export const getSelectedClustersEntities = getEntity(
  (state) => state.forms[FORM_MODULE]?.data?.selectedClustersGuids,
  [ClusterSchema]
);

export const getSelectedClustersList = createSelector(
  (state) => state.forms[FORM_MODULE]?.data?.clusters,
  projectHostClustersFetcher.selector,
  (clusterUids, { result }) => {
    const clusters = result?.filter((cluster) =>
      clusterUids?.includes(cluster?.metadata?.uid)
    );
    return (clusters || []).map((item) => ({
      ...item,
      key: item.guid,
      health: item.status.health?.state,
      metrics: item.status.metrics,
      isBrownfield:
        item?.status?.clusterImport?.isBrownfield ||
        !!item?.status?.clusterImport?.state,
    }));
  }
);

export const getSelectedClustersOptions = createSelector(
  getSelectedClustersList,
  (selectedClusters) => {
    return (selectedClusters || []).map((cluster) => ({
      value: cluster.metadata.uid,
      label: cluster.metadata.name,
    }));
  }
);

export function getHostClustersConfig({ data, selectedClusters }) {
  const { clusterEndpointType } = data || {};

  const getIngressConfig = (clusterUid) => {
    if (clusterEndpointType !== "Ingress") {
      return null;
    }
    const ingressHost = (
      data.hostClusters?.[clusterUid]?.ingressHost || ""
    ).trim();
    return {
      ingressConfig: {
        host: `*.${ingressHost}`,
      },
    };
  };

  return (selectedClusters || []).map((cluster) => {
    const uid = cluster?.metadata?.uid || cluster;
    return {
      clusterUid: uid,
      endpointConfig:
        clusterEndpointType === "Ingress" ? getIngressConfig(uid) : {},
    };
  });
}

export const getClusterGroupPayload = createSelector(
  (state) => state.forms[FORM_MODULE]?.data,
  getSelectedClustersList,
  (data = {}, selectedClusters) => {
    return {
      metadata: {
        name: data.name,
        labels: formatTags(data.tags),
        annotations: { description: data.description },
      },
      spec: {
        type: "hostCluster",
        clusterRefs: (selectedClusters || []).map((cluster) => ({
          clusterUid: cluster.metadata.uid,
        })),
        profiles: profileModule.payload,
        clustersConfig: {
          kubernetesDistroType: data.kubernetesDistroType,
          endpointType: data.clusterEndpointType,
          hostClustersConfig: getHostClustersConfig({ data, selectedClusters }),
          limitConfig: {
            cpuMilliCore: getQuotaValue(data.cpuLimits, 6) * 1000,
            memoryMiB: getQuotaValue(data.memoryLimits, 8) * 1024,
            storageGiB: getQuotaValue(data.storageLimits, 10),
            overSubscription: getQuotaValue(data.oversubscription, 0),
          },
          values: data.yamlConfigValues || "",
        },
      },
    };
  }
);
