import i18next from "i18next";

import api from "services/api";
import ModalService from "services/modal";
import historyService from "services/history";
import store, { getStoreEntity } from "services/store";
import notifications from "services/notifications";
import Validator from "services/validator";
import { Missing } from "services/validator/rules";

import { CertificateAuthoritySchema } from "utils/schemas";

import dataFetcher from "modules/dataFetcher";
import ListActions from "modules/list/actions";
import createFormActions from "modules/form/actions";
import AsyncAction from "modules/asyncAction";

import { getCurrentUser } from "state/auth/selectors";

export const CERTS_LIST_MODULE = "certificates";
export const CERT_FORM_MODULE = "certificate";

export const deleteConfirmService = new ModalService();
export const certificateModal = new ModalService();

const certificateValidator = new Validator();
certificateValidator.addRule(["name", "certificate"], Missing());

export const certificatesFetcher = dataFetcher({
  selectors: [
    "certificates",
    (store) => getCurrentUser(store)?.metadata?.annotations?.tenantUid,
  ],
  fetchData([_, tenantUid]) {
    return api.get(`v1/tenants/${tenantUid}/assets/certs`);
  },
});

export const certificatesListActions = new ListActions({
  dataFetcher: certificatesFetcher,
  schema: [CertificateAuthoritySchema],
});

export const certificateAsyncAction = new AsyncAction({
  promise: () => {
    return store.dispatch(
      certificatesFormActions.submit({ module: CERT_FORM_MODULE })
    );
  },
});

export const certificatesFormActions = createFormActions({
  init: async () => {
    const uid = certificateModal?.data?.uid;
    const tenantUid = getCurrentUser(store.getState())?.metadata?.annotations
      ?.tenantUid;
    let data;

    if (uid) {
      data = await api.get(`v1/tenants/${tenantUid}/assets/certs/${uid}`);
    }

    return Promise.resolve({
      name: data?.metadata?.name || "",
      certificate: data?.spec?.certificate || "",
    });
  },
  submit: async (data) => {
    const certUid = certificateModal?.data?.uid;
    const { certificate, name } = data || {};
    const payload = {
      metadata: {
        uid: certUid,
        name,
      },
      spec: {
        certificate,
        isCA: false,
        key: "",
      },
    };
    const tenantUid = getCurrentUser(store.getState())?.metadata?.annotations
      ?.tenantUid;
    let response;

    const promise = certUid
      ? api.put(`v1/tenants/${tenantUid}/assets/certs/${certUid}`, payload)
      : api.post(`v1/tenants/${tenantUid}/assets/certs`, payload);

    try {
      response = await promise;
    } catch (error) {
      notifications.error({
        message: certUid
          ? i18next.t("Something went wrong while updating the certificate")
          : i18next.t("Something went wrong while adding the certificate"),
        description: error.message,
      });
      return Promise.reject(error);
    }

    notifications.success({
      message: `Certificate "${name}" has been ${
        certUid ? "updated" : "added"
      } successfully`,
    });
    return response;
  },
  validator: certificateValidator,
});

export const deleteAsyncAction = new AsyncAction({
  promise: async (guid) => {
    const state = store.getState();
    const certificateEntity = getStoreEntity(guid, CertificateAuthoritySchema);
    const tenantUid = getCurrentUser(state)?.metadata?.annotations?.tenantUid;
    const promise = api.delete(
      `v1/tenants/${tenantUid}/assets/certs/${certificateEntity?.metadata?.uid}`
    );

    try {
      await promise;
      store.dispatch(certificatesListActions.initialize(CERTS_LIST_MODULE));
      notifications.success({
        message: i18next.t(
          'Certificate "{{name}}" has been deleted successfully',
          { name: certificateEntity.metadata.name }
        ),
      });
    } catch (error) {
      notifications.error({
        message: i18next.t(
          "Something went wrong while deleting the certificate"
        ),
        description: error.message,
      });
    }
  },
});

export function onCertDelete(guid) {
  return function thunk() {
    deleteConfirmService.open({ guid }).then(() => {
      deleteAsyncAction.key(guid).trigger();
    });
  };
}

export function openCertificateModal(uid) {
  return (dispatch) => {
    function fallback() {
      historyService.push("/settings/certificates");
    }
    certificateModal.open({ uid }).then(async () => {
      await certificateAsyncAction.trigger();
      fallback();
    }, fallback);
    dispatch(certificatesFormActions.init({ module: CERT_FORM_MODULE }));
  };
}
