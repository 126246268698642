import React from "react";
import styled from "styled-components";
import { Trans, useTranslation } from "react-i18next";
import { Tooltip } from "antd";

import { white, darkBlue, nearBlack, midGray } from "utils/constants/colors";
import Warning from "assets/icons/warning.svg?react";
import { faUserAstronaut } from "@fortawesome/pro-solid-svg-icons";
import {
  faBook,
  faComments,
  faCompass,
} from "@fortawesome/pro-light-svg-icons";
import {
  faPowerOff,
  faChevronDown,
  faArrowsRetweet,
  faBuilding,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FLAGS } from "utils/constants/flags";

import { TextButton } from "components/ui/Button";
import Link from "components/common/History/Link";
import ContextDropdown from "./ContextDropdown";
import { BreadcrumbSlot } from ".";
import ActionMenu from "components/common/ActionMenu";
import Icon from "components/ui/Icon";
import { faKey, faBug } from "@fortawesome/free-solid-svg-icons";
import { downloadBugReport } from "services/store/bugReporter";
import FreemiumAndCloudCredit from "./FreemiumAndCloudCredit";
import permissions from "services/permissions";
import Flags from "components/ui/Flags";
import { useDataFetcher } from "modules/dataFetcher";
import { orgsFetcher } from "state/auth/actions";

const HeaderContent = styled.div`
  height: 48px;
  min-height: 48px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  background-color: ${white};
  color: rgba(13, 20, 64, 0.7);
`;

const IconWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  font-size: 18px;
  margin-right: 12px;

  svg path {
    fill: rgba(13, 20, 64, 0.7);
  }
`;

const WrapItems = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`;

const StyledIcon = styled(FontAwesomeIcon)`
  color: rgba(13, 20, 64, 0.7);
  font-size: 18px;
`;

const MenuIcon = styled(StyledIcon)`
  font-size: 14px;
  margin-right: 10px;
`;

const StyledArrowIcon = styled(FontAwesomeIcon)`
  color: ${midGray};
  opacity: 0.5;
  font-size: 10px;
  margin-left: 12px;
`;

const DocsLink = styled.a`
  padding: 5px 16px 5px 8px;
  border-radius: 3px;
  background: rgba(53, 117, 207, 0.1);
  display: flex;
  align-items: center;

  .anticon {
    margin-right: 8px;
  }
`;

const MenuLink = styled(Link)`
  margin-left: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
`;

const DropdownContent = styled.div`
  color: ${nearBlack};
  height: 30px;
  padding: 0 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.02em;

  .anticon {
    font-size: 14px;
    color: ${midGray};
  }
`;

const ProductTourButton = styled(TextButton)`
  padding: 0;
  margin-left: 10px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(13, 20, 64, 0.7);

  > ${IconWrap} {
    margin-right: 0;
  }
`;

const BugReportButton = styled(TextButton)`
  padding: 0;
  margin-left: 10px;
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(13, 20, 64, 0.7);

  > ${IconWrap} {
    margin-right: 0;
  }
`;

const StyledMenuItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledWarning = styled(Warning)`
  margin-left: 12px;
`;

export default function AppHeader(props) {
  const { t } = useTranslation();
  const { result: organizations } = useDataFetcher(orgsFetcher);

  function renderHelpLink() {
    if (!import.meta.env.SPECTRO_SUPPORT_LINK) {
      return null;
    }
    return (
      <Tooltip title={t("Help")} placement="bottom" color={darkBlue}>
        <MenuLink
          as="a"
          href={import.meta.env.SPECTRO_SUPPORT_LINK}
          target="_blank"
          rel="noopener noreferrer"
        >
          <StyledIcon icon={faComments} />
        </MenuLink>
      </Tooltip>
    );
  }

  function renderBugReport() {
    return (
      <Flags has={FLAGS.BUG_REPORTER}>
        <Tooltip title={t("Bug Report")} placement="bottom" color={darkBlue}>
          <BugReportButton onClick={() => downloadBugReport()}>
            <IconWrap>
              <Icon awesome={faBug} />
            </IconWrap>
          </BugReportButton>
        </Tooltip>
      </Flags>
    );
  }

  const renderModeLabel = () => {
    if (props.isDevMode) {
      return t("Switch to Cluster Mode");
    }

    if (props.isSecurityModeFips) {
      return (
        <StyledMenuItem>
          <span>{t("Switch to App Mode")}</span>
          <Tooltip
            title={t("App Mode is a non-FIPS compliant feature")}
            placement="left"
            color={darkBlue}
          >
            <StyledWarning />
          </Tooltip>
        </StyledMenuItem>
      );
    }

    return t("Switch to App Mode");
  };

  const userOptions = [
    {
      key: "my-profile",
      label: <Link to="/my-profile">{t("My Profile")}</Link>,
      icon: <MenuIcon icon={faUserAstronaut} />,
    },
    {
      key: "my-api-keys",
      label: <Link to="/my-api-keys">{t("My API Keys")}</Link>,
      icon: <MenuIcon icon={faKey} />,
    },
    permissions.has([
      "appDeployment.list",
      "virtualCluster.list",
      "virtualCloudconfig.list",
      "appProfile.list",
    ]) && {
      key: "dev-mode",
      label: renderModeLabel(),
      icon: <MenuIcon icon={faArrowsRetweet} />,
      onClick: () => props.toggleDevMode({ shouldResetToDefault: true }),
    },
    organizations?.length > 1 && {
      key: "switch-org",
      icon: <MenuIcon icon={faBuilding} />,
      label: <Link to="/auth/orgs">{t("Switch Organization")}</Link>,
    },
    {
      key: "logout",
      icon: <MenuIcon icon={faPowerOff} />,
      label: (
        <Trans>
          Logout from <strong>{props.user?.status?.tenant?.orgName}</strong>
        </Trans>
      ),
      onClick: props.onLogout,
    },
  ].filter(Boolean);
  const hasFirstAndLastName = props.username.trim().length > 0;

  function renderUserDropdown() {
    return (
      <ActionMenu trigger="click" data-qa="user-settings" options={userOptions}>
        <DropdownContent data-qa="user-menu">
          <span>
            {!hasFirstAndLastName ? (
              <Icon awesome={faUserAstronaut} />
            ) : (
              props.username
            )}
          </span>
          <StyledArrowIcon icon={faChevronDown} placement="bottomRight" arrow />
        </DropdownContent>
      </ActionMenu>
    );
  }

  return (
    <HeaderContent>
      <WrapItems>
        <ContextDropdown {...props} />
        <BreadcrumbSlot.Target />
      </WrapItems>
      <WrapItems>
        <FreemiumAndCloudCredit />
        {renderHelpLink()}
        {renderBugReport()}
        <Tooltip title={t("Product Tour")} placement="bottom" color={darkBlue}>
          <ProductTourButton onClick={props.openProductTourModal}>
            <IconWrap>
              <Icon awesome={faCompass} />
            </IconWrap>
          </ProductTourButton>
        </Tooltip>
        <Tooltip title={t("Documentation")} placement="bottom" color={darkBlue}>
          <DocsLink
            href="https://docs.spectrocloud.com"
            target="_blank"
            rel="noreferrer"
          >
            <Icon awesome={faBook} />
            Docs
          </DocsLink>
        </Tooltip>
        {renderUserDropdown()}
      </WrapItems>
    </HeaderContent>
  );
}
