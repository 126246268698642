import React from "react";
import styled, { css } from "styled-components";

import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faCircle } from "@fortawesome/pro-light-svg-icons";

import { Tooltip } from "antd";
import { ProfileCardTags } from "components/common/ClusterProfileCard";
import Accordion from "components/ui/Accordion";
import ClusterProfileVersionMenu from "components/common/ClusterProfileVersionMenu";
import BackedUpImage from "components/common/BackedUpImage";
import Ellipsis from "components/common/Ellipsis";
import Icon from "components/ui/Icon";
import ManifestIcon from "assets/icons/manifest.svg?react";

import { getPackPrecedence } from "utils/presenters";
import { nearWhite, white } from "utils/constants/colors";
import { LAYER_TYPES, PACK_DIFF_DETAILS_MAPPING } from "utils/constants";

const ProfileListWrap = styled.div`
  overflow-y: hidden;
  width: 100%;
  height: 100%;
  display: flex;
`;

const ProfileTitleWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const PackDetailsWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const PackWrap = styled.div`
  position: relative;
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 8px;

  background-color: ${white};
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08);
  border-bottom: 1px solid #ddd;
  cursor: pointer;

  ${(props) =>
    props.isSelected &&
    css`
      background: #fafafa;
      box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08), inset 2px 0px 0px #0072c6;
    `}

  ${(props) =>
    !props.withSummary &&
    css`
      &:hover {
        background: #fafafa;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08), inset 2px 0px 0px #0072c6;
      }
    `}
`;

const LeftSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 90%;
`;

const ReviewStatusWrap = styled.div`
  display: flex;
  align-items: center;
`;

const ProfilePackList = styled.div``;

const PackDetails = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-left: 4px;
`;

const LogoWrap = styled.div`
  width: 30px;
  margin-right: 8px;

  > img {
    max-width: 100%;
  }
`;

const AttachedManifestContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${white};
  padding: 4px 8px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08);
  margin-left: 16px;
  cursor: pointer;
  border-bottom: 1px solid ${nearWhite};
  position: relative;

  svg {
    margin-right: 8px;
  }

  ${(props) =>
    props.selectedManifest &&
    css`
      background: linear-gradient(
          0deg,
          rgba(32, 108, 209, 0.07),
          rgba(32, 108, 209, 0.07)
        ),
        #ffffff;
      box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08), inset 2px 0px 0px #206cd1;
    `}
`;

const AttachedManifestDetails = styled.div`
  display: flex;
  align-items: center;
`;

const DiffType = styled.div`
  position: absolute;
  right: ${(props) => (props.isManifestDiff ? "-36px" : "-28px")};

  ${(props) =>
    props.color &&
    css`
      svg {
        color: ${(props) => props.color};
      }
    `}
`;

const DiffWrap = styled.div`
  width: 40px;
`;

function PackDiffType({ diffType = "", isManifestDiff = false, changes = [] }) {
  let type = null;

  if (diffType.includes("added")) {
    type = "added";
  } else if (
    changes.includes("values") ||
    (isManifestDiff && diffType.includes("updated"))
  ) {
    type = "values";
  } else if (changes.includes("children") && changes.length === 1) {
    type = "updated";
  } else if (changes.includes("version")) {
    type = "version";
  } else if (diffType.includes("removed")) {
    type = "removed";
  }

  if (!type) {
    return null;
  }

  return (
    <DiffType
      isManifestDiff={isManifestDiff}
      color={PACK_DIFF_DETAILS_MAPPING[type]?.color}
    >
      <Tooltip
        placement="right"
        title={PACK_DIFF_DETAILS_MAPPING[type]?.label()}
      >
        <Icon awesome={PACK_DIFF_DETAILS_MAPPING[type]?.icon} />
      </Tooltip>
    </DiffType>
  );
}

function ProfilesList({
  profilesDiffs = [],
  selectedLayer = "",
  expanded = [],
  reviewedLayers = [],
  onSelectDiffViewerLayer,
  toggleExpandedProfiles,
  withSummary = false,
}) {
  function renderReviewStatus(isReviewed) {
    if (withSummary) {
      return null;
    }

    let icon = faCircle;
    if (isReviewed) {
      icon = faCheck;
    }

    return (
      <ReviewStatusWrap>
        <Icon awesome={icon} />
      </ReviewStatusWrap>
    );
  }
  const renderPackDiff = (
    { type: diffType, target, current, manifests, changes },
    profileGuid,
    profileUid
  ) => {
    const pack = target?.pack || current?.pack;
    const packType = pack?.type || pack?.spec?.type;
    const packDetails = `${
      pack?.pack?.spec?.displayName ||
      pack?.pack?.spec?.name ||
      pack?.name ||
      ""
    } ${pack?.spec?.version || pack?.version}`;
    const isLayerReviewed = reviewedLayers.includes(pack.guid);

    function renderLayerIcon() {
      if (packType === "manifest") {
        return <ManifestIcon />;
      }

      const layerType =
        pack?.spec?.addonSubType || pack?.spec?.addonType || pack?.spec?.layer;

      let packLogo = pack?.spec?.logoUrl || pack?.pack?.spec?.logoUrl;
      if (pack?.spec?.type === "oci") {
        packLogo = `/v1/packs/${pack?.metadata?.uid || pack?.packUid}/logo`;
      }

      const layerIconSrc = LAYER_TYPES.find(
        ({ type }) => type === layerType
      )?.icon;

      return <BackedUpImage src={packLogo} fallbackSrc={layerIconSrc} />;
    }

    function onLayerClick(layerGuid) {
      if (withSummary) {
        return;
      }
      onSelectDiffViewerLayer(layerGuid, profileGuid);
    }

    function renderManifests() {
      return (manifests || []).map(({ current, target, type }) => {
        const manifest = target?.manifest || current?.manifest;
        const isManifestSelected = selectedLayer === manifest?.guid;
        const isReviewed = reviewedLayers.includes(manifest?.guid);

        return (
          <AttachedManifestContainer
            key={manifest.name}
            selectedManifest={isManifestSelected}
            onClick={() => onLayerClick(manifest?.guid)}
          >
            <AttachedManifestDetails>
              <ManifestIcon />
              {manifest.name}
            </AttachedManifestDetails>
            {renderReviewStatus(isReviewed)}
            {withSummary && (
              <PackDiffType isManifestDiff={true} diffType={type} />
            )}
          </AttachedManifestContainer>
        );
      });
    }

    return (
      <React.Fragment key={pack?.guid}>
        <PackWrap
          isSelected={selectedLayer === pack?.guid}
          onClick={() => onLayerClick(pack?.guid)}
          withSummary={withSummary}
        >
          <LeftSection>
            <Tooltip placement="right" title={packDetails}>
              <PackDetails>
                <LogoWrap>{renderLayerIcon()}</LogoWrap>
                <Ellipsis>{packDetails}</Ellipsis>
              </PackDetails>
            </Tooltip>
          </LeftSection>
          {renderReviewStatus(isLayerReviewed)}
          {withSummary && (
            <PackDiffType diffType={diffType} changes={changes} />
          )}
        </PackWrap>
        {renderManifests()}
      </React.Fragment>
    );
  };

  const renderProfileDiff = ({ profile, packs: packsDiffs }) => {
    const profileName = profile?.metadata?.name;
    let { version } = profile?.spec || profile?.specSummary || {};
    const scope = profile?.metadata?.annotations?.scope;
    if (profile?.profile?.spec?.version) {
      version = profile?.profile?.spec?.version;
    }

    const orderedPacksDiffs = [...packsDiffs].sort(
      (packDiffA, packDiffB) =>
        getPackPrecedence(packDiffA?.target?.pack || packDiffA?.current?.pack) -
        getPackPrecedence(packDiffB?.target?.pack || packDiffB?.current?.pack)
    );

    return (
      <Accordion.Panel
        key={profile?.guid}
        header={
          <ProfileTitleWrap>
            <span title={profileName}>{profileName}</span>
            <PackDetailsWrap>
              <ProfileCardTags scope={scope} compressed />
              <ClusterProfileVersionMenu
                minWidth="100px"
                height="24px"
                disabled
                selectedVersion={version}
                versions={[version]}
              />
            </PackDetailsWrap>
          </ProfileTitleWrap>
        }
      >
        <ProfilePackList>
          {orderedPacksDiffs.map((packDiff) =>
            renderPackDiff(packDiff, profile?.guid, profile?.metadata?.uid)
          )}
        </ProfilePackList>
      </Accordion.Panel>
    );
  };

  return (
    <ProfileListWrap>
      <Accordion
        variant="white"
        activeKey={expanded}
        onChange={toggleExpandedProfiles}
      >
        {profilesDiffs.map((profileDiffs) => renderProfileDiff(profileDiffs))}
      </Accordion>
      {withSummary && <DiffWrap />}
    </ProfileListWrap>
  );
}

export default ProfilesList;
