import { renderInstanceTypeDescription } from "components/common/NodePoolCloudFields/InstanceTypeDescription";

export function sortListOfInstanceTypes(instanceTypes) {
  return instanceTypes.sort((a, b) => (a.value > b.value ? 1 : -1));
}

export function presentInstanceTypesAsOptions(instanceTypes) {
  let groupOptionsByCategory = (instanceTypes || []).reduce((acc, type) => {
    let category = type.category;
    if (!category) {
      category = "Others";
    }

    acc[category] = acc[category] || [];
    acc[category].push({
      title: type.type,
      value: type.type,
      description: renderInstanceTypeDescription(type),
    });
    return acc;
  }, {});

  const othersCategory = groupOptionsByCategory["Others"];
  if (othersCategory) {
    delete groupOptionsByCategory["Others"];
    groupOptionsByCategory = {
      ...groupOptionsByCategory,
      Others: othersCategory,
    };
  }

  return Object.keys(groupOptionsByCategory).map((category) => ({
    title: category,
    value: category,
    selectable: false,
    children: sortListOfInstanceTypes(groupOptionsByCategory[category]),
  }));
}

export function getNonSupportedZones(instanceTypes, selectedInstance) {
  if (!selectedInstance) {
    return [];
  }
  const selectedOption = instanceTypes?.find(
    (type) => type.type === selectedInstance
  );
  return selectedOption?.nonSupportedZones || [];
}

export function presentAzsAsOptions(azs, instanceTypes, selectedInstance) {
  if (!azs) return [];

  const nonSupportedZones = getNonSupportedZones(
    instanceTypes,
    selectedInstance
  );

  return azs
    .map((az) => ({
      label: az.name,
      value: az.zoneId,
      disabled: nonSupportedZones?.some((zone) => zone === az.name),
    }))
    .sort((a, b) => (a.label > b.label ? 1 : -1));
}
