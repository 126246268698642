import i18next from "i18next";
import store from "services/store";
import _ from "lodash";

import { CLUSTER_GROUPS } from "utils/constants/routes";

import { WizardActions } from "modules/wizard/actions";
import historyService from "services/history";

import clusterGroupFormActions from "./form";
import {
  FORM_MODULE,
  WIZARD_MODULE,
  cancelCreateGroupModal,
  revertPackValuesConfirm,
  projectHostClustersFetcher,
  profileModule,
} from "state/clustergroups/services/create";
import api from "services/api";
import { ClusterSchema } from "utils/schemas";
import {
  getVirtualYamlConfig,
  virtualYamlConfigValuesFetcher,
} from "state/nestedclusters/services/create";
import { clusterGroupEditClusters } from "../hostclusters/edit";

export const wizardActions = new WizardActions({
  formActions: clusterGroupFormActions,
  fieldsToValidate() {
    return {
      0: ["name"],
      1: ["clusters"],
      2: [
        "requestsCpu",
        "requestsMemory",
        "requestsStorage",
        "cpuLimits",
        "memoryLimits",
        "storageLimits",
        "endpoints",
        "pods",
        "services",
        "secrets",
        "configMaps",
        "persistentVolumeClaims",
        "hostClusters",
      ],
      3: ["backupPrefix", "location", "backupedClusters"],
      4: [],
    };
  },
  getDescription({ id }) {
    const formData = store.getState().forms[FORM_MODULE]?.data;

    if (id === "basic-info") {
      return formData.name;
    }

    if (["clusters", "config", "settings", "add-on-profiles"].includes(id)) {
      return i18next.t("Completed");
    }
  },
  steps: [
    {
      title: () => i18next.t("Basic Information"),
      id: "basic-info",
    },
    {
      title: () => i18next.t("Clusters"),
      id: "clusters",
    },
    {
      title: () => i18next.t("Settings"),
      id: "settings",
    },
    {
      title: () => i18next.t("Add-On Profiles"),
      id: "add-on-profiles",
    },
    {
      title: () => i18next.t("Review"),
      id: "review",
    },
  ],
  onStepChange({ id }) {
    if (id === "clusters") {
      store.dispatch(projectHostClustersFetcher.fetch());
    }
    if (id === "add-on-profiles") {
      profileModule.actions.initialize({
        profiles: [],
      });
    }
  },
});

export function resetConfigToDefaultValues() {
  return async (dispatch) => {
    const yamlConfigValues = await getVirtualYamlConfig();
    dispatch(
      clusterGroupFormActions.onChange({
        module: FORM_MODULE,
        name: "yamlConfigValues",
        value: yamlConfigValues,
      })
    );
  };
}

export function onEditorRevert() {
  return (dispatch) => {
    revertPackValuesConfirm.open().then(() => {
      dispatch(resetConfigToDefaultValues());
    });
  };
}

export function onDistroChange(value) {
  return async (dispatch, getState) => {
    dispatch(
      clusterGroupFormActions.onChange({
        module: FORM_MODULE,
        name: "kubernetesDistroType",
        value,
      })
    );
    await dispatch(virtualYamlConfigValuesFetcher.fetch());
    const { result } = await virtualYamlConfigValuesFetcher.selector(
      getState()
    );

    dispatch(
      clusterGroupFormActions.onChange({
        module: FORM_MODULE,
        name: "yamlConfigValues",
        value: result,
      })
    );
  };
}

export function onEditorValuesChange(value) {
  return (dispatch) => {
    dispatch(
      clusterGroupFormActions.onChange({
        module: FORM_MODULE,
        name: "yamlConfigValues",
        value,
      })
    );
  };
}

export function onClusterEndpointTypeChange(value) {
  return (dispatch, getState) => {
    const module = getState().location?.params?.id
      ? "editClusters"
      : "clusterGroup";

    const formActions = {
      editClusters: clusterGroupEditClusters,
      clusterGroup: clusterGroupFormActions,
    };

    dispatch(
      formActions[module].onChange({
        module,
        name: "clusterEndpointType",
        value,
      })
    );

    dispatch(
      formActions[module].clearFieldErrors({
        module,
        field: "hostClusters",
      })
    );
  };
}

export function onWizardModalClose() {
  const { name } = store.getState().forms[FORM_MODULE]?.data || {};
  const returnPath = CLUSTER_GROUPS.ROOT;

  if (name === "") {
    historyService.push(returnPath);
    return;
  }

  cancelCreateGroupModal.open().then(() => {
    historyService.push(returnPath);
  });
}

export function onClusterRemove(clusterUid) {
  return (dispatch, getState) => {
    const { clusters, backupedClusters } =
      getState().forms[FORM_MODULE]?.data || {};
    const filteredClusters = (clusters || []).filter(
      (uid) => uid !== clusterUid
    );
    const filteredBackupList = (backupedClusters || []).filter(
      (uid) => uid !== clusterUid
    );

    const filteredGuids = (
      projectHostClustersFetcher.selector(store.getState())?.result || []
    )
      .filter((item) => {
        return filteredClusters.includes(item?.metadata.uid);
      })
      .map((item) => item.guid);

    dispatch(
      clusterGroupFormActions.batchChange({
        module: FORM_MODULE,
        updates: {
          clusters: filteredClusters,
          selectedClustersGuids: filteredGuids,
          backupedClusters: filteredBackupList,
        },
      })
    );
  };
}

export function fetchClustersByUids(clusterUids) {
  clusterUids.forEach((uid) => {
    store.dispatch({
      type: "FETCH_CLUSTER_GROUP_SELECTED_CLUSTER",
      promise: api.get(`v1/dashboard/spectroclusters/${uid}`),
      schema: ClusterSchema,
    });
  });
}

export function onClusterChange(value) {
  return async (dispatch, getState) => {
    const module = getState().location?.params?.id
      ? "editClusters"
      : "clusterGroup";

    const formActions = {
      editClusters: clusterGroupEditClusters,
      clusterGroup: clusterGroupFormActions,
    };

    const formData = getState().forms[module]?.data;
    const formClusters = formData?.clusters || [];
    const hostClusters = formData?.hostClusters || {};

    const clusters =
      projectHostClustersFetcher.selector(getState())?.result || [];
    const addedClusters = _.difference(value, formClusters);
    const existingHostClustersIngressErrorsUids = (
      getState().forms[module]?.errors || []
    )
      .filter((error) => error.field.includes("ingressHost"))
      .map((error) => {
        const fieldNameParts = error.field.split(".");

        return fieldNameParts[1];
      });

    const deletedClusterErrorUid = existingHostClustersIngressErrorsUids.find(
      (uid) => !value.includes(uid)
    );

    if (deletedClusterErrorUid) {
      dispatch(
        formActions[module].clearFieldErrors({
          module,
          field: `hostClusters.${deletedClusterErrorUid}.ingressHost`,
        })
      );
    }

    if (addedClusters.length > 0) {
      await fetchClustersByUids(addedClusters);
    }

    const filteredGuids = clusters
      .filter((cluster) => value.includes(cluster.metadata.uid))
      .map((cluster) => cluster?.guid);

    const updates = {
      selectedClustersGuids: filteredGuids,
      clusters: value,
      hostClusters: value.reduce((acc, uid) => {
        acc[uid] = {
          ...(hostClusters?.[uid] || {}),
        };

        return acc;
      }, {}),
    };

    dispatch(
      formActions[module].batchChange({
        module,
        updates,
      })
    );
  };
}

export function initClusterGroupWizard() {
  return (dispatch) => {
    dispatch(wizardActions.initialize(WIZARD_MODULE));
    dispatch(clusterGroupFormActions.init({ module: FORM_MODULE }));
  };
}
