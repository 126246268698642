import { createSelector } from "reselect";
import moment from "moment";
import i18next from "i18next";

import { getEntity } from "utils/entities";
import { ClusterNamespaceSchema } from "utils/schemas";
import { parseResourcesChartData } from "utils/parsers";
import { round, formatToUSCurrency } from "utils/number";
import { USAGE_LINE_CHART_COLORS } from "utils/constants/colors";

import {
  clusterActualCostsFetcher,
  USAGE_AND_COSTS_FILTERS_MODULE,
} from "state/cluster/services/costs";
import { NAMESPACE_LIST_MODULE } from "state/cluster/actions/costs";
import {
  utilizationFetcher,
  namespaceConsumptionFetcher,
} from "state/cluster/services";
import { mapChartPoints, isVirtualCluster } from "./details";

export const getUsageCosts = getEntity(
  (state) => state.list?.namespaceList?.items?.initial,
  [ClusterNamespaceSchema]
);

export const getNamespacesListItems = createSelector(
  getUsageCosts,
  (state) => state.forms?.[USAGE_AND_COSTS_FILTERS_MODULE]?.data?.namespaces,
  (items, namespaces) => {
    if (!namespaces || !namespaces.length) {
      return items;
    }
    return (items || []).filter((item) =>
      namespaces.some((ns) => item.entity?.name === ns)
    );
  }
);

export const getTotalUsageCost = createSelector(
  getNamespacesListItems,
  (items) => {
    if (!items?.length) {
      return "-";
    }
    const total = items.reduce(
      (acc, item) => acc + (item.total?.total || 0),
      0
    );
    return formatToUSCurrency(total, "usd", 2) || "-";
  }
);

export const getAverageUsageCost = createSelector(
  getNamespacesListItems,
  (items) => {
    if (!items?.length) {
      return "-";
    }
    const total = items.reduce(
      (acc, item) => acc + (item.total?.total || 0),
      0
    );
    const avg = round(total / items.length, 2);
    return formatToUSCurrency(avg, "usd", 2) || "-";
  }
);

function isSameTime(time1, time2, unit) {
  const sameHour = time1.isSame(time2, "hour");
  const sameDay = time1.isSame(time2, "day");
  const sameMinute = time1.isSame(time2, "minute");
  if (unit === "hour") {
    return sameHour && sameDay && sameMinute;
  }
  if (unit === "hours") {
    return sameHour && sameDay;
  }
  return sameDay;
}

function parseLastHourCostsResources({ result, query }) {
  return (result || []).map(({ entity, data }) => ({
    entity,
    data: Array.from({ length: 8 })
      .map((_, index) => {
        const currentXValue = moment(query.startTime)
          .startOf("hour")
          .add(index * 15, "minutes");
        const currentXValueToMs = currentXValue.valueOf() * 1000000;
        const isPastCurrentDay = moment().diff(currentXValue) < 0;
        if (isPastCurrentDay) {
          return false;
        }
        const dayCost = data.find((clusterCost) =>
          isSameTime(
            moment(clusterCost.timestamp / 1000000),
            currentXValue,
            "hour"
          )
        );

        if (!dayCost) {
          return false;
        }
        return {
          timestamp: currentXValueToMs,
          total: dayCost?.total || 0,
        };
      })
      .filter(Boolean),
  }));
}

function parseCostsResources({ result, query, filter }) {
  const { unit, value } = filter || {};

  return (result || []).map(({ entity, data }) => ({
    entity,
    data: Array.from({ length: value || 0 })
      .map((_, index) => {
        const currentXValue = moment(query.startTime).add(
          index,
          unit === "hours" ? "hours" : "days"
        );
        const currentXValueToMs = currentXValue.valueOf() * 1000000;
        const isPastCurrentDay = moment().diff(currentXValue) < 0;
        if (isPastCurrentDay) {
          return false;
        }

        const dayCost =
          data.find((clusterCost) =>
            isSameTime(
              moment(clusterCost.timestamp / 1000000),
              currentXValue,
              unit
            )
          )?.total || 0;

        return {
          timestamp: currentXValueToMs,
          total: dayCost,
        };
      })
      .filter(Boolean),
  }));
}

export const getCostsChartData = createSelector(
  getNamespacesListItems,
  clusterActualCostsFetcher.selector,
  (state) => state.forms?.[USAGE_AND_COSTS_FILTERS_MODULE]?.data,
  (result, { result: actualCostResult }, { filter = {}, query = {} } = {}) => {
    const actualCost = actualCostResult?.cluster?.cloud?.data;
    const { unit, value } = filter;
    let resources = [];

    if (value === "1" && unit === "hour") {
      resources = parseLastHourCostsResources({ result, query });
    } else {
      resources = parseCostsResources({ result, query, filter });
    }

    if (!result?.length && actualCost?.length > 0) {
      resources = [
        {
          data: Array.from({ length: value || 0 })
            .map((_, index) => {
              const currentXValue = moment(query.startTime).add(
                index,
                unit === "hours" ? "hours" : "days"
              );
              const currentXValueToMs = currentXValue.valueOf() * 1000000;
              const isPastCurrentDay = moment().diff(currentXValue) < 0;

              if (isPastCurrentDay) {
                return false;
              }

              return {
                timestamp: currentXValueToMs,
                total: 0,
              };
            })
            .filter(Boolean),
          entity: {},
        },
      ];
    }

    const dateFormats = {
      months: "DD MMM",
      days: "DD MMM",
      hours: "HH:mm",
    };

    return (
      parseResourcesChartData({
        result: resources,
        getY: (item) => item.total,
        xFormat: dateFormats[unit],
      }) || []
    );
  }
);

export const getSelectedKey = createSelector(
  (state) => state.forms?.[USAGE_AND_COSTS_FILTERS_MODULE]?.data?.filter,
  (filter = {}) => {
    const dropdownFilterKey = `${filter.value} ${filter.unit}`;
    return filter.type === "calendar" ? filter.type : dropdownFilterKey;
  }
);

export const getCalendarSelectedValue = createSelector(
  getSelectedKey,
  (state) =>
    state.forms?.[USAGE_AND_COSTS_FILTERS_MODULE]?.data?.query?.endTime,
  (selectedKey, endTime) => {
    return selectedKey !== "calendar"
      ? i18next.t("last {{selectedKey}}", { selectedKey })
      : endTime.format("MMM YYYY");
  }
);

export const getLegendItems = createSelector(
  getCostsChartData,
  isVirtualCluster,
  (chartData, isVirtualCluster) => {
    if (!chartData?.length) {
      return [];
    }
    const data = chartData
      .map((data) => ({
        id: data.id,
        color: data.color,
      }))
      .filter((data) => data.id);

    if (!isVirtualCluster) {
      data.unshift({
        id: "Cloud Cost",
        isCloudCost: true,
      });
    }

    return data;
  }
);

export const getActualCostsData = createSelector(
  clusterActualCostsFetcher.selector,
  (state) => state.forms?.[USAGE_AND_COSTS_FILTERS_MODULE]?.data,
  ({ result }, { filter = {}, query = {} } = {}) => {
    const actualCost = result?.cluster?.cloud?.data || [];
    const { unit, value } = filter;

    if (!actualCost?.length > 0) {
      return [];
    }

    return Array.from({ length: value || 0 })
      .map((_, index) => {
        const currentXValue = moment(query.startTime).add(
          index,
          unit === "hours" ? "hours" : "days"
        );
        const isPastCurrentDay = moment().diff(currentXValue) < 0;

        if (isPastCurrentDay) {
          return false;
        }

        const dayEstimatedCost =
          actualCost.find((clusterCost) =>
            isSameTime(
              moment(clusterCost.timestamp / 1000000),
              currentXValue,
              unit
            )
          )?.total || 0;

        const xFormat =
          unit === "months" || unit === "days" ? "DD MMM" : "DD MMM h:mma";

        return {
          x: currentXValue.format(xFormat),
          y: dayEstimatedCost,
        };
      })
      .filter(Boolean);
  }
);

export const getNamespacesListEntities = getEntity(
  (state) => state.list[NAMESPACE_LIST_MODULE]?.items?.initial,
  [ClusterNamespaceSchema]
);

export const getNamespacesFilterOptions = createSelector(
  getNamespacesListEntities,
  (namespaces) => {
    return (namespaces || []).map((ns) => ({
      label: ns?.entity?.name,
      value: ns?.entity?.name,
    }));
  }
);

function parseAvgAndMaxChartData({ usage, total, period, label, dateUnit }) {
  const values = {
    avg: (usage?.aggregation?.avg / total?.aggregation?.avg) * 100,
    max: (usage?.aggregation?.max / total?.aggregation?.max) * 100,
  };

  if (isNaN(values.avg) && isNaN(values.max)) {
    return {
      label,
      statistics: [],
      chartData: [],
    };
  }

  const maxLabel = {
    label: "Maximum",
    value: Number.isNaN(values.max) ? "-" : round(values.max, 2),
    color: USAGE_LINE_CHART_COLORS.MAX,
  };
  const maxData = {
    id: "max",
    color: USAGE_LINE_CHART_COLORS.MAX,
    data: mapChartPoints({ metric: usage, total, key: "max", dateUnit }),
  };
  let statistics = [
    {
      label: "Average",
      value: Number.isNaN(values.avg) ? "-" : round(values.avg, 2),
      color: period ? USAGE_LINE_CHART_COLORS.AVG : USAGE_LINE_CHART_COLORS.MAX,
    },
  ];
  let chartData = [
    {
      id: "avg",
      color: period ? USAGE_LINE_CHART_COLORS.AVG : USAGE_LINE_CHART_COLORS.MAX,
      data: mapChartPoints({ metric: usage, total, key: "avg", dateUnit }),
    },
  ];

  if (period) {
    statistics = [...statistics, maxLabel];
    chartData = [...chartData, maxData];
  }

  return {
    label,
    statistics,
    chartData,
  };
}

export const getAvgAndMaxMetrics = createSelector(
  utilizationFetcher.selector,
  (state) => state.forms?.[USAGE_AND_COSTS_FILTERS_MODULE]?.data,
  ({ result }, { averageAndMaximumType, filter } = {}) => {
    if (!result) {
      return [];
    }

    const { memoryUsage, memoryTotal, cpuUsage, cpuTotal, period } = result;

    if (averageAndMaximumType === "cpu") {
      return parseAvgAndMaxChartData({
        usage: cpuUsage,
        total: cpuTotal,
        period,
        dateUnit: filter?.unit,
      });
    }

    return parseAvgAndMaxChartData({
      usage: memoryUsage,
      total: memoryTotal,
      period,
      dateUnit: filter?.unit,
    });
  }
);

export const getNamespaceUsageMetrics = createSelector(
  namespaceConsumptionFetcher.selector,
  (state) => state.forms?.[USAGE_AND_COSTS_FILTERS_MODULE]?.data,
  ({ result }, { filter = {}, namespaces, namespacesUsageType } = {}) => {
    const unit = filter.unit;
    let resources = result?.resources || [];
    if (namespaces && namespaces.length > 0) {
      resources = resources.filter((resource) =>
        namespaces.some((ns) => ns === resource?.entity?.name)
      );
    }
    const getCpu = (item) =>
      item.allotted.cpu > 0
        ? round((item.usage.cpu / item.allotted.cpu) * 100, 2)
        : 0;
    const getMemory = (item) =>
      item.allotted.memory > 0
        ? round((item.usage.memory / item.allotted.memory) * 100, 2)
        : 0;
    const xFormat =
      unit === "months" || unit === "days" ? "DD MMM" : "DD MMM h:mma";

    if (namespacesUsageType === "cpu") {
      return parseResourcesChartData({
        result: resources,
        getY: getCpu,
        xFormat,
      });
    }

    return parseResourcesChartData({
      result: resources,
      getY: getMemory,
      xFormat,
    });
  }
);

export const getNamespacesUsageLegend = createSelector(
  getNamespaceUsageMetrics,
  (chartData) => {
    if (!chartData?.length) {
      return [];
    }
    const data = chartData
      .map((data) => ({
        id: data.id,
        color: data.color,
      }))
      .filter((data) => data.id);

    return data;
  }
);
