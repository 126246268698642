import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { faChevronDown } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { lightMidGray } from "utils/constants/colors";

import { TextButton } from "components/ui/Button";
import ActionMenu from "components/common/ActionMenu";

const VersionTrigger = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: ${(props) => props.minWidth || "160px"};
  height: ${(props) => props.height || "32px"};
  padding: 0 12px;
  border: 1px solid ${lightMidGray};
`;

export default function SelectVersionMenu({
  versions,
  selectedVersion,
  onVersionChange,
  openNewVersionModal,
  canCreate = true,
  disabled = false,
  ...rest
}) {
  const { t } = useTranslation();

  const menuOptions = useMemo(() => {
    const options = (versions || []).map((item) => ({
      key: item?.version,
      label: item?.version,
      onClick: () => onVersionChange(item),
    }));

    const createNewVersionOption = canCreate && {
      key: "newVersion",
      label: (
        <TextButton data-qa="create-version">
          {t("Create new version")}
        </TextButton>
      ),
      onClick: openNewVersionModal,
    };

    return [...options, createNewVersionOption].filter(Boolean);
  }, [versions, onVersionChange, openNewVersionModal, canCreate, t]);

  if (!selectedVersion) {
    return null;
  }

  return (
    <ActionMenu
      options={menuOptions}
      selectedKeys={[selectedVersion]}
      disabled={disabled}
    >
      <VersionTrigger {...rest}>
        <span>{selectedVersion}</span>
        <FontAwesomeIcon icon={faChevronDown} />
      </VersionTrigger>
    </ActionMenu>
  );
}
