import React from "react";
import { connect } from "react-redux";
import { Modal as AntdModal } from "antd";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import {
  whiteSmoke,
  midGray,
  mediumLightGray,
  lightMidGray,
} from "utils/constants/colors";

import Button, { TextButton } from "components/ui/Button";

const StyledModal = styled(AntdModal)`
  display: flex;
  max-height: ${(props) => props.height || "100%"};
  width: 100%;

  .ant-modal-body {
    overflow-y: auto;
    background: ${whiteSmoke};
  }

  .ant-modal-content {
    display: flex;
    flex-direction: column;
    max-height: 100%;

    max-width: ${({ width }) => width};
    width: 100%;
  }

  .ant-modal-title {
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    width: 100%;
    color: ${midGray};
  }

  .ant-modal-footer {
    display: flex;
    flex-direction: row-reverse;
    justify-content: end;
    border-top: 1px solid ${lightMidGray};
  }

  .ant-modal-header {
    border-bottom: 1px solid ${lightMidGray};
  }

  .ant-modal-close-x {
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${mediumLightGray};
  }

  .ant-modal-footer,
  .ant-modal-header {
    background: ${whiteSmoke};
    flex-basis: 64px;
    display: flex;
    align-items: center;
  }
`;

export function DefaultModalButtons({
  ConfirmButton,
  confirmLabel,
  onConfirm,
  disabledConfirm,
  loading,
  cancelLabel,
  onCancel,
}) {
  const { t } = useTranslation();

  return [
    <ConfirmButton
      key="submit"
      data-qa="modal-confirm"
      onClick={onConfirm}
      disabled={disabledConfirm}
      loading={loading}
    >
      {confirmLabel || t("Confirm")}
    </ConfirmButton>,
    <TextButton
      key="cancel"
      data-qa="modal-cancel"
      onClick={onCancel}
      disabled={loading}
      secondary
    >
      {cancelLabel || t("Cancel")}
    </TextButton>,
  ];
}

const Modal = ({
  width,
  visible,
  disabledConfirm,
  title,
  onConfirm,
  confirmLabel,
  onCancel,
  cancelLabel,
  children,
  name,
  maskClosable = false,
  loading,
  ModalButtons = DefaultModalButtons,
  ConfirmButton = Button,
  ...rest
}) => {
  const buttonProps = {
    confirmLabel,
    onConfirm,
    disabledConfirm,
    loading,
    cancelLabel,
    onCancel,
    ConfirmButton,
  };

  return (
    <StyledModal
      width={width}
      visible={visible}
      title={title}
      name={name}
      onCancel={onCancel}
      onOk={onConfirm}
      centered
      destroyOnClose={true}
      maskClosable={maskClosable}
      maskStyle={{
        backgroundColor: "rgba(43, 50, 60, 0.8)",
      }}
      footer={<ModalButtons {...buttonProps} />}
      {...rest}
    >
      {children}
    </StyledModal>
  );
};

StyledModal.defaultProps = {
  width: "480px",
};

export default connect(
  (_, ownProps) => ({
    visible: ownProps?.service?.isOpened() || ownProps.visible,
  }),
  (_, ownProps) => {
    return {
      onCancel: () => {
        const closeAction =
          ownProps.onClose || ownProps.service.reject || ownProps.service.close;
        closeAction();
      },
      onConfirm: ownProps.onConfirm || (() => ownProps.service.resolve()),
    };
  }
)(Modal);
