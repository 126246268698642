import { combineReducers } from "redux";
import createReducer from "utils/createReducer";

import * as create from "./create";
import * as details from "./details";
import * as layerConfig from "./layerConfig";

export default combineReducers({
  create: createReducer(create),
  details: createReducer(details),
  layerConfig: createReducer(layerConfig),
});
