import i18next from "i18next";
import api from "services/api";
import Validator from "services/validator";
import {
  Missing,
  areValidKubernetesTags,
  SemanticVersion,
} from "services/validator/rules";

import store from "services/store";
import { deployClusterModal, editClusterProfile } from "../services";
import notifications from "services/notifications";

import createFormActions from "modules/form/actions";
import { getRawClusterProfile } from "state/clusterprofile/selectors/details";
import { validateClusterProfileNameAndVersion } from "state/clusterprofile/actions/details";

import { ClusterProfileSchema } from "utils/schemas";
import { onDeleteProfile } from "./listing";
import { parseTagsForInput } from "utils/parsers";
import { formatTags } from "utils/presenters";
import { clusterProfileFormActions } from "./layerConfig";
import history from "services/history";

const EDIT_CLUSTER_MODULE = "editClusterProfile";

//

export async function submit(data) {
  const clusterprofile = getRawClusterProfile(store.getState());
  const initialData =
    store.getState().forms?.editClusterProfile?.initialData || {};

  if (initialData.name !== data.name || initialData.version !== data.version) {
    const error = await validateClusterProfileNameAndVersion(data);
    if (error) {
      notifications.error({
        message: i18next.t("Something went wrong"),
        description: error,
      });
      return Promise.reject();
    }
  }

  const promise = api.patch(
    `v1/clusterprofiles/${clusterprofile.metadata.uid}/metadata`,
    {
      metadata: {
        name: data.name,
        annotations: {
          description: data.description,
        },
        labels: formatTags(data.tags),
      },
      spec: {
        version: data?.version,
      },
    }
  );

  try {
    await promise;
    notifications.success({
      message: i18next.t("Cluster profile details were updated successfully"),
    });
  } catch (err) {
    notifications.error({
      message: i18next.t(
        "Something went wrong while updating the cluster profile"
      ),
      description: err.message,
    });

    return;
  }

  store.dispatch({
    type: "UPDATE_SELECTED_VERSION",
    version: data.version,
  });
  await store.dispatch(getClusterProfileAfterEdit(clusterprofile.metadata.uid));
  store.dispatch(clusterProfileFormActions.init({ module: "clusterprofile" }));
}

export const validator = new Validator();
function createValidator() {
  validator.removeRules();
  validator.addRule(["name", "version"], Missing());
  validator.addRule(["tags"], areValidKubernetesTags());
  validator.addRule(["version"], SemanticVersion());
}

createValidator();

export const editClusterProfileFormActions = createFormActions({
  validator,
  submit,
  init: () => {
    const { metadata, spec } = getRawClusterProfile(store.getState());
    const { name, annotations, labels } = metadata;

    return Promise.resolve({
      name: name,
      description: annotations?.description || "",
      tags: parseTagsForInput(labels),
      version: spec?.version,
    });
  },
});

export function onClusterProfileInfo() {
  return (dispatch) => {
    dispatch(
      editClusterProfileFormActions.init({ module: EDIT_CLUSTER_MODULE })
    );
    createValidator();
    editClusterProfile.open().then(async () => {
      await dispatch(
        editClusterProfileFormActions.submit({ module: EDIT_CLUSTER_MODULE })
      );
    });
  };
}

export function getClusterProfileAfterEdit(uid) {
  return function thunk(dispatch) {
    const promise = api.get(`v1/clusterprofiles/${uid}`);

    dispatch({
      type: "UPDATE_CLUSTER_PROFILE_DETAILS",
      promise,
      schema: ClusterProfileSchema,
    });

    return promise;
  };
}

export function onDetailsDelete() {
  return (dispatch, getState) => {
    const clusterprofile = getRawClusterProfile(getState());
    dispatch(onDeleteProfile(clusterprofile.guid));
  };
}

export function setIsClusterProfileEdit(isEdit) {
  return {
    type: "SET_CLUSTER_PROFILE_EDIT",
    isEdit,
  };
}

export function onDeployClusterProfile(uid) {
  return function thunk() {
    deployClusterModal.open().then(() => {
      history.push(`/clusters/create/${uid}`);
    });
  };
}
