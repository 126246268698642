export function validationsReducer() {
  return {
    name: "validations",
    initialState: {},
    stashErrors(nextState, { guid, errors }) {
      nextState[guid] = errors;
    },
    removeFileValidation(nextState, { fileGuid }) {
      delete nextState[fileGuid];
    },
    removeAllValidations(nextState, _, state) {
      Object.keys(state).forEach((fileGuid) => (nextState[fileGuid] = []));
    },
  };
}
