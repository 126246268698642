import { createSelector } from "reselect";
import language from "i18next";

import { RepositorySchema, PackVersionSchema, PackSchema } from "utils/schemas";
import { getEntity } from "utils/entities";
import {
  presentLayer,
  generateEditorYamlSchema,
  groupPresetsOptions,
  formatTags,
} from "utils/presenters";
import { ADD_GUID } from "state/clusterprofile/reducers/layerConfig";
import { getRawClusterProfile } from "./details";
import {
  repositoriesFetcher,
  packNamesFetcher,
  profileBuilderCreateModule,
  profileBuilderEditModule,
} from "../services";
import { KUBERNETES_LAYER } from "utils/constants";
import {
  getPackValuesWithoutPresetsComment,
  getDefaultPresetsFromValues,
} from "utils/parsers";
import { updateInstallOrder } from "utils/yaml";
import { presentVariableToPayload } from "modules/profileBuilder/actions/createProfileForm";

const errorMessages = {
  missingField: () => language.t("Please select an item in order to continue"),
  missingLayerType: () =>
    language.t(
      "Layers are not configured. Please configure all the layers in order to continue"
    ),
};

export const getLayers = createSelector(
  (state) => state.forms.clusterprofile.data.layers,
  (layers) => {
    if (!layers) {
      return [];
    }

    return layers.map((layer) => {
      return presentLayer({ ...layer, ...layer.config });
    });
  }
);

export const getLayerTypes = createSelector(
  (state) => state.clusterprofile.layerConfig.layerTypes,
  (layerTypes) => layerTypes.map(presentLayer)
);

export const getUnconfiguredLayerTypes = createSelector(
  (state) => state.clusterprofile.layerConfig.layerTypes,
  (state) => state.forms.clusterprofile.data.layers,
  (layerTypes, configuredLayers) => {
    const configuredLayerTypes = configuredLayers.map((layer) => layer.type);
    return layerTypes
      .filter(({ type }) => {
        return !configuredLayerTypes.includes(type);
      })
      .map(presentLayer);
  }
);

export const getLayerPackVersions = getEntity(
  createSelector(getLayers, (layers) => {
    return layers.map((layer) => layer.version);
  }),
  [PackVersionSchema]
);

export const hasIngressLayer = createSelector(
  getLayerPackVersions,
  (versions) => {
    return versions.some(
      (version) => version?.pack?.spec?.annotations?.ingressCapability
    );
  }
);

export const getSelectedPackVersion = getEntity(
  (state) => state.forms.packSteps.data.version,
  PackVersionSchema
);

export const getSelectedPackUid = createSelector(
  getSelectedPackVersion,
  (selectedPack) => {
    return selectedPack.packUid;
  }
);

export const getSelectedRepository = getEntity(
  (state) => state.clusterprofile.layerConfig.selectedRepository,
  RepositorySchema
);

export const getRepositories = createSelector(
  repositoriesFetcher.selector,
  (fetchStatus) => fetchStatus.result
);

export const getAvailableRegistries = createSelector(
  (state) => state.clusterprofile.layerConfig.availableRepositories,
  (repositories) => {
    return repositories.map((repository) => ({
      label: repository.metadata.name || repository.metadata.uid,
      value: repository.guid,
    }));
  }
);

export const getFormError = createSelector(
  (state) => state.clusterprofile.layerConfig.stepNumber,
  (state) => state.forms.clusterprofile,
  (currentStep, formState) => {
    let message = "";
    const errors = formState && formState.errors;

    if (errors && errors.length === 0) {
      return null;
    }

    if (currentStep === 0 || currentStep === 1) {
      message = errorMessages.missingField();
    }

    if (currentStep === 2) {
      message = errorMessages.missingLayerType();
    }

    return message;
  }
);

export const getSelectedLayer = createSelector(
  (state) => state.clusterprofile.layerConfig.selectedLayer,
  getLayers,
  (selectedLayer, layers) => {
    if (selectedLayer === ADD_GUID) {
      return { type: "new", guid: ADD_GUID };
    }

    const layer = layers.find((layer) => layer.guid === selectedLayer);
    if (!layer) {
      return null;
    }

    return layer;
  }
);

export const getPackVersions = getEntity(
  (state) => state.clusterprofile.layerConfig.versions,
  [PackVersionSchema]
);

export const getCurrentSidebarStep = createSelector(
  (state) => state.clusterprofile.layerConfig.selectedLayer,
  (state) => state.clusterprofile.layerConfig.sidebarStep,
  (selectedLayer, step) => {
    return selectedLayer ? step[selectedLayer] : "preview";
  }
);

export const getPackTree = createSelector(getPackVersions, (versions) => {
  const roots = versions
    .filter((version) => {
      const [major, minor, patch] = version.tag.split(".");
      return major !== undefined && minor !== undefined && patch === "x";
    })
    .map((version) => ({
      ...version,
      version: version.group
        ? `${version.tag.replace(`${version.group}__`, "")} (${version.group})`
        : version.tag,
      currentVersion: version.version,
    }));

  versions.forEach((version) => {
    const parentTags = version?.parentTags;
    const parent = parentTags?.[0];
    if (!parent) {
      return;
    }
    const parentVersion = roots.find(
      (rootVersion) => rootVersion.tag === parent
    );

    if (parentVersion) {
      parentVersion.children = parentVersion.children || [];
      parentVersion.children.push(version);
    }
  });

  return roots;
});

export const getLayerConfiguration = createSelector(
  getSelectedLayer,
  getSelectedPackVersion,
  (selectedLayer, packVersion) => {
    const config = selectedLayer.config;

    let parameters = packVersion?.pack?.spec?.params?.filter(
      (param) => param.scope === "clusterprofile"
    );
    const values = config?.params;

    if (!values || !parameters) {
      return [];
    }

    return parameters.map((param) => {
      return {
        ...param,
        value: values[param.name],
      };
    });
  }
);

export const isAdding = createSelector(
  getSelectedLayer,
  (selectedLayer) => selectedLayer?.guid === ADD_GUID
);

export const getSelectedClusterProfilePacks = getEntity(
  (state) => state.clusterprofile.layerConfig.selectedClusterProfilePacks,
  [PackSchema]
);

export const getSelectedClusterProfileTemplate = createSelector(
  getRawClusterProfile,
  (clusterprofile) =>
    clusterprofile.status.isPublished
      ? clusterprofile.spec.published
      : clusterprofile.spec.draft
);

export const getPackParams = createSelector(
  (state) => state.forms.packSteps.data,
  (formData) => {
    return formData.values || "";
  }
);

export const getSelectedPack = createSelector(
  packNamesFetcher.selector,
  (state) => state.forms.packSteps.data.name,
  (state) => state.forms.packSteps.data.type,
  (packs, selectedPackName, type) => {
    if (type === "k8s") {
      return {
        label: KUBERNETES_LAYER.title(),
        logo: KUBERNETES_LAYER.icon,
      };
    }
    return packs.result?.find((pack) => pack.value === selectedPackName);
  }
);

export const getProfileLayersPreview = createSelector(
  () => profileBuilderCreateModule.state,
  (profileBuilder) => {
    return profileBuilder?.layers?.map((layer) => {
      return presentLayer({ ...layer, ...layer.config });
    });
  }
);

export const getReversedLayers = createSelector(
  getLayers,
  getSelectedLayer,
  (layers, selectedLayer) => {
    let pulseGuid = null;
    return [...layers].reverse().map((layer) => {
      if (
        layer.guid !== selectedLayer?.guid &&
        !layer?.config?.version &&
        !pulseGuid &&
        selectedLayer?.config?.version
      ) {
        pulseGuid = layer.guid;
        return {
          ...layer,
          pulse: true,
        };
      }

      return layer;
    });
  }
);

export const getCurrentLayerIndex = createSelector(
  getSelectedLayer,
  getReversedLayers,
  (selectedLayer, layers) => {
    return layers.findIndex((layer) => layer.guid === selectedLayer?.guid);
  }
);
function presentLayersForAPI(pack) {
  return {
    ...pack,
    values: updateInstallOrder(pack),
    ...(pack?.type === "manifest" && {
      uid: pack?.uid || "spectro-manifest-pack",
    }),
  };
}

export const getFormattedPayload = createSelector(
  (state) => state.forms.clusterprofile?.data || {},
  () => profileBuilderCreateModule.payload,
  () => profileBuilderEditModule.payload,
  (formData, createPayload, editPayload) => {
    return {
      metadata: {
        name: formData.name,
        annotations: {
          description: formData.description,
        },
        labels: formatTags(formData.tags),
      },
      spec: {
        template: {
          cloudType:
            formData.profileType === "add-on" ? "all" : formData.cloudType,
          type: formData.profileType,
          packs: formData.persisted
            ? (editPayload?.layers || []).map(presentLayersForAPI)
            : (createPayload?.layers || []).map(presentLayersForAPI),
        },
        variables: formData.persisted
          ? (editPayload?.variables || []).map(presentVariableToPayload)
          : (createPayload?.variables || []).map(presentVariableToPayload),
        version: formData.version,
      },
    };
  }
);

export const getLatestMajorVersion = createSelector(
  getPackVersions,
  (packVersions) =>
    packVersions.find((version) => {
      const patch = version.tag.split(".")[2];
      return patch === "x";
    })
);

export const areAllLayersConfigured = createSelector(
  (state) => state.forms.clusterprofile.data.layers,
  (layers = []) => layers.every((layer) => layer.config?.version)
);

export const areSomeLayersConfigured = createSelector(
  (state) => state.forms.clusterprofile.data.layers,
  (layers = []) => layers.some((layer) => layer.config?.version)
);

export const getLayerConfigurationStatus = createSelector(
  areAllLayersConfigured,
  areSomeLayersConfigured,
  (state) => state.clusterprofile.layerConfig.validationErrors,
  (isComplete, isIncomplete, validationErrors) => {
    if (validationErrors.length > 0) {
      return "errors";
    }

    if (isComplete) {
      return "complete";
    }

    if (isIncomplete) {
      return "incomplete";
    }

    return "not-started";
  }
);

export const getSelectedClusterProfileType = createSelector(
  getRawClusterProfile,
  (profile) =>
    profile?.spec?.published?.cloudType || profile?.spec?.draft?.cloudType
);

export const getSelectedVersionParent = createSelector(
  getPackVersions,
  getSelectedPackVersion,
  (versions, selectedVersion) => {
    return versions.find((pack) => {
      const parentTag = selectedVersion?.parentTags?.[0];

      return (
        pack.tag === parentTag &&
        // This is oddly specific because at first I receive then parent as 1.x, 2.x etc
        parentTag.split(".").length > 2
      );
    });
  }
);

export const getSelectedVersionValues = createSelector(
  getSelectedLayer,
  getSelectedPackVersion,
  (layer, packVersion) => {
    let values;
    if (layer.config?.packUid === packVersion.packUid) {
      // user's values
      values = layer.config?.values;
    } else {
      // pack's default values
      values = packVersion.values || packVersion.pack.spec.values || "";
    }
    return getPackValuesWithoutPresetsComment(values);
  }
);

export const getSelectedVersionPresets = createSelector(
  getSelectedLayer,
  getSelectedPackVersion,
  (layer, packVersion) => {
    const defaultOptions = getDefaultPresetsFromValues(
      packVersion?.pack?.spec?.values
    );
    if (layer.config?.packUid === packVersion?.packUid) {
      return layer.config?.presets || defaultOptions;
    }
    return defaultOptions;
  }
);

export const getSelectedVersionDefaultValues = createSelector(
  getSelectedPackVersion,
  (packVersion) => {
    const values = packVersion.values || packVersion.pack.spec.values || "";
    return getPackValuesWithoutPresetsComment(values);
  }
);

export const isSelectedPackValueOriginal = createSelector(
  getSelectedVersionValues,
  getSelectedVersionDefaultValues,
  (selectedVersionValues, selectedVersionDefaultValues) => {
    return selectedVersionValues === selectedVersionDefaultValues;
  }
);

export const getUnconfiguredPackNames = createSelector(
  packNamesFetcher.selector,
  (state) => state.forms.packSteps.data.type,
  (state) => state.forms.packSteps.data.name,
  (state) => state.forms.clusterprofile?.data?.layers,
  (state) => state.clusterprofile.layerConfig.selectedLayer,
  (packsNames, layerType, selectedPackName, layers, selectedLayer) => {
    const configuredLayerTypePacks = layers
      .filter((layer) => {
        return (
          layer.type === layerType &&
          layer.config &&
          layer.config.name !== selectedPackName &&
          layer.guid !== selectedLayer
        );
      })
      .map((layer) => layer.config?.name);

    return (packsNames.result || []).filter(
      (item) => !configuredLayerTypePacks.includes(item.name)
    );
  }
);

export const finishConfigurationCondition = createSelector(
  (state) => state.forms.clusterprofile?.data,
  areAllLayersConfigured,
  (state) => state.clusterprofile.layerConfig.selectedLayer,
  (formData, allConfiguredLayers, selectedLayer) => {
    const isInfra = formData.profileType === "infra";
    return allConfiguredLayers && (isInfra ? true : selectedLayer === ADD_GUID);
  }
);

export const getFilteredExpandedKeys = createSelector(
  (state) => state.clusterprofile.layerConfig?.treeSearchValue,
  (state) => getSelectedVersionParent(state)?.guid,
  getPackTree,
  (term, selectedParentVersion, tags) => {
    let expandedKeys = tags.reduce((acc, tag) => {
      if (tag.children) {
        const shouldExpand = tag.children.find((child) =>
          child.version.includes(term)
        );
        if (shouldExpand) {
          return [...acc, tag.guid];
        }
      }

      return acc;
    }, []);

    if (term.trim() === "") {
      expandedKeys = [];

      if (selectedParentVersion) {
        expandedKeys = [selectedParentVersion];
      }
    }

    return expandedKeys;
  }
);

export const shouldShowEditorNotifications = createSelector(
  (state) => state.clusterprofile.layerConfig.editorNotifications,
  getSelectedLayer,
  (editorNotifications, layer) => {
    return [...editorNotifications].includes(layer?.guid);
  }
);

export const getLayerPresets = createSelector(
  getSelectedPackVersion,
  (packVersion) => {
    const presets = packVersion?.pack?.spec?.presets;
    return groupPresetsOptions(presets);
  }
);

export const getEditorSchema = createSelector(
  getSelectedPackVersion,
  (packVersion) => {
    return generateEditorYamlSchema(packVersion?.pack?.spec?.schema);
  }
);
