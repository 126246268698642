import { createSelector } from "reselect";
import { getEntity } from "utils/entities";
import { AppDeploymentSchema, AppProfileSchema } from "utils/schemas";
import { APP_PROFILES_MODULE } from "state/appprofiles/actions/listing";
import { DEPLOYMENTS_LIST_MODULE } from "state/appdeployments/services/listings";

export const getTotalCountsOfAppDeployments = createSelector(
  (state) => state,
  (state) => state.list[DEPLOYMENTS_LIST_MODULE]?.count
);

export const getTotalCountsOfAppProfiles = createSelector(
  (state) => state,
  (state) => state.list?.[APP_PROFILES_MODULE]?.count
);

export const getListOfAppDeployments = createSelector(
  (state) => state?.list[DEPLOYMENTS_LIST_MODULE]?.items || {},
  (state) => state?.list[DEPLOYMENTS_LIST_MODULE]?.pages || new Set(),
  (items, pages) => {
    const listOfIds = [...pages].reduce((accumulator, page) => {
      return accumulator.concat(items[page] || []);
    }, []);
    return [...new Set(listOfIds)];
  }
);

export const getListOfAppProfiles = createSelector(
  (state) => state?.list[APP_PROFILES_MODULE]?.items || {},
  (state) => state?.list[APP_PROFILES_MODULE]?.pages || new Set(),
  (items, pages) => {
    const listOfIds = [...pages].reduce((accumulator, page) => {
      return accumulator.concat(items[page] || []);
    }, []);
    return [...new Set(listOfIds)];
  }
);

export const getDeployments = getEntity(getListOfAppDeployments, [
  AppDeploymentSchema,
]);

export const getAppProfiles = getEntity(getListOfAppProfiles, [
  AppProfileSchema,
]);

export const getDemoAppProfileUid = createSelector(
  (state) => getAppProfiles(state),
  (appProfiles) => {
    return appProfiles.reduce((accumulator, profile) => {
      if (profile?.metadata?.name === "profile-marvel") {
        return { ...accumulator, "profile-marvel": profile?.metadata?.uid };
      }
      if (profile?.metadata?.name === "profile-gamebox") {
        return { ...accumulator, "profile-gamebox": profile?.metadata?.uid };
      }
      return accumulator;
    }, {});
  }
);
