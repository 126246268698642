import React, { useState } from "react";
import { Resizable } from "react-resizable";
import styled from "styled-components";
import debounce from "lodash/debounce";

const Handler = styled.span`
  position: absolute;
  right: -5px;
  bottom: 0;
  z-index: 1;
  width: 10px;
  height: 100%;
  cursor: col-resize;
`;

const StyledResizable = styled(Resizable)`
  .ant-table-column-title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const ResizableColumn = ({ column = {}, ...rest }) => {
  const { onResize = () => {}, onClick = () => {} } = rest;
  const { label = "", width } = column;
  const [allowClick, setAllowClick] = useState(true);
  const debouncedOnResize = debounce(
    (e, { size }) => {
      e.stopPropagation();
      onResize(size);
    },
    100,
    { leading: true, trailing: false }
  );
  return (
    <StyledResizable
      title={label}
      width={width}
      handle={<Handler className="resize-handler" />}
      onResizeStart={() => setAllowClick(false)}
      onResize={debouncedOnResize}
      onResizeStop={() => {
        setTimeout(() => setAllowClick(true), 50);
      }}
      onClick={(e) => {
        allowClick && onClick(e);
      }}
    >
      <th {...rest} />
    </StyledResizable>
  );
};
