import ModalService from "services/modal";
import api from "services/api";

import dataFetcher from "modules/dataFetcher";
import { ClusterSchema } from "utils/schemas";
import ProfileStackModule from "modules/profileStack";

export const FORM_MODULE = "clusterGroup";
export const WIZARD_MODULE = "clusterGroup";

export const cancelCreateGroupModal = new ModalService();
export const revertPackValuesConfirm = new ModalService();

export const profileModule = new ProfileStackModule({
  name: "clusterGroup",
});

export const clusterWorkspacesFetcher = dataFetcher({
  selectors: ["cluster", "workspaces", "meta"],
  async fetchData() {
    const response = await api.get("v1/dashboard/workspaces");
    return (response?.items || []).map((item) => ({
      label: item.metadata?.name,
      value: item.metadata?.uid,
    }));
  },
});

export const groupsFilteredClustersFetcher = dataFetcher({
  selectors: ["groups", "spectroclusters"],
  schema: [ClusterSchema],
  async fetchData() {
    const response = await api.post("v1/dashboard/spectroclusters", {
      filter: { healthState: "Healthy", clusterStates: ["Running"] },
    });

    return (response?.items || []).filter(
      (cluster) => !cluster.specSummary?.projectMeta?.uid
    );
  },
});

export const projectHostClustersFetcher = dataFetcher({
  selectors: ["groups", "hostclusters"],
  schema: [ClusterSchema],
  async fetchData() {
    const response = await api.get(
      "v1/dashboard/spectroclusters/metadata?quickFilter=hostclusters"
    );

    return (response?.items || []).map((item) => ({
      metadata: { ...item },
      spec: {},
      status: {},
    }));
  },
});
