import api from "services/api";
import dataFetcher from "modules/dataFetcher";

import { GENERAL_DATE_FORMAT } from "utils/constants";
import { ClusterSchema } from "utils/schemas";
import { getEntity } from "utils/entities";

export const USAGE_AND_COSTS_FILTERS_MODULE = "usageAndCostsFilters";

export const getRawCluster = getEntity(
  (state) => state.cluster.details.currentClusterId,
  ClusterSchema
);

export const clusterActualCostsFetcher = dataFetcher({
  selectors: ["actualCosts", (state) => getRawCluster(state)?.metadata?.uid],
  fetchData([_, clusterUid], query) {
    const startTime = query.startTime.format(GENERAL_DATE_FORMAT);
    const endTime = query.endTime.format(GENERAL_DATE_FORMAT);
    const period = query?.period || 60 * 24;
    const queryParams = query
      ? `?startTime=${startTime}&endTime=${endTime}&period=${period}`
      : "";

    return api.get(
      `v1/dashboard/spectroclusters/${clusterUid}/cost${queryParams}`
    );
  },
});
