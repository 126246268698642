import Color from "color";

export const red = "#EB5757";
export const darkRed = "#d04863";
export const functionalRed = "#F03F6C";
export const blue = "#206cd1";
export const strongBlue = "#1d59ab";
export const functionalBlue = "#528CDD";
export const dodgerBlue = "#2681FA";
export const darkBlue = "#091e3b";
export const midnightBlue = "#0d1440";
export const darkGreen = "#219653";
export const green = "#57CA81";
export const turmeric = "#A9B531";
export const darkOrange = "#F2994A";
export const orange = "#FF9435";
export const yellow = "#E1B827";
export const lightYellow = "#fffaec";
export const highlightYellow = "#ffffcc";
export const marine = "#004F9E";
export const cyan = "#00ACC1";
export const lightCyan = "#e6f7ff";
export const concrete = "#78909C";
export const pink = "#FFC0CB";
export const turquoise = "#247893";
export const goldWarning = "#BF8756";
export const mustard = "#DDAF32";

// new palette
export const greenGray = "#64748B";
export const darkGray = "#3E4856";
export const concreteWhite = "#E8EBEE";
export const regentGray = "#818ea1";
export const platinum = "#DADEE3";
export const vintageBlue = "#4A88DA";
export const fauxAliceBlue = "#f4f8fd";
export const adminBlue = "#181C21";

export const primaryBlue = "#1890ff";
export const lightBlue = "#5FD5F9";
export const lightPurple = "#f2f1fa";
export const winterMist = "#f2f2f2";
export const whiteSmoke = "#f5f5f5";
export const purple = "#827CC5";
export const darkLavender = "#281E93";
export const lavender = "#4A4398";
export const lightLavender = "#A49FD5";
export const lightPurpleGray = "#DCDAF9";
export const lightRed = "#FFE3E3";
export const blueberry = "#7065AD";
export const nearLightBlue = "#F7F9FF";

export const info = "#409BF6";
export const success = "#219653";
export const warning = "#F2994A";
export const error = "#EB5757";

export const black = "#000";
export const nearBlack = "#111";
export const midGray = "#555";
export const mediumLightGray = "#bbb";
export const lightMidGray = "#ddd";
export const gray = "#777";
export const silver = "#BCC3CD";
export const lightSilver = "#aaa";
export const moonGray = "#ccc";
export const lightGray = "#eee";
export const nearWhite = "#f4f4f4";
export const lightWhite = "#F7F8F9";
export const titanWhite = "#F3F2FF";
export const white = "#fff";
export const navy = "#5fd5f9";
export const gold = "#D4AF37";
export const iconWarning = "#D4A832";
export const functionalOrange0 = "#FFFCF2";
export const functionalOrange05 = "#FFF4CF";
export const borderColorDefault = "#DEE1EA";
export const linkDefault = "#1890FF";
export const iconInformation = "#4A8FF1";
export const textInformation = "#31578C";
export const backgroundNeutral = "#1F263C0F";
export const backgroundNeutralHovered = "#1F263C24";
export const helperTextDefault = "#818EA1";
export const secondaryButtonBackground = "#EDEEF4";
export const borderDefault = "#DEE1EA";
export const elevationSurfaceSubtlest = "#F7F9FF";
export const lightNeutralNeutral1000 = "#2B323C";
export const backgroundBrandBold = "#5E58B6";
export const backgroundAccentNeutralSubtle = "#EDEEF4";
export const textSubtle = "#545F7E";
export const textInverse = "#FFFFFF";

export const STATUS_COLOR = {
  inactive: "#C4C4C4",
  unassigned: "#C4C4C4",
  paused: warning,
  pending: warning,
  configured: navy,
  provisioned: info,
  importing: info,
  installing: info,
  provisioning: info,
  updating: info,
  pausing: warning,
  resuming: info,
  active: success,
  running: success,
  pass: success,
  success,
  warning,
  error,
  fail: error,
  deleting: error,
  deleted: error,
  info: blue,
  successful: green,
  failed: error,
  scheduled: info,
  initiated: info,
  completed: green,
  inprogress: info,
  low: blue,
  medium: warning,
  high: red,
  warn: warning,
  passed: green,
  succeeded: success,
  waiting: warning,
  terminated: "#C4C4C4",
  "in-use": info,
  ready: green,
  deploying: info,
  deployed: green,
};

export const BAR_CHART_COLORS = [
  "#4432F5",
  "#5FD5F9",
  "#AF26FA",
  "#D4AF37",
  "#003366",
  "#42d4f4",
  "#f032e6",
  "#bfef45",
  "#daa520",
  "#469990",
  "#8b0000",
  "#9A6324",
  "#8a2be2",
  "#00ced1",
  "#66cdaa",
  "#808000",
  "#f7347a",
  "#000075",
  "#ff4040",
].map((color) => Color(color).lighten(0.3).hex());

export const PURE_COREHOURS_CHART_COLOR = vintageBlue;

export const ALLOY_COREHOURS_CHART_COLOR = regentGray;

export const PROGRESS_BAR_COLORS = {
  paleGreen: "#C5F1E1",
  malibu: "#C3E8FC",
};

export const COST_CHART_COLORS = [
  "#D67BC0",
  "#98D6BE",
  "#B398D9",
  "#A2E6FB",
  "#E9B4E2",
  "#F8DABF",
];

export const USAGE_LINE_CHART_COLORS = {
  MAX: "#b398d9",
  AVG: "#febdb4",
};
