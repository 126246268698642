import ModalService from "services/modal";

export const SCAN_SETTINGS_MODULE = "scan-settings";
export const SYFT_CONFIGURATION_FORM_MODULE = "syft-configuration";
export const scanWarning = new ModalService("scan-warning");
export const scanLogDetails = new ModalService("scan-log-details");
export const syftScanConfigurationModal = new ModalService(
  "syft-configuration"
);
export const scanImageContextModal = new ModalService("scan-image-context");
export const SCAN_TYPES = {
  0: "kubebench",
  1: "kubehunter",
  2: "sonobuoy",
  3: "syft",
  kubebench: 0,
  kubehunter: 1,
  sonobuoy: 2,
  syft: 3,
};
