import i18next from "i18next";
import { CLUSTER_GROUPS } from "utils/constants/routes";

import createFormActions from "modules/form/actions";
import historyService from "services/history";
import Validator from "services/validator";
import notifications from "services/notifications";
import {
  Missing,
  isKubernetesName,
  MaxLength,
  MinLength,
  DebouncedRule,
  isBelowLimit,
} from "services/validator/rules";
import store from "services/store";
import api from "services/api";

import { FORM_MODULE } from "state/clustergroups/services/create";
import { getClusterGroupPayload } from "state/clustergroups/selectors/create";
import { getVirtualYamlConfig } from "state/nestedclusters/services/create";

export const validator = new Validator();

const validateClusterName = async (value) => {
  let error;

  const promise = api.get(`v1/clustergroups/validate/name?name=${value}`);

  try {
    await promise;
  } catch (err) {
    let message;
    if (err.code === "InvalidName") {
      message = i18next.t(
        `Cluster group name must consist of lower case alphanumeric characters and must start with an alphabet`
      );
    }

    if (err.code === "ResourceNameAlreadyExists") {
      message = i18next.t(
        `Cluster group with the name "{{ value }}" already exists`,
        {
          value,
        }
      );
    }

    error = {
      result: message,
      field: "name",
    };
  }

  return error || false;
};

const nameValidationRule = DebouncedRule()(validateClusterName);

validator.addRule(["name", "clusters", "project"], Missing());
validator.addRule("name", isKubernetesName());
validator.addRule(["name"], (value) => {
  if (!value || value.length < 3 || value.length > 32) {
    return;
  }
  const promise = nameValidationRule(value);
  store.dispatch({
    type: "CLUSTER_GROUP_NAME_VALIDATION",
    promise,
  });
  return promise;
});
validator.addRule(["name"], MaxLength(32));
validator.addRule(["name"], MinLength(3));
validator.addRule("hostClusters", function* (value, key, data) {
  if (data?.clusterEndpointType === "Ingress") {
    for (const clusterUid in value) {
      yield {
        result: !value?.[clusterUid]?.ingressHost
          ? i18next.t("Missing field")
          : false,
        field: `hostClusters.${clusterUid}.ingressHost`,
      };
    }
  }
});
validator.addRule(["cpuLimits"], isBelowLimit("CPU", 3));
validator.addRule(["memoryLimits"], isBelowLimit("Memory", 3));
validator.addRule(["storageLimits"], isBelowLimit("Storage", 2));

const clusterGroupFormActions = createFormActions({
  validator,
  init: async () => {
    const yamlConfigValues = await getVirtualYamlConfig();

    return {
      //basic info
      name: "",
      description: "",
      tags: [],

      //clusters
      clusters: [],
      selectedClustersGuids: [],
      // profile
      clusterProfiles: [],

      //settings
      isHostCluster: true,
      cpuLimits: "6",
      memoryLimits: "8",
      storageLimits: "10",
      oversubscription: "120",
      clusterEndpointType: "Ingress",
      kubernetesDistroType: "k3s",
      yamlConfigValues,
      hostClusters: {},
    };
  },
  submit: async (data) => {
    const payload = getClusterGroupPayload(store.getState());
    const promise = api.post(`v1/clustergroups`, payload);
    let response;

    try {
      response = await promise;
    } catch (err) {
      notifications.error({
        message: i18next.t(
          "Something went wrong while creating the cluster group"
        ),
        description: err.message,
      });
    }

    if (response) {
      historyService.push(CLUSTER_GROUPS.ROOT);
      notifications.success({
        message: `Cluster group "${data.name}" has been created successfully`,
      });
    }
  },
});

export function onSubmit() {
  return (dispatch) => {
    dispatch(clusterGroupFormActions.submit({ module: FORM_MODULE }));
  };
}

export function handleMenuChange(value) {
  return (dispatch) => {
    dispatch(
      clusterGroupFormActions.onChange({
        module: FORM_MODULE,
        name: "currentKey",
        value,
      })
    );
  };
}

export default clusterGroupFormActions;
