import React from "react";
import { useTranslation } from "react-i18next";
import { pageWidthConstrain } from "components/styled/mixins";
import styled from "styled-components";
import ApiKeys from "../settings/apikeys";
import PageHeader from "components/common/layouts/App/PageHeader";

const Wrap = styled.div`
  ${pageWidthConstrain};
`;

export default function MyApiKeys() {
  const { t } = useTranslation();
  return (
    <Wrap>
      <PageHeader title={t("My API Keys")} withBack={false} />
      <ApiKeys />
    </Wrap>
  );
}
