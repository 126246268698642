import ListActions from "modules/list/actions";
import { RegistrationTokensSchema } from "utils/schemas";
import { SETTINGS } from "utils/constants/routes";
import historyService from "services/history";
import {
  registrationTokenModal,
  registrationTokensFetcher,
  REGISTRATION_TOKENS_FORM_MODULE,
  REGISTRATION_TOKENS_LIST_MODULE,
} from "./services";
import { nonProjectApi } from "services/api";
import createFormActions from "modules/form/actions";
import notifications from "services/notifications";
import i18next from "i18next";
import moment from "moment";
import { ApplyIf, Missing } from "services/validator/rules";
import Validator from "services/validator";
import store from "services/store";

function filterData(data, { owner, project }) {
  if (!owner && !project) {
    return data;
  }

  return {
    items: data.items.filter((item) => {
      if (owner && item.metadata.annotations.ownerUid !== owner) {
        return false;
      }

      if (project && item.spec.defaultProject.uid !== project) {
        return false;
      }

      return true;
    }),
  };
}

export const registrationTokensListActions = new ListActions({
  schema: [RegistrationTokensSchema],

  async fetchData(filters) {
    let data = registrationTokensFetcher.selector(store.getState()).result;

    if (data) {
      return filterData(data, filters);
    }

    await store.dispatch(registrationTokensFetcher.fetch());
    data = registrationTokensFetcher.selector(store.getState()).result;

    return filterData(data, filters);
  },

  defaultQuery: {
    owner: "",
    project: "",
    limit: 50,
  },
  initialQuery: () => {
    return {
      owner: "",
      project: "",
      limit: 50,
    };
  },
});

function parseDate(date) {
  if (typeof date === "number") {
    return moment().add(date, "days").toISOString();
  }

  return moment(date).toISOString();
}

const validator = new Validator();
validator.addRule(["name", "expiryDate"], Missing());
validator.addRule(
  ["customDate"],
  ApplyIf((value, key, data) => data.expiryDate === "custom", Missing())
);

export const registrationTokensFormActions = createFormActions({
  validator,
  init: async () => {
    const uid = registrationTokenModal?.data?.uid;

    let data;

    if (uid) {
      try {
        data = await nonProjectApi.get(`v1/edgehosts/tokens/${uid}`);
      } catch (err) {
        notifications.error({
          message: i18next.t(
            "Something went wrong while fetching the requested registration token"
          ),
          description: err.message,
        });
        return Promise.reject(err);
      }
    }

    return Promise.resolve({
      name: data?.metadata?.name || "",
      description: data?.metadata?.annotations?.description || "",
      expiryDate: data?.spec?.expiry
        ? moment(data?.spec?.expiry).format("D MMM YYYY")
        : "",
      customDate: moment().add(1, "days"),
      status: data?.status?.isActive,
      defaultProjectUid: data?.spec?.defaultProject?.uid || "",
    });
  },
  submit: async (data) => {
    const uid = registrationTokenModal?.data?.uid;

    const payload = {
      metadata: {
        name: data.name,
        annotations: {
          description: data.description,
        },
        uid,
      },
      spec: {
        defaultProjectUid: data.defaultProjectUid || "",
        expiry:
          data.expiryDate === "custom"
            ? moment(data.customDate).toISOString()
            : parseDate(data.expiryDate),
      },
    };

    let promise;

    if (uid) {
      promise = nonProjectApi.put(`v1/edgehosts/tokens/${uid}`, payload);
    } else {
      promise = nonProjectApi.post(`v1/edgehosts/tokens/`, payload);
    }

    try {
      await promise;
    } catch (error) {
      notifications.error({
        message: uid
          ? i18next.t(
              "Something went wrong while updating the registration token"
            )
          : i18next.t(
              "Something went wrong while creating the registration token"
            ),
        description: error.message,
      });
      return Promise.reject(error);
    }

    notifications.success({
      message: uid
        ? i18next.t("Registration token '{{name}}' was updated successfully", {
            name: data?.name,
          })
        : i18next.t("Registration token '{{name}}' was created successfully", {
            name: data?.name,
          }),
    });
  },
});

export function openRegistrationTokenModal(uid) {
  return (dispatch, getState) => {
    function fallback() {
      historyService.push(SETTINGS.REGISTRATION_TOKENS);
      dispatch(refreshList());
    }
    registrationTokenModal.open({ uid }).then(async () => {
      await dispatch(
        registrationTokensFormActions.submit({
          module: REGISTRATION_TOKENS_FORM_MODULE,
        })
      );
      fallback();
    }, fallback);
    dispatch(
      registrationTokensFormActions.init({
        module: REGISTRATION_TOKENS_FORM_MODULE,
      })
    );
  };
}

function refreshList() {
  return (dispatch) => {
    dispatch(registrationTokensFetcher.flushCache());
    dispatch(
      registrationTokensListActions.refreshItems(
        REGISTRATION_TOKENS_LIST_MODULE
      )
    );
  };
}

export function onRegistrationTokenChangeStatus(data, isActive) {
  return async (dispatch) => {
    try {
      await nonProjectApi.put(
        `v1/edgehosts/tokens/${data.metadata.uid}/state`,
        {
          isActive,
        }
      );
      dispatch(refreshList());
    } catch (err) {
      notifications.error({
        message: isActive
          ? i18next.t(
              "Something when wrong when trying to re-activate '{{name}}'",
              {
                name: data?.metadata?.name,
              }
            )
          : i18next.t("Something when wrong when trying to revoke '{{name}}'", {
              name: data?.metadata?.name,
            }),
      });
    }
  };
}

export function onRegistrationTokenDelete(data) {
  return async (dispatch) => {
    try {
      await nonProjectApi.delete(`v1/edgehosts/tokens/${data.metadata.uid}`);
      dispatch(refreshList());
    } catch (err) {
      notifications.error(
        i18next.t("Something when wrong when trying to delete '{{name}}'", {
          name: data?.metadata?.name,
        })
      );
    }
  };
}
