import React from "react";
import { createTeleporter } from "react-teleporter";
import styled from "styled-components";

export function createSlots(slots) {
  const Root = createTeleporter();

  const Slots = slots.reduce((accumulator, slot) => {
    accumulator[slot] = createTeleporter();
    return accumulator;
  }, {});

  function RootComponent({ children, className }) {
    return (
      <>
        <Root.Target className={className} />
        <Root.Source>
          {children ||
            slots.map((slot) => {
              const Component = Slots[slot].Target;
              return <Component key={slot} />;
            })}
        </Root.Source>
      </>
    );
  }

  RootComponent.Slot = {};
  slots.forEach((slot) => {
    RootComponent[slot] = function SlotComponent({ children }) {
      const Component = Slots[slot].Source;
      return <Component>{children}</Component>;
    };
  });

  slots.forEach((slot) => {
    RootComponent.Slot[slot] = function SlotComponent({ children }) {
      const Component = Slots[slot].Target;
      return <Component>{children}</Component>;
    };
  });

  RootComponent.addFills = function (Component) {
    slots.forEach((slot) => {
      Component[slot] = function SlotComponent(props) {
        const Component = Slots[slot].Source;
        return <Component {...props} />;
      };
    });
  };

  return RootComponent;
}

export const ThreeColumns = createSlots(["Toolbar", "Files", "Editor"]);

const Wrap = styled(ThreeColumns)`
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
  align-items: stretch;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);

  > :nth-child(3) {
    flex-grow: 1;
  }
`;

export function ThreeColumnsLayout() {
  return (
    <Wrap>
      <ThreeColumns.Slot.Toolbar />
      <ThreeColumns.Slot.Files />
      <ThreeColumns.Slot.Editor />
    </Wrap>
  );
}

ThreeColumns.addFills(ThreeColumnsLayout);
