import { createSelector } from "reselect";
import querystring from "query-string";

import { getEntity } from "utils/entities";
import { ClusterGroupSchema } from "utils/schemas";
import { CLUSTER_GROUPS } from "utils/constants/routes";

import {
  CLUSTER_GROUPS_LIST_MODULE,
  CLUSTER_GROUPS_FILTERS_MODULE,
  VIRTUAL_CLUSTERS_LIST_MODULE,
  VIRTUAL_CLUSTERS_FILTERS_MODULE,
  HOST_CLUSTERS_LIST_MODULE,
  HOST_CLUSTERS_FILTERS_MODULE,
} from "../services/listing";

export const getClusterGroupsList = getEntity(
  (state) => state.list[CLUSTER_GROUPS_LIST_MODULE]?.items?.initial,
  [ClusterGroupSchema]
);

export const getFilteredClusterGroups = createSelector(
  getClusterGroupsList,
  (state) => state.forms[CLUSTER_GROUPS_FILTERS_MODULE]?.data,
  (list, filters) => {
    const { groupName, clusters } = filters || {};
    if (!groupName && !clusters?.length) {
      return list || [];
    }

    return (list || []).filter((item) => {
      const nameIsMatched =
        !groupName ||
        item.metadata?.name
          ?.toLowerCase()
          ?.startsWith(groupName?.toLowerCase());
      const clusterIsMatched =
        !clusters?.length ||
        clusters.some((cluster) =>
          (item?.spec?.hostClusters || []).find(
            (hostCluster) => hostCluster.name === cluster
          )
        );

      return nameIsMatched && clusterIsMatched;
    });
  }
);

export const hasClusterGroupsFiltersApplied = createSelector(
  (state) => state.forms[CLUSTER_GROUPS_FILTERS_MODULE]?.data,
  (filters = {}) => {
    return filters.groupName || filters.clusters?.length > 0;
  }
);

export const isInitialClusterGroupsListEmpty = createSelector(
  (state) => state.list[CLUSTER_GROUPS_LIST_MODULE]?.items?.initial?.length,
  (state) => state.list[CLUSTER_GROUPS_LIST_MODULE]?.isLoading,
  (state) => state.list[CLUSTER_GROUPS_LIST_MODULE]?.isInitializing,
  hasClusterGroupsFiltersApplied,
  (listLength, isLoading, isInitializing, hasFiltersApplied) => {
    return !listLength && !isLoading && !isInitializing && !hasFiltersApplied;
  }
);

export const getClusterGroupsLink = createSelector(
  (state) => state.forms[CLUSTER_GROUPS_FILTERS_MODULE]?.data,
  (data) => {
    const root = CLUSTER_GROUPS.ROOT;

    if (!data) {
      return root;
    }
    const query = querystring.stringify({
      groupName: data?.groupName || "",
      clusters: data?.clusters || [],
    });

    return `${root}?${query}`;
  }
);

export const hasVirtualClustersFiltersApplied = createSelector(
  (state) => state.forms[VIRTUAL_CLUSTERS_FILTERS_MODULE]?.data,
  (filters = {}) => {
    return !!filters.clusterName;
  }
);

export const isInitialVirtualClustersListEmpty = createSelector(
  (state) => state.list[VIRTUAL_CLUSTERS_LIST_MODULE]?.count,
  (state) => state.list[VIRTUAL_CLUSTERS_LIST_MODULE]?.isLoading,
  (state) => state.list[VIRTUAL_CLUSTERS_LIST_MODULE]?.isInitializing,
  hasVirtualClustersFiltersApplied,
  (count, isLoading, isInitializing, hasFilters) => {
    return !isLoading && !isInitializing && !count && !hasFilters;
  }
);

export const hasHostClustersFiltersApplied = createSelector(
  (state) => state.forms[HOST_CLUSTERS_FILTERS_MODULE]?.data,
  (filters = {}) => {
    return !!filters.hostClusterName;
  }
);

export const isInitialHostClustersListEmpty = createSelector(
  (state) => state.list[HOST_CLUSTERS_LIST_MODULE]?.count,
  (state) => state.list[HOST_CLUSTERS_LIST_MODULE]?.isLoading,
  (state) => state.list[HOST_CLUSTERS_LIST_MODULE]?.isInitializing,
  hasVirtualClustersFiltersApplied,
  (count, isLoading, isInitializing, hasFilters) => {
    return !isLoading && !isInitializing && !count && !hasFilters;
  }
);

export const getHostClustersListQuery = createSelector(
  (state) => state.list[HOST_CLUSTERS_LIST_MODULE]?.query,
  (query) => {
    return query || {};
  }
);

export const getVirtualClustersListQuery = createSelector(
  (state) => state.list[VIRTUAL_CLUSTERS_LIST_MODULE]?.query,
  (query) => {
    return query || {};
  }
);
