import moment from "moment";
import api from "services/api";

import ListActions from "modules/list/actions";
import { ClusterNamespaceSchema } from "utils/schemas";
import store from "services/store";
import createFormActions from "modules/form/actions";
import { includeMastersCache } from "services/localstorage/cache";

import { getCurrentUser } from "state/auth/selectors";
import { getRawCluster } from "state/cluster/selectors/details";
import { getSelectedKey } from "state/cluster/selectors/costs";
import {
  utilizationFetcher,
  namespaceConsumptionFetcher,
} from "state/cluster/services";
import {
  clusterActualCostsFetcher,
  USAGE_AND_COSTS_FILTERS_MODULE,
} from "state/cluster/services/costs";
import { GENERAL_DATE_FORMAT } from "utils/constants";
import { getBoolean } from "utils/parsers";

export const NAMESPACE_LIST_MODULE = "namespaceList";

export const usageAndCostsFilterFormActions = createFormActions({
  init: () => {
    return Promise.resolve({
      query: {},
      filter: {
        value: 7,
        unit: "days",
        type: "period",
      },
      namespaces: [],
      averageAndMaximumType: "cpu",
      namespacesUsageType: "cpu",
    });
  },
});

export function initClusterCosts() {
  return async (dispatch, getState) => {
    await dispatch(
      usageAndCostsFilterFormActions.init({
        module: USAGE_AND_COSTS_FILTERS_MODULE,
      })
    );

    const state = getState();
    const timePeriod = getSelectedKey(state);
    const selectedMonth =
      state.forms?.[USAGE_AND_COSTS_FILTERS_MODULE]?.data?.query?.endTime;

    dispatch(namespaceListActions.initialize(NAMESPACE_LIST_MODULE));

    if (timePeriod === "calendar") {
      dispatch(costsChartCalendarChange(selectedMonth.toDate()));
    } else {
      dispatch(costsChartTimePeriodChange(timePeriod));
    }
  };
}

export const USAGE_COSTS_TIME_PERIODS = {
  "1 hour": 10,
  "6 hours": 30,
  "12 hours": 60,
  "24 hours": 60,
  "7 days": 60 * 24,
  "14 days": 60 * 24,
  "30 days": 60 * 24,
};

export function costsChartTimePeriodChange(timePeriod) {
  return (dispatch, getState) => {
    const state = getState();
    const includeMasters = state.cluster.details.includeMasters;
    const clusterUid = getRawCluster(state)?.metadata?.uid;
    const [value, unit] = timePeriod.split(" ");
    const period = USAGE_COSTS_TIME_PERIODS[timePeriod];
    let startTime = moment().subtract(value - 1, unit);
    if (timePeriod === "1 hour") {
      startTime = moment().subtract(1, "hours");
    }
    const query = {
      startTime,
      endTime: moment(),
      period,
    };
    const formattedStartTime = query.startTime
      .utc()
      .format(GENERAL_DATE_FORMAT);
    const formattedEndTime = query.endTime.utc().format(GENERAL_DATE_FORMAT);

    dispatch(
      usageAndCostsFilterFormActions.batchChange({
        module: USAGE_AND_COSTS_FILTERS_MODULE,
        updates: {
          query,
          filter: { value, unit, type: "period" },
        },
      })
    );

    dispatch(
      namespaceListActions.batchChangeQuery({
        module: NAMESPACE_LIST_MODULE,
        query: {
          ...query,
          includeMasters,
        },
      })
    );
    dispatch(
      clusterActualCostsFetcher.fetch({
        ...query,
        period: period < 60 ? 60 : period,
      })
    );
    dispatch(
      utilizationFetcher.fetch(clusterUid, {
        startTime: formattedStartTime,
        endTime: formattedEndTime,
        includeControlPlaneMachines: includeMasters,
        period,
        metricKind: "cpuUsage,cpuTotal,memoryUsage,memoryTotal",
      })
    );
    dispatch(
      namespaceConsumptionFetcher.fetch({
        startTime: formattedStartTime,
        endTime: formattedEndTime,
        period,
      })
    );
  };
}

// date should be date type (new Date()) and not moment
export function costsChartCalendarChange(date = moment()) {
  return (dispatch, getState) => {
    const state = getState();
    const includeMasters = state.cluster.details.includeMasters;
    const clusterUid = getRawCluster(state)?.metadata?.uid;
    const startOfMonth = moment(date).startOf("month");
    const endOfMonth = moment(startOfMonth).endOf("month");
    const period = 60 * 24;
    const query = {
      startTime: startOfMonth,
      endTime: endOfMonth,
      period,
    };
    const formattedStartTime = query.startTime
      .utc()
      .format(GENERAL_DATE_FORMAT);
    const formattedEndTime = query.endTime.utc().format(GENERAL_DATE_FORMAT);

    dispatch(
      usageAndCostsFilterFormActions.batchChange({
        module: USAGE_AND_COSTS_FILTERS_MODULE,
        updates: {
          query,
          filter: {
            value: moment(date).daysInMonth(),
            unit: "days",
            type: "calendar",
          },
        },
      })
    );

    dispatch(
      namespaceListActions.batchChangeQuery({
        module: NAMESPACE_LIST_MODULE,
        query,
      })
    );
    dispatch(clusterActualCostsFetcher.fetch(query));
    dispatch(
      utilizationFetcher.fetch(clusterUid, {
        startTime: query.startTime.utc().format(),
        endTime: query.endTime.utc().format(),
        includeControlPlaneMachines: includeMasters,
        period,
        metricKind: "cpuUsage,cpuTotal,memoryUsage,memoryTotal",
      })
    );
    dispatch(
      namespaceConsumptionFetcher.fetch({
        startTime: formattedStartTime,
        endTime: formattedEndTime,
        period,
      })
    );
  };
}

export const namespaceListActions = new ListActions({
  schema: [ClusterNamespaceSchema],
  async fetchData(query) {
    if (!query?.startTime || !query?.endTime) {
      return;
    }

    const clusterUid = getRawCluster(store.getState())?.metadata?.uid;
    const startTime = moment(query.startTime).format(GENERAL_DATE_FORMAT);
    const endTime = moment(query.endTime).format(GENERAL_DATE_FORMAT);
    const payload = {
      filter: {
        startTime,
        endTime,
        clusters: [clusterUid],
        namespaces: query?.namespaces || [],
        includeControlPlaneMachines: getBoolean(query?.includeMasters),
      },
      options: {
        groupBy: "namespace",
        period: query?.period || 60 * 24,
      },
    };
    const response = await api.post(
      "v1/dashboard/spectroclusters/resources/cost",
      payload
    );

    return {
      items: response?.resources,
    };
  },
});

export function onUsageAndCostsFilterChange(name, value) {
  return (dispatch) => {
    dispatch(
      usageAndCostsFilterFormActions.onChange({
        module: USAGE_AND_COSTS_FILTERS_MODULE,
        name,
        value,
      })
    );
  };
}

export function toggleIncludeMasters() {
  return (dispatch, getState) => {
    dispatch({
      type: "TOGGLE_GAUGE_METRICS",
    });

    const state = getState();
    const { includeMasters } = state.cluster.details;
    const currentUser = getCurrentUser(getState());

    includeMastersCache.set(currentUser.metadata.uid, includeMasters);

    const timePeriod = getSelectedKey(state);
    const selectedMonth =
      state.forms?.[USAGE_AND_COSTS_FILTERS_MODULE]?.data?.query?.endTime;

    if (timePeriod === "calendar") {
      dispatch(costsChartCalendarChange(selectedMonth.toDate()));
    } else {
      dispatch(costsChartTimePeriodChange(timePeriod));
    }
  };
}
