import i18next from "i18next";
import createFormActions from "modules/form/actions";
import { nonProjectApi } from "services/api";
import notifications from "services/notifications";
import store from "services/store";
import Validator from "services/validator";
import { Missing } from "services/validator/rules";
import { getCurrentUser, isSecurityModeFips } from "state/auth/selectors";
import base64 from "base-64";

const validator = new Validator();
validator.addRule(
  [
    "issuerUrl",
    "clientId",
    "clientSecret",
    "callbackUrl",
    "logoutUrl",
    "scopes",
    "Email",
    "FirstName",
    "LastName",
    "SpectroTeam",
  ],
  Missing()
);

function convertToBase64(value) {
  if (!value) {
    return "";
  }
  try {
    //if the string is already base64 encoded, return it as is
    const decodedValue = base64.decode(value);
    const reEncodedValue = base64.encode(decodedValue);
    if (reEncodedValue === value) {
      return value;
    } else {
      return base64.encode(value);
    }
  } catch (error) {
    return base64.encode(value);
  }
}

function decodeToPlainText(value) {
  if (!value) {
    return "";
  }
  try {
    return base64.decode(value);
  } catch (error) {
    return value;
  }
}

export const oidcFormActions = createFormActions({
  validator,
  init: async () => {
    const user = getCurrentUser(store.getState());
    const tenantUid = user?.metadata?.annotations?.tenantUid;
    const data = await nonProjectApi.get(`v1/tenants/${tenantUid}/oidc/config`);

    return Promise.resolve({
      issuerUrl: data?.issuerUrl || "",
      caCertificate: decodeToPlainText(data?.issuerTls?.caCertificateBase64),
      insecureSkipVerify: data?.issuerTls?.insecureSkipVerify || false,
      clientId: data?.clientId || "",
      clientSecret: data?.clientSecret || "",
      defaultTeams: data?.defaultTeams || [],
      isSsoEnabled: data?.isSsoEnabled || false,
      callbackUrl: data?.callbackUrl || "",
      logoutUrl: data?.logoutUrl || "",
      Email: data?.requiredClaims?.Email || "",
      FirstName: data?.requiredClaims?.FirstName || "",
      LastName: data?.requiredClaims?.LastName || "",
      SpectroTeam: data?.requiredClaims?.SpectroTeam || "",
      scopes: data?.scopes || [],
      persisted: data?.isSsoEnabled || false,
    });
  },
  submit: async (data) => {
    const state = store.getState();
    const user = getCurrentUser(state);
    const isFips = isSecurityModeFips(store.getState());
    const tenantUid = user?.status?.tenant.tenantUid;

    const payload = {
      issuerUrl: data.issuerUrl,
      issuerTls: {
        caCertificateBase64: convertToBase64(data?.caCertificate),
      },
      callbackUrl: data.callbackUrl,
      clientId: data.clientId,
      clientSecret: data.clientSecret,
      defaultTeams: data.defaultTeams,
      isSsoEnabled: state.sso?.enabledSso === "oidc",
      logoutUrl: data.logoutUrl,
      requiredClaims: {
        Email: data.Email,
        FirstName: data.FirstName,
        LastName: data.LastName,
        SpectroTeam: data.SpectroTeam,
      },
      scopes: data.scopes,
    };

    if (!isFips) {
      payload.issuerTls.insecureSkipVerify = data?.insecureSkipVerify;
    }

    try {
      await nonProjectApi.post(`v1/tenants/${tenantUid}/oidc/config`, payload);

      notifications.success({
        message: i18next.t("OIDC configured successfully"),
      });
    } catch (error) {
      notifications.error({
        message: i18next.t("Something went wrong."),
        description: error.message,
      });
    }
  },
});
