import React from "react";
import { v4 as uuid } from "uuid";
import { notification as notifications } from "antd";
import { Provider } from "react-redux";
import store from "services/store";
import i18next from "i18next";
import AuditNote, { AddNoteButton } from "components/common/AuditNote";
import { auditSysMsgFetcher } from "state/audit/services";
import flags from "services/flags";
import { FLAGS } from "utils/constants/flags";
import api from "services/api";

export function updateAuditLog() {
  return async function thunk(_, getState) {
    const { note } = getState().forms["audit"]?.data;
    const { auditUid } = getState().audit;

    if (note) {
      try {
        api.patch(`v1/audits/${auditUid}/userMsg`, {
          userMsg: note,
        });
      } catch (err) {
        notifications.error({
          details: err.message,
          message: "Unable to add note to the update.",
        });
      }
    }
  };
}

function getAuditSystemMessage(auditUid) {
  return function thunk(dispatch) {
    dispatch({ type: "AUDIT_USER_MESSAGE", auditUid });
    dispatch(auditSysMsgFetcher.fetch());
  };
}

export function getAuditHeaders(promise) {
  return async function thunk(dispatch) {
    if (!flags.has(FLAGS.AUDIT)) {
      return;
    }

    try {
      const headers = await promise?.headers;
      if (headers && headers.audituid) {
        dispatch(getAuditSystemMessage(headers.audituid));
        return;
      }
    } catch (err) {
      notifications.error({
        details: err.message,
        message: "Something went wrong while updating the audit logs.",
      });
    }
  };
}

function openNoteNotification(promise, notificationId) {
  store.dispatch(getAuditHeaders(promise));

  notifications.open({
    message: i18next.t("Add a note for your latest update"),
    key: notificationId,
    duration: 0,
    description: (
      <Provider store={store}>
        <AuditNote
          closeNotification={() => {
            notifications.close(notificationId);
          }}
        />
      </Provider>
    ),
  });
}

notifications.config({
  duration: 10,
});

notifications.withAudit = ({ type = "success", message = "", promise }) => {
  const notificationId = uuid();
  notifications[type]({
    key: notificationId,
    duration: 20,
    message: message,
    ...(promise && {
      description: (
        <AddNoteButton
          onClick={() => openNoteNotification(promise, notificationId)}
        />
      ),
    }),
  });
};

export default notifications;
